import React, { useContext, useEffect } from "react";
import Footer from "Shared/Footer";
import Header from "Shared/Header";
import Navbar from "Shared/Navbar";
import secticologo from "assets/images/sectigo-logo.svg";
import infocirclefill from "assets/images/info-circle-fill.svg";
import { Button, Form } from "react-bootstrap";
import Error from "Shared/Error";
import { countryListData } from "Utils/Data";
import { ATTENTION_ORG_INFO } from "Utils/Constants";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ProductDetailsContext } from "Context/ProductDetailContext";
import Loader from "Shared/Loader";

const OrganizationDetails = () => {
  let navigate = useNavigate();
  const {
    step,
    isOv,
    setStepOrganizationDetail,
    values,
    generateCertificate,
    loading,
    setLoading,
    csrInfo,
    error,
    setError,
    productName,
  } = useContext(ProductDetailsContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const countryListDropdown = countryListData.map((item) => {
    return (
      <option key={item.id} value={item.CountryValue}>
        {item.name}
      </option>
    );
  });

  const onSubmit = async (data) => {
    setLoading("true");
    values.orgLegalName = data.orgLegalName;
    values.orgAssumedName = data.orgAssumedName;
    values.orgDUNS = data.orgDUNS;
    values.orgAddress1 = data.orgAddress1;
    values.orgAddress2 = data.orgAddress2;
    values.orgCity = data.orgCity;
    values.orgState = data.orgState;
    values.orgZip = data.orgZip;
    values.orgCountry = data.orgCountry;
    values.orgPhone = data.orgPhone;
    values.orgFax = data.orgFax;
    // values.localityNameForEV = data.,
    values.stateOrProvinceNameForEV = data.orgState;
    values.countryNameForEV = data.orgCountry;
    values.assumedNameForEV = data.orgAssumedName;
    const result = await generateCertificate();
    if (result.data.errorCode === "0") {
      setError("");
      navigate("/thankyou");
      setLoading("");
    }
  };
  const onBackClick = () => {
    navigate("/verify-detail");
  };
  useEffect(() => {
    setStepOrganizationDetail();
  }, []);
  useEffect(() => {
    if (error !== "" && error !== null) {
      setLoading("");
      alert(error);
      navigate("/error");
    }
  }, [error]);

  return (
    <div>
      <Header />
      <main>
        {loading === "true" ? <Loader /> : ""}
        <section className="py-3 border-top">
          <div className="container">
            <div className="d-flex flex-sm-row flex-column justify-content-between align-items-center">
              <h1 className="mb-md-0 mb-2 text-md-left text-center fs-3">
                {productName}
              </h1>
              <span className="brand-logo">
                <img alt="Certificate Generation" src={secticologo} />
              </span>
            </div>
          </div>
        </section>
        <section className="content bg-light py-5">
          <div className="container">
            <Navbar step={step} isOv={isOv} />
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="bg-white p-4 p-md-5">
                <div className="alert alert-primary d-flex" role="alert">
                  <img
                    alt="Info"
                    src={infocirclefill}
                    width="24"
                    height="24"
                    className="me-2"
                  />
                  <div>{ATTENTION_ORG_INFO}</div>
                </div>
                <div className="mb-3 row align-items-center">
                  <label className="col-sm-3 col-form-label">
                    Product Name:
                  </label>
                  <div className="col-sm-9">Sectigo OV SSL</div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">
                    Validation Type:{" "}
                  </label>
                  <div className="col-sm-9">OV/EV</div>
                </div>
                <Form.Group className="mb-3 row position-relative">
                  <Form.Label className="col-md-3 col-form-label">
                    Legal Name:<span className="text-danger">*</span>
                  </Form.Label>
                  <div className="col-md-9">
                    <Form.Control
                      type="text"
                      className={
                        errors.orgLegalName
                          ? "form-control border-danger"
                          : "form-control"
                      }
                      name="orgLegalName"
                      {...register("orgLegalName", { required: true })}
                    />
                    {errors.orgLegalName && <Error />}
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 row">
                  <Form.Label className="col-md-3 col-form-label">
                    Assumed Name (DBA):
                  </Form.Label>
                  <div className="col-md-9">
                    <Form.Control
                      type="text"
                      className="form-control"
                      name="orgAssumedName"
                      {...register("orgAssumedName")}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 row">
                  <Form.Label className="col-md-3 col-form-label">
                    D-U-N-S Number:
                  </Form.Label>
                  <div className="col-md-9">
                    <Form.Control
                      type="text"
                      className="form-control"
                      name="orgDUNS"
                      {...register("orgDUNS")}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 row position-relative">
                  <Form.Label className="col-md-3 col-form-label">
                    Address 1:<span className="text-danger">*</span>
                  </Form.Label>
                  <div className="col-md-9">
                    <Form.Control
                      type="text"
                      className={
                        errors.orgAddress1
                          ? "form-control border-danger"
                          : "form-control"
                      }
                      name="orgAddress1"
                      {...register("orgAddress1", { required: true })}
                    />
                    {errors.orgAddress1 && <Error />}
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 row">
                  <Form.Label className="col-md-3 col-form-label">
                    Address 2:
                  </Form.Label>
                  <div className="col-md-9">
                    <Form.Control
                      type="text"
                      className="form-control"
                      name="orgAddress2"
                      {...register("orgAddress2")}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 row position-relative">
                  <Form.Label className="col-md-3 col-form-label">
                    City:<span className="text-danger">*</span>
                  </Form.Label>
                  <div className="col-md-9">
                    <Form.Control
                      type="text"
                      className={
                        errors.orgCity
                          ? "form-control border-danger"
                          : "form-control"
                      }
                      name="orgCity"
                      {...register("orgCity", { required: true })}
                    />
                    {errors.orgCity && <Error />}
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 row position-relative">
                  <Form.Label className="col-md-3 col-form-label">
                    State/Province:<span className="text-danger">*</span>
                  </Form.Label>
                  <div className="col-md-9">
                    <Form.Control
                      type="text"
                      className={
                        errors.orgState
                          ? "form-control border-danger"
                          : "form-control"
                      }
                      defaultValue={csrInfo.stateOrProvinceName}
                      name="orgState"
                      {...register("orgState", { required: true })}
                    />
                    {errors.orgState && <Error />}
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 row position-relative">
                  <Form.Label className="col-md-3 col-form-label">
                    Postal or Zip Code:<span className="text-danger">*</span>
                  </Form.Label>
                  <div className="col-md-9">
                    <Form.Control
                      type="text"
                      className={
                        errors.orgZip
                          ? "form-control border-danger"
                          : "form-control"
                      }
                      name="orgZip"
                      {...register("orgZip", { required: true })}
                    />
                    {errors.orgZip && <Error />}
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 row position-relative">
                  <Form.Label className="col-md-3 col-form-label">
                    Country:<span className="text-danger">*</span>
                  </Form.Label>
                  <div className="col-md-9">
                    <Form.Select
                      className={
                        errors.orgCountry
                          ? "form-select border-danger"
                          : "form-select"
                      }
                      name="orgCountry"
                      {...register("orgCountry", { required: true })}
                      aria-label="Default select example"
                      data-live-search="true"
                      defaultValue=""
                    >
                      {countryListDropdown}
                    </Form.Select>
                    {errors.orgCountry && <Error />}
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 row position-relative">
                  <Form.Label className="col-sm-3 col-form-label">
                    Phone Number:<span className="text-danger">*</span>
                  </Form.Label>
                  <div className="col-sm-9">
                    <Form.Control
                      type="number"
                      className={
                        errors.orgPhone
                          ? "form-control border-danger"
                          : "form-control"
                      }
                      name="orgPhone"
                      {...register("orgPhone", { required: true })}
                    />
                    {errors.orgPhone && <Error />}
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 row">
                  <Form.Label className="col-sm-3 col-form-label">
                    Fax Number:
                  </Form.Label>
                  <div className="col-sm-9">
                    <Form.Control
                      type="text"
                      className="form-control"
                      name="orgFax"
                      id="faxNumber"
                      {...register("orgFax")}
                    />
                  </div>
                </Form.Group>
                <div className="pt-4 mt-5 border-top d-flex justify-content-between">
                  <Button
                    className="btn btn-secondary btn-lg"
                    type="button"
                    name="Back"
                    id="back"
                    value="Back"
                    onClick={onBackClick}
                  >
                    Back
                  </Button>
                  <Button
                    className="btn btn-success btn-lg"
                    type="submit"
                    name="continue"
                    id="btnSubmit"
                    value="Continue"
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default OrganizationDetails;
