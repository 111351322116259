import React from "react";

export const ErrorCsr = () => {
  return <span className="field-validation-error">Please Enter valid CSR</span>;
};

const Error = () => {
  return <span className="field-validation-error">This field is required</span>;
};

export const ErrorValidEmail = () => {
  return (
    <span className="border-danger field-validation-error">
      Please Enter Valid Email
    </span>
  );
};

export default Error;
