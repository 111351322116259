import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = ({ isAuthenticated, children }) => {
  const inviteKey = localStorage.getItem("inviteKey");
  if (!isAuthenticated) {
    return <Navigate to={`/entercsr/${inviteKey}`} />;
  }
  return children ? children : <Outlet />;
};

export default ProtectedRoute;
