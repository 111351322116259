import React from "react";
import csrTextImage from "assets/images/csrtxt.jpg";

const CsrSampleModal = () => {
  return (
    <div
      className="modal fade"
      id="exampleCSR"
      aria-labelledby="exampleCSRModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleCSRModalLabel">
              Sample CSR Only
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <img alt="Sample CSR" src={csrTextImage} className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CsrSampleModal;
