import axios from "axios";

export const Api = {
  actions(url) {
    return {
      fetchAll: (data, token) =>
        axios.post(url, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
      fetchById: (id) => axios.post(url, id),
      create: (data, token) =>
        axios.post(url, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
    };
  },
};
