import { Api } from "Services/Api";
import {
  CNAME,
  CSR,
  DCV,
  DCV_AVAILABLE,
  GET_TOKEN,
  MAIN_URL,
  REISSUE,
  RETRIVE_EMAILS,
  SUBMIT_ORDER,
  SUBSCRIBER_AGREEMENT,
} from "Utils/Constants";

export const getProductDetails = async (id) => {
  return Api.actions(MAIN_URL + GET_TOKEN).fetchById(id);
};

export const postCsrDecoded = async (data, token) => {
  return Api.actions(MAIN_URL + CSR).fetchAll(data, token);
};

export const postDcvDropdownData = async (data, token) => {
  return Api.actions(MAIN_URL + DCV_AVAILABLE).fetchAll(data, token);
};

export const postRetrieveDropdownData = async (data, token) => {
  return Api.actions(MAIN_URL + RETRIVE_EMAILS).fetchAll(data, token);
};

export const postDcvDropdownHttp = async (data, token) => {
  return Api.actions(MAIN_URL + DCV).fetchAll(data, token);
};
export const postDcvDropdownCname = async (data, token) => {
  return Api.actions(MAIN_URL + CNAME).fetchAll(data, token);
};
export const postsubscriberAgreement = async (data, token) => {
  return Api.actions(MAIN_URL + SUBSCRIBER_AGREEMENT).fetchAll(data, token);
};
export const postSubmitOrder = async (data, token) => {
  return Api.actions(MAIN_URL + SUBMIT_ORDER).fetchAll(data, token);
};
export const postReissue = async (data, token) => {
  return Api.actions(MAIN_URL + REISSUE).fetchAll(data, token);
};
