import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import {
  ProductDetailsContext,
  ProductDetailsContextProvider,
} from "Context/ProductDetailContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ProductDetailsContextProvider>
      <App />
    </ProductDetailsContextProvider>
  </React.StrictMode>
);
