import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "App.css";
import VerifyDetails from "Pages/VerifyDetails/VerifyDetails";
import ThankYou from "Pages/ThankYou/ThankYou";
import { Route, Routes, HashRouter } from "react-router-dom";
import PageNotFound from "Shared/PageNotFound";
import OrganizationInfo from "Pages/OrganizationDetails/OrganizationDetails";
import IndexCsr from "Pages/Csr/IndexCsr";
import { ProductDetailsContext } from "Context/ProductDetailContext";
import { useContext } from "react";
import ProtectedRoute from "Shared/ProtectedRoute";
import CodeSigning from "Pages/CodeSigning/CodeSigning";

const App = () => {
  const { jwtToken, enrollPin, isCodeSigning } = useContext(
    ProductDetailsContext
  );
  return (
    <div className="App">
      <HashRouter basename={"/order/"}>
        <Routes>
          <>
            <Route
              path="/entercsr/:id"
              element={!isCodeSigning ? <IndexCsr /> : <PageNotFound />}
              exact
            />
            <Route path="/codesigning/:id" element={<CodeSigning />} exact />
            <Route path="*" element={<PageNotFound />} />
            <Route path="error" element={<PageNotFound />} />
            <Route
              element={
                <ProtectedRoute
                  isAuthenticated={jwtToken}
                  inviteKey={enrollPin}
                />
              }
            >
              <Route
                path="verify-detail"
                element={!isCodeSigning ? <VerifyDetails /> : <PageNotFound />}
                exact
              />
              <Route
                path="organisationdetail"
                element={
                  !isCodeSigning ? <OrganizationInfo /> : <PageNotFound />
                }
                exact
              />
              <Route path="thankyou" element={<ThankYou />} exact />
            </Route>
          </>
        </Routes>
      </HashRouter>
    </div>
  );
};

export default App;
