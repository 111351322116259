export const countryListData = [
  { id: "0", name: "--Select Country--", CountryValue: "" },
  { id: "1", name: "Afghanistan", CountryValue: "AF" },
  { id: "2", name: "Aland Islands", CountryValue: "AX" },
  { id: "3", name: "Albania", CountryValue: "AL" },
  { id: "4", name: "Algeria", CountryValue: "DZ" },
  { id: "5", name: "American Samoa", CountryValue: "AS" },
  { id: "6", name: "Andorra", CountryValue: "AD" },
  { id: "7", name: "Angola", CountryValue: "AO" },
  { id: "8", name: "Anguilla", CountryValue: "AI" },
  { id: "9", name: "Antarctica", CountryValue: "AQ" },
  { id: "10", name: "Antigua and Barbuda", CountryValue: "AG" },
  { id: "11", name: "Arab Emirates", CountryValue: "AE" },
  { id: "12", name: "Argentina", CountryValue: "AR" },
  { id: "13", name: "Armenia", CountryValue: "AM" },
  { id: "14", name: "Aruba", CountryValue: "AW" },
  { id: "15", name: "Ashmore and Cartier", CountryValue: "AU" },
  { id: "16", name: "Australia", CountryValue: "AU" },
  { id: "17", name: "Austria", CountryValue: "AT" },
  { id: "18", name: "Azerbaijan", CountryValue: "AZ" },
  { id: "19", name: "Bahrain", CountryValue: "BH" },
  { id: "20", name: "Baker Island", CountryValue: "UM" },
  { id: "21", name: "Bangladesh", CountryValue: "BD" },
  { id: "22", name: "Barbados", CountryValue: "BB" },
  { id: "23", name: "Bassas da India", CountryValue: "TF" },
  { id: "24", name: "Belarus", CountryValue: "BY" },
  { id: "25", name: "Belgium", CountryValue: "BE" },
  { id: "26", name: "Belize", CountryValue: "BZ" },
  { id: "27", name: "Benin", CountryValue: "BJ" },
  { id: "28", name: "Bermuda", CountryValue: "BM" },
  { id: "29", name: "Bhutan", CountryValue: "BT" },
  { id: "30", name: "Bolivia", CountryValue: "BO" },
  { id: "31", name: "Bosnia and Herzegovina", CountryValue: "BA" },
  { id: "32", name: "Botswana", CountryValue: "BW" },
  { id: "33", name: "Bouvet Island", CountryValue: "BV" },
  { id: "34", name: "Brazil", CountryValue: "BR" },
  { id: "35", name: "British Indian Ocean Territory", CountryValue: "IO" },
  { id: "36", name: "British Virgin Islands", CountryValue: "VG" },
  { id: "37", name: "Brunei Darussalam", CountryValue: "BN" },
  { id: "38", name: "Bulgaria", CountryValue: "BG" },
  { id: "39", name: "Burkina Faso", CountryValue: "BF" },
  { id: "40", name: "Burma", CountryValue: "MM" },
  { id: "41", name: "Burundi", CountryValue: "BI" },
  { id: "42", name: "Cambodia", CountryValue: "KH" },
  { id: "43", name: "Cameroon", CountryValue: "CM" },
  { id: "44", name: "Canada", CountryValue: "CA" },
  { id: "45", name: "Cape Verde", CountryValue: "CV" },
  { id: "46", name: "Cayman Islands", CountryValue: "KY" },
  { id: "47", name: "Central African Republic", CountryValue: "CF" },
  { id: "48", name: "Chad", CountryValue: "TD" },
  { id: "49", name: "Chile", CountryValue: "CL" },
  { id: "50", name: "China", CountryValue: "CN" },
  { id: "51", name: "Christmas Island", CountryValue: "CX" },
  { id: "52", name: "Clipperton Island", CountryValue: "FR" },
  { id: "53", name: "Cocos (Keeling) Islands", CountryValue: "CC" },
  { id: "54", name: "Colombia", CountryValue: "CO" },
  { id: "55", name: "Comoros", CountryValue: "KM" },
  { id: "56", name: "Congo, Democratic Republic of the", CountryValue: "CD" },
  { id: "57", name: "Congo, Republic of the", CountryValue: "CG" },
  { id: "58", name: "Cook Islands", CountryValue: "CK" },
  { id: "59", name: "Coral Sea Islands", CountryValue: "AU" },
  { id: "60", name: "Costa Rica", CountryValue: "CR" },
  { id: "61", name: "Cote d'Ivoire", CountryValue: "CI" },
  { id: "62", name: "Croatia", CountryValue: "HR" },
  { id: "63", name: "Cuba", CountryValue: "CU" },
  { id: "64", name: "Curacao", CountryValue: "CW" },
  { id: "65", name: "Cyprus", CountryValue: "CY" },
  { id: "66", name: "Czech Republic", CountryValue: "CZ" },
  { id: "67", name: "Denmark", CountryValue: "DK" },
  { id: "68", name: "Djibouti", CountryValue: "DJ" },
  { id: "69", name: "Dominica", CountryValue: "DM" },
  { id: "70", name: "Dominican Republic", CountryValue: "DO" },
  { id: "71", name: "East Timor", CountryValue: "TL" },
  { id: "72", name: "Ecuador", CountryValue: "EC" },
  { id: "73", name: "Egypt", CountryValue: "EG" },
  { id: "74", name: "El Salvador", CountryValue: "SV" },
  { id: "75", name: "Equatorial Guinea", CountryValue: "GQ" },
  { id: "76", name: "Eritrea", CountryValue: "ER" },
  { id: "77", name: "Estonia", CountryValue: "EE" },
  { id: "78", name: "Ethiopia", CountryValue: "ET" },
  { id: "79", name: "Europa Island", CountryValue: "TF" },
  { id: "80", name: "Falkland Islands (Islas Malvinas)", CountryValue: "FK" },
  { id: "81", name: "Faroe Islands", CountryValue: "FO" },
  { id: "82", name: "Fiji", CountryValue: "FJ" },
  { id: "83", name: "Finland", CountryValue: "FI" },
  { id: "84", name: "France", CountryValue: "FR" },
  { id: "85", name: "France, Metropolitan", CountryValue: "FR" },
  { id: "86", name: "French Guiana", CountryValue: "GF" },
  { id: "87", name: "French Polynesia", CountryValue: "PF" },
  { id: "88", name: "French Southern and Antarctic Lands", CountryValue: "TF" },
  { id: "89", name: "Gabon", CountryValue: "GA" },
  { id: "90", name: "Gaza Strip", CountryValue: "PS" },
  { id: "91", name: "Georgia", CountryValue: "GE" },
  { id: "92", name: "Germany", CountryValue: "DE" },
  { id: "93", name: "Ghana", CountryValue: "GH" },
  { id: "94", name: "Gibraltar", CountryValue: "GI" },
  { id: "95", name: "Glorioso Islands", CountryValue: "TF" },
  { id: "96", name: "Greece", CountryValue: "GR" },
  { id: "97", name: "Greenland", CountryValue: "GL" },
  { id: "98", name: "Grenada", CountryValue: "GD" },
  { id: "99", name: "Guadeloupe", CountryValue: "GP" },
  { id: "100", name: "Guam", CountryValue: "GU" },
  { id: "101", name: "Guatemala", CountryValue: "GT" },
  { id: "102", name: "Guernsey", CountryValue: "GG" },
  { id: "103", name: "Guinea", CountryValue: "GN" },
  { id: "104", name: "Guinea-Bissau", CountryValue: "GW" },
  { id: "105", name: "Guyana", CountryValue: "GY" },
  { id: "106", name: "Haiti", CountryValue: "HT" },
  { id: "107", name: "Heard Island and McDonald Islands", CountryValue: "HM" },
  { id: "108", name: "Holy See (Vatican City)", CountryValue: "VA" },
  { id: "109", name: "Honduras", CountryValue: "HN" },
  { id: "110", name: "Hong Kong (SAR)", CountryValue: "HK" },
  { id: "111", name: "Howland Island", CountryValue: "UM" },
  { id: "112", name: "Hungary", CountryValue: "HU" },
  { id: "113", name: "Iceland", CountryValue: "IS" },
  { id: "114", name: "India", CountryValue: "IN" },
  { id: "115", name: "Indonesia", CountryValue: "ID" },
  { id: "116", name: "Iran", CountryValue: "IR" },
  { id: "117", name: "Iraq", CountryValue: "IQ" },
  { id: "118", name: "Ireland", CountryValue: "IE" },
  { id: "119", name: "Isle of man", CountryValue: "IF" },
  { id: "120", name: "Israel", CountryValue: "IL" },
  { id: "121", name: "Italy", CountryValue: "IT" },
  { id: "122", name: "Jamaica", CountryValue: "JM" },
  { id: "123", name: "Jan Mayen", CountryValue: "SJ" },
  { id: "124", name: "Japan", CountryValue: "JP" },
  { id: "125", name: "Jarvis Island", CountryValue: "UM" },
  { id: "126", name: "Jersey", CountryValue: "JE" },
  { id: "127", name: "Johnston Atoll", CountryValue: "UM" },
  { id: "128", name: "Jordan", CountryValue: "JO" },
  { id: "129", name: "Juan de Nova Island", CountryValue: "TF" },
  { id: "130", name: "Kazakhstan", CountryValue: "KZ" },
  { id: "131", name: "Kenya", CountryValue: "KE" },
  { id: "132", name: "Kingman Reef", CountryValue: "UM" },
  { id: "133", name: "Kiribati", CountryValue: "KI" },
  { id: "134", name: "Korea, North", CountryValue: "KP" },
  { id: "135", name: "Korea, South", CountryValue: "KR" },
  { id: "136", name: "Kosovo", CountryValue: "XK" },
  { id: "137", name: "Kuwait", CountryValue: "KW" },
  { id: "138", name: "Kyrgyzstan", CountryValue: "KG" },
  { id: "139", name: "Laos", CountryValue: "LA" },
  { id: "140", name: "Latvia", CountryValue: "LV" },
  { id: "141", name: "Lebanon", CountryValue: "LB" },
  { id: "142", name: "Lesotho", CountryValue: "LS" },
  { id: "143", name: "Liberia", CountryValue: "LR" },
  { id: "144", name: "Libya", CountryValue: "LY" },
  { id: "145", name: "Liechtenstein", CountryValue: "LI" },
  { id: "146", name: "Lithuania", CountryValue: "LT" },
  { id: "147", name: "Luxembourg", CountryValue: "LU" },
  { id: "148", name: "Macao", CountryValue: "MO" },
  {
    id: "149",
    name: "Macedonia, The Former Yugoslav Republic of",
    CountryValue: "MK",
  },
  { id: "150", name: "Madagascar", CountryValue: "MG" },
  { id: "151", name: "Malawi", CountryValue: "MW" },
  { id: "152", name: "Malaysia", CountryValue: "MY" },
  { id: "153", name: "Maldives", CountryValue: "MV" },
  { id: "154", name: "Mali", CountryValue: "ML" },
  { id: "155", name: "Malta", CountryValue: "MT" },
  { id: "156", name: "Marshall Islands", CountryValue: "MH" },
  { id: "157", name: "Martinique", CountryValue: "MQ" },
  { id: "158", name: "Mauritania", CountryValue: "MR" },
  { id: "159", name: "Mauritius", CountryValue: "MU" },
  { id: "160", name: "Mayotte", CountryValue: "YT" },
  { id: "161", name: "Mexico", CountryValue: "MX" },
  { id: "162", name: "Micronesia, Federated States of", CountryValue: "FM" },
  { id: "163", name: "Midway Islands", CountryValue: "UM" },
  { id: "164", name: "Miscellaneous (French)", CountryValue: "TF" },
  { id: "165", name: "Moldova", CountryValue: "MD" },
  { id: "166", name: "Monaco", CountryValue: "MC" },
  { id: "167", name: "Mongolia", CountryValue: "MN" },
  { id: "168", name: "Montenegro", CountryValue: "ME" },
  { id: "169", name: "Montserrat", CountryValue: "MS" },
  { id: "170", name: "Morocco", CountryValue: "MA" },
  { id: "171", name: "Mozambique", CountryValue: "MZ" },
  { id: "172", name: "Myanmar", CountryValue: "MM" },
  { id: "173", name: "Namibia", CountryValue: "NA" },
  { id: "174", name: "Nauru", CountryValue: "NR" },
  { id: "175", name: "Navassa Island", CountryValue: "UM" },
  { id: "176", name: "Nepal", CountryValue: "NP" },
  { id: "177", name: "Netherlands", CountryValue: "NL" },
  { id: "178", name: "New Caledonia", CountryValue: "NC" },
  { id: "179", name: "New Zealand", CountryValue: "NZ" },
  { id: "180", name: "Nicaragua", CountryValue: "NI" },
  { id: "181", name: "Niger", CountryValue: "NE" },
  { id: "182", name: "Nigeria", CountryValue: "NG" },
  { id: "183", name: "Niue", CountryValue: "NU" },
  { id: "184", name: "Norfolk Island", CountryValue: "NF" },
  { id: "185", name: "Northern Mariana Islands", CountryValue: "MP" },
  { id: "186", name: "Norway", CountryValue: "NO" },
  { id: "187", name: "Oman", CountryValue: "OM" },
  { id: "188", name: "Pakistan", CountryValue: "PK" },
  { id: "189", name: "Palau", CountryValue: "PW" },
  { id: "190", name: "Palestinian Territory, Occupied", CountryValue: "PS" },
  { id: "191", name: "Palmyra Atoll", CountryValue: "UM" },
  { id: "192", name: "Panama", CountryValue: "PA" },
  { id: "193", name: "Papua New Guinea", CountryValue: "PG" },
  { id: "194", name: "Paracel Islands", CountryValue: "VN" },
  { id: "195", name: "Paraguay", CountryValue: "PY" },
  { id: "196", name: "Peru", CountryValue: "PE" },
  { id: "197", name: "Philippines", CountryValue: "PH" },
  { id: "198", name: "Pitcairn Islands", CountryValue: "PN" },
  { id: "199", name: "Poland", CountryValue: "PL" },
  { id: "200", name: "Portugal", CountryValue: "PT" },
  { id: "201", name: "Puerto Rico", CountryValue: "PR" },
  { id: "202", name: "Qatar", CountryValue: "QA" },
  { id: "203", name: "Réunion", CountryValue: "RE" },
  { id: "204", name: "Romania", CountryValue: "RO" },
  { id: "205", name: "Russia", CountryValue: "RU" },
  { id: "206", name: "Rwanda", CountryValue: "RW" },
  { id: "207", name: "Saint Helena", CountryValue: "SH" },
  { id: "208", name: "Saint Kitts and Nevis", CountryValue: "KN" },
  { id: "209", name: "Saint Lucia", CountryValue: "LC" },
  { id: "210", name: "Saint Pierre and Miquelon", CountryValue: "PM" },
  { id: "211", name: "Saint Vincent and the Grenadines", CountryValue: "VC" },
  { id: "212", name: "Saint-Barthélemy", CountryValue: "BL" },
  { id: "213", name: "Saint-Martin (French part)", CountryValue: "MF" },
  { id: "214", name: "Samoa", CountryValue: "WS" },
  { id: "215", name: "San Marino", CountryValue: "SM" },
  { id: "216", name: "São Tomé and Príncipe", CountryValue: "ST" },
  { id: "217", name: "Saudi Arabia", CountryValue: "SA" },
  { id: "218", name: "Senegal", CountryValue: "SN" },
  { id: "219", name: "Serbia", CountryValue: "--" },
  { id: "220", name: "Serbia and Montenegro", CountryValue: "ME" },
  { id: "221", name: "Seychelles", CountryValue: "SC" },
  { id: "222", name: "Sierra Leone", CountryValue: "SL" },
  { id: "223", name: "Singapore", CountryValue: "SG" },
  { id: "224", name: "Sint Maarten", CountryValue: "SX" },
  { id: "225", name: "Slovakia", CountryValue: "SK" },
  { id: "226", name: "Slovenia", CountryValue: "SI" },
  { id: "227", name: "Solomon Islands", CountryValue: "SB" },
  { id: "228", name: "Somalia", CountryValue: "SO" },
  { id: "229", name: "South Africa", CountryValue: "ZA" },
  {
    id: "230",
    name: "South Georgia and the South Sandwich Islands",
    CountryValue: "GS",
  },
  { id: "231", name: "South Sudan", CountryValue: "SS" },
  { id: "232", name: "Spain", CountryValue: "ES" },
  { id: "233", name: "Spratly Islands", CountryValue: "VN" },
  { id: "234", name: "Sri Lanka", CountryValue: "LK" },
  { id: "235", name: "Sudan", CountryValue: "SD" },
  { id: "236", name: "Suriname", CountryValue: "SR" },
  { id: "237", name: "Svalbard", CountryValue: "SJ" },
  { id: "238", name: "Swaziland", CountryValue: "SZ" },
  { id: "239", name: "Sweden", CountryValue: "SE" },
  { id: "240", name: "Switzerland", CountryValue: "CH" },
  { id: "241", name: "Syrian Arab Republic (Syria)", CountryValue: "SY" },
  { id: "242", name: "Taiwan", CountryValue: "TW" },
  { id: "243", name: "Tajikistan", CountryValue: "TJ" },
  { id: "244", name: "Tanzania", CountryValue: "TZ" },
  { id: "245", name: "Thailand", CountryValue: "TH" },
  { id: "246", name: "The Bahamas", CountryValue: "BS" },
  { id: "247", name: "The Gambia", CountryValue: "GM" },
  { id: "248", name: "Togo", CountryValue: "TG" },
  { id: "249", name: "Tokelau", CountryValue: "TK" },
  { id: "250", name: "Tonga", CountryValue: "TO" },
  { id: "251", name: "Trinidad and Tobago", CountryValue: "TT" },
  { id: "252", name: "Tromelin Island", CountryValue: "TF" },
  { id: "253", name: "Tunisia", CountryValue: "TN" },
  { id: "254", name: "Turkey", CountryValue: "TR" },
  { id: "255", name: "Turkmenistan", CountryValue: "TM" },
  { id: "256", name: "Turks and Caicos Islands", CountryValue: "TC" },
  { id: "257", name: "Tuvalu", CountryValue: "TV" },
  { id: "258", name: "Uganda", CountryValue: "UG" },
  { id: "259", name: "Ukraine", CountryValue: "UA" },
  { id: "260", name: "United Kingdom", CountryValue: "GB" },
  { id: "261", name: "United States", CountryValue: "US" },
  {
    id: "262",
    name: "United States Minor Outlying Islands",
    CountryValue: "UM",
  },
  { id: "263", name: "Uruguay", CountryValue: "UY" },
  { id: "264", name: "Uzbekistan", CountryValue: "UZ" },
  { id: "265", name: "Vanuatu", CountryValue: "VU" },
  { id: "266", name: "Venezuela", CountryValue: "VE" },
  { id: "267", name: "Vietnam", CountryValue: "VN" },
  { id: "268", name: "Virgin Islands", CountryValue: "VI" },
  { id: "269", name: "Virgin Islands (UK)", CountryValue: "VG" },
  { id: "270", name: "Virgin Islands (US)", CountryValue: "VI" },
  { id: "271", name: "Wake Island", CountryValue: "UM" },
  { id: "272", name: "Wallis and Futuna", CountryValue: "WF" },
  { id: "273", name: "West Bank", CountryValue: "PS" },
  { id: "274", name: "Western Sahara", CountryValue: "EH" },
  { id: "275", name: "Western Samoa", CountryValue: "AS" },
  { id: "276", name: "World", CountryValue: "--" },
  { id: "277", name: "Yemen", CountryValue: "YE" },
  { id: "278", name: "Yugoslavia", CountryValue: "YU" },
  { id: "279", name: "Zaire", CountryValue: "CD" },
  { id: "280", name: "Zambia", CountryValue: "ZM" },
  { id: "281", name: "Zimbabwe", CountryValue: "ZW" },
];

export const stateListDataIndia = [
  {
    stateID: 1,
    stateName: "Andaman and Nicobar Islands",
  },
  {
    stateID: 2,
    stateName: "Andhra Pradesh",
  },
  {
    stateID: 3,
    stateName: "Arunachal Pradesh",
  },
  {
    stateID: 4,
    stateName: "Assam",
  },
  {
    stateID: 5,
    stateName: "Bihar",
  },
  {
    stateID: 6,
    stateName: "Chandigarh",
  },
  {
    stateID: 7,
    stateName: "Chhattisgarh",
  },
  {
    stateID: 8,
    stateName: "Dadra and Nagar Haveli",
  },
  {
    stateID: 9,
    stateName: "Daman and Diu",
  },
  {
    stateID: 10,
    stateName: "Delhi",
  },
  {
    stateID: 11,
    stateName: "Goa",
  },
  {
    stateID: 12,
    stateName: "Gujarat",
  },
  {
    stateID: 13,
    stateName: "Haryana",
  },
  {
    stateID: 14,
    stateName: "Himachal Pradesh",
  },
  {
    stateID: 15,
    stateName: "Jammu and Kashmir",
  },
  {
    stateID: 16,
    stateName: "Jharkhand",
  },
  {
    stateID: 17,
    stateName: "Karnataka",
  },
  {
    stateID: 18,
    stateName: "Kerala",
  },
  {
    stateID: 19,
    stateName: "Lakshadweep",
  },
  {
    stateID: 20,
    stateName: "Madhya Pradesh",
  },
  {
    stateID: 21,
    stateName: "Maharashtra",
  },
  {
    stateID: 22,
    stateName: "Manipur",
  },
  {
    stateID: 23,
    stateName: "Meghalaya",
  },
  {
    stateID: 24,
    stateName: "Mizoram",
  },
  {
    stateID: 25,
    stateName: "Nagaland",
  },
  {
    stateID: 26,
    stateName: "Odisha",
  },
  {
    stateID: 27,
    stateName: "Puducherry",
  },
  {
    stateID: 28,
    stateName: "Punjab",
  },
  {
    stateID: 29,
    stateName: "Rajasthan",
  },
  {
    stateID: 30,
    stateName: "Sikkim",
  },
  {
    stateID: 31,
    stateName: "Tamil Nadu",
  },
  {
    stateID: 32,
    stateName: "Telangana",
  },
  {
    stateID: 33,
    stateName: "Tripura",
  },
  {
    stateID: 34,
    stateName: "Uttar Pradesh",
  },
  {
    stateID: 35,
    stateName: "Uttarakhand",
  },
  {
    stateID: 36,
    stateName: "West Bengal",
  },
];

export const stateListDataUsa = [
  {
    stateID: 37,
    stateName: "Armed Forces Americas",
  },
  {
    stateID: 38,
    stateName: "Armed Forces Europe",
  },
  {
    stateID: 39,
    stateName: "Armed Forces Pacific",
  },
  {
    stateID: 40,
    stateName: "Alaska",
  },
  {
    stateID: 41,
    stateName: "Alabama",
  },
  {
    stateID: 42,
    stateName: "Arkansas",
  },
  {
    stateID: 43,
    stateName: "Arizona",
  },
  {
    stateID: 44,
    stateName: "California",
  },
  {
    stateID: 45,
    stateName: "Colorado",
  },
  {
    stateID: 46,
    stateName: "Connecticut",
  },
  {
    stateID: 47,
    stateName: "District of Columbia",
  },
  {
    stateID: 48,
    stateName: "Delaware",
  },
  {
    stateID: 49,
    stateName: "Florida",
  },
  {
    stateID: 50,
    stateName: "Georgia",
  },
  {
    stateID: 51,
    stateName: "Guam",
  },
  {
    stateID: 52,
    stateName: "Hawaii",
  },
  {
    stateID: 53,
    stateName: "Iowa",
  },
  {
    stateID: 54,
    stateName: "Idaho",
  },
  {
    stateID: 55,
    stateName: "Illinois",
  },
  {
    stateID: 56,
    stateName: "Indiana",
  },
  {
    stateID: 57,
    stateName: "Kansas",
  },
  {
    stateID: 58,
    stateName: "Kentucky",
  },
  {
    stateID: 59,
    stateName: "Louisiana",
  },
  {
    stateID: 60,
    stateName: "Massachusetts",
  },
  {
    stateID: 61,
    stateName: "Maryland",
  },
  {
    stateID: 62,
    stateName: "Maine",
  },
  {
    stateID: 63,
    stateName: "Michigan",
  },
  {
    stateID: 64,
    stateName: "Minnesota",
  },
  {
    stateID: 65,
    stateName: "Missouri",
  },
  {
    stateID: 66,
    stateName: "Mississippi",
  },
  {
    stateID: 67,
    stateName: "Montana",
  },
  {
    stateID: 68,
    stateName: "North Carolina",
  },
  {
    stateID: 69,
    stateName: "North Dakota",
  },
  {
    stateID: 70,
    stateName: "Nebraska",
  },
  {
    stateID: 71,
    stateName: "New Hampshire",
  },
  {
    stateID: 72,
    stateName: "New Jersey",
  },
  {
    stateID: 73,
    stateName: "New Mexico",
  },
  {
    stateID: 74,
    stateName: "Nevada",
  },
  {
    stateID: 75,
    stateName: "New York",
  },
  {
    stateID: 76,
    stateName: "Ohio",
  },
  {
    stateID: 77,
    stateName: "Oklahoma",
  },
  {
    stateID: 78,
    stateName: "Oregon",
  },
  {
    stateID: 79,
    stateName: "Pennsylvania",
  },
  {
    stateID: 80,
    stateName: "Puerto Rico",
  },
  {
    stateID: 81,
    stateName: "Rhode Island",
  },
  {
    stateID: 82,
    stateName: "South Carolina",
  },
  {
    stateID: 83,
    stateName: "South Dakota",
  },
  {
    stateID: 84,
    stateName: "Tennessee",
  },
  {
    stateID: 85,
    stateName: "Texas",
  },
  {
    stateID: 86,
    stateName: "Utah",
  },
  {
    stateID: 87,
    stateName: "Virginia",
  },
  {
    stateID: 88,
    stateName: "Virgin Islands",
  },
  {
    stateID: 89,
    stateName: "Vermont",
  },
  {
    stateID: 90,
    stateName: "Washington",
  },
  {
    stateID: 91,
    stateName: "Wisconsin",
  },
  {
    stateID: 92,
    stateName: "West Virginia",
  },
  {
    stateID: 93,
    stateName: "Wyoming",
  },
];

export const serverTypeDropdownData = [
  { id: "0", type: "Please Select WebServer" },
  { id: "1", type: "AOL" },
  { id: "2", type: "Apache-ModSSL" },
  { id: "3", type: "Apache-SSL (Ben-SSL, not Stronghold)" },
  { id: "4", type: "C2Net Stronghold" },
  { id: "5", type: "Cisco 3000 Series VPN Concentrator" },
  { id: "6", type: "Citrix" },
  { id: "7", type: "Cobalt Raq" },
  { id: "8", type: "Covalent Server Software" },
  { id: "9", type: "Ensim" },
  { id: "10", type: "HSphere" },
  { id: "11", type: "IBM HTTP Server" },
  { id: "12", type: "IBM Internet Connection Server" },
  { id: "13", type: "iPlanet" },
  { id: "14", type: "Java Web Server (Javasoft / Sun)" },
  { id: "15", type: "Lotus Domino" },
  { id: "16", type: "Lotus Domino Go!" },
  { id: "17", type: "Microsoft IIS 1.x to 4.x" },
  { id: "18", type: "Microsoft IIS 5.x and later" },
  { id: "19", type: "Netscape Enterprise Server" },
  { id: "20", type: "Netscape FastTrack" },
  { id: "21", type: "Novell Web Server" },
  { id: "22", type: "Oracle" },
  { id: "23", type: "Plesk" },
  { id: "24", type: "Quid Pro Quo" },
  { id: "25", type: "R3 SSL Server" },
  { id: "26", type: "Raven SSL" },
  { id: "27", type: "RedHat Linux" },
  { id: "28", type: "SAP Web Application Server" },
  { id: "29", type: "Tomcat" },
  { id: "30", type: "Website Professional" },
  { id: "31", type: "WebStar 4.x and later" },
  { id: "32", type: "WebTen (from Tenon)" },
  { id: "33", type: "WHM/CPanel" },
  { id: "34", type: "Zeus Web Server" },
  { id: "35", type: "OTHER" },
];
export const dcvDropdownData = [
  { id: "1", type: "Email Authentication", value: "Email" },
  { id: "2", type: "HTTPS File-Based", value: "File" },
  { id: "3", type: "CNAME", value: "Cname" },
];

export const definitionsData = [
  {
    id: 1,
    content: `1.1. "Application Software Suppliers" means a
                                developer of Internet browser software or other
                                software that displays or uses Sectigo’s
                                Certificates and distributes Sectigo’s root
                                Certificates, such as Google Inc., Microsoft
                                Corporation, Mozilla Foundation, and Opera
                                Software ASA.`,
  },
  {
    id: 2,
    content: `1.2. "Certificate" means a digitally signed
                                electronic data file issued by Sectigo to a
                                person or entity seeking to conduct business
                                over a communications network which contains the
                                identity of the person authorized to use the
                                Digital Signature, a copy of their Public Key, a
                                serial number, a time period during which the
                                data file may be used, and a Digital Signature
                                issued by Sectigo.`,
  },
  {
    id: 3,
    content: `1.3. "Certificate Approver" means a natural
                                person who is either Subscriber, employed by
                                Subscriber, or an authorized agent who has
                                express authority to represent Subscriber to (i)
                                act as a Certificate Requester and to authorize
                                other employees or third parties to act as a
                                Certificate Requester, and (ii) to approve
                                Certificate Requests for EV Certificates
                                submitted by other Certificate Requesters.`,
  },
  {
    id: 4,
    content: `1.4. "Certificate Requester" means a natural
                                person who is either the Subscriber, employed by
                                the Subscriber, an authorized agent who has
                                express authority to represent the Subscriber,
                                or a third party (such as an ISP or hosting
                                company) that completes and submits a
                                Certificate Request for an EV Certificate on
                                behalf of Subscriber.`,
  },
  {
    id: 5,
    content: `1.5. "CPS" refers to the documents explaining
                                Sectigo’s policies and procedures when operating
                                its PKI infrastructure.`,
  },

  {
    id: 6,
    content: `1.6. "Confidential Information" means all
                                material, data, systems, technical operations,
                                and other information concerning Sectigo’s
                                business operations that is not known to the
                                general public, including all information about
                                the Certificate issuance services (such as all
                                Private Keys, personal identification numbers
                                and passwords).`,
  },
  {
    id: 7,
    content: `1.7. "Client Certificate" means a Certificate
                                that is validated by Subscriber and provided by
                                Sectigo that both (i) encrypts and adds a
                                Digital Signature to emails sent by Subscriber
                                or its employees, agents, or contractors and
                                (ii) can be used by employees, agents, or
                                contractors of Subscriber to authenticate access
                                to Subscriber’s secure domains.`,
  },
  {
    id: 8,
    content: `1.8. "Digital Signature" means an encrypted
                                electronic data file which may be attached to or
                                logically associated with other electronic data
                                and which identifies and is uniquely linked to
                                the signatory of the electronic data, is created
                                using the signatory's Private Key and is linked
                                in a way so as to make any subsequent changes to
                                the electronic data detectable.`,
  },
  {
    id: 9,
    content: `1.9. "DV Certificate" means a Certificate that
                                is validated by confirming the domain name
                                listed in the Certificate.`,
  },
  {
    id: 10,
    content: `1.10. "EV Certificate" means a Certificate
                                signed to Sectigo’s EV root certificate and that
                                complies with the EV Guidelines.`,
  },
  {
    id: 11,
    content: `1.11. "EV Guidelines" refers to the official,
                                adopted guidelines governing EV Certificates as
                                established by the CA/Browser Forum that are
                                available online at http://www.cabforum.org.`,
  },
  {
    id: 12,
    content: `1.12. "OV Certificate" means a Certificate that
                                is validated by confirming the existence of the
                                entity named in the Certificate and the domain
                                name listed in the Certificate.`,
  },
  {
    id: 13,
    content: `1.13. "Privacy Policy" means Sectigo’s policies
                                and practices about information privacy
                                accessible via the website:
                                https://www.comodoca.com/en-us/privacy-policy/.`,
  },
  {
    id: 14,
    content: `1.14. "Private Key" means a confidential
                                encrypted electronic data file designed to
                                interface with a Public Key using the same
                                encryption algorithm and which may be used to
                                create Digital Signatures, and decrypt files or
                                messages which have been encrypted with a Public
                                Key.`,
  },
  {
    id: 15,
    content: `1.15. "Public Key" means a publicly available
                                encrypted electronic data file designed to
                                interface with a Private Key using the same
                                encryption algorithm and which may be used to
                                verify Digital Signatures and encrypt files or
                                messages.`,
  },
  {
    id: 16,
    content: `1.16. "Relying Party" means an entity, other
                                than Subscriber, that acts in reliance on a
                                Certificate or a Digital Signature.`,
  },
  {
    id: 17,
    content: `1.17. "Relying Party Agreement" refers to an
                                agreement located on the Sectigo Repository that
                                governs a Relying Party’s use of the Certificate
                                when transacting business with the Subscriber’s
                                website.`,
  },
  {
    id: 18,
    content: `1.18. "Relying Party Warranty" refers to a
                                warranty offered by Sectigo to a Relying Party
                                under the terms and conditions found in the
                                Sectigo Relying Party Agreement in connection
                                with the Relying Party’s use of a Certificate.`,
  },
  {
    id: 19,
    content: `1.19. "Repository" means a publicly available
                                collection of information and databases relating
                                to Sectigo’s Certificate practices and which is
                                available at
                                https://www.comodoca.com/en-us/legal/.`,
  },
  {
    id: 20,
    content: `1.20. "Services" means the Certificates ordered
                                hereunder along with any related TrustLogos
                                and/or Sectigo software, and documentation.`,
  },
  {
    id: 21,
    content: `1.21. "TrustLogo" means a logo provided by
                                Sectigo for use on a Subscriber’s site in
                                connection with an issued Certificate.`,
  },
];

export const subscriptionServiceData = [
  {
    id: 1,
    content: `2.1. Request. When applying for a Certificate,
                                Subscriber shall submit a certificate request in
                                a form specified by Sectigo for each ordered
                                Certificate ("Certificate Request"). Forms for a
                                Certificate Request are available on Sectigo’s
                                website and may be completed electronically.`,
  },
  {
    id: 2,
    content: `2.2. Appointment. When submitting a Certificate
                                Request for an EV Certificate, Subscriber shall
                                designate, and thereby appoint, a Certificate
                                Requester and Certificate Approver. Subscriber
                                shall provide each natural person to be
                                designated a Certificate Requester or
                                Certificate Approver a copy of, or an
                                opportunity to review, this Agreement and the
                                Privacy Policy prior to such appointment. Unless
                                such appointment is revoked by Subscriber
                                sending notice to Sectigo, such appointment
                                lasts for the duration of this Agreement.`,
  },
  {
    id: 3,
    content: `2.3. Validation. Upon Sectigo’s acceptance of
                                Subscriber’s Certificate Request, Sectigo shall
                                attempt to validate the information provided in
                                accordance with the Sectigo CPS, industry
                                standards and, for EV Certificates, the EV
                                Guidelines. If Sectigo chooses to accept the
                                application and can validate Subscriber to
                                Sectigo’s satisfaction, Sectigo shall issue the
                                ordered Certificate(s) to Subscriber. Sectigo
                                may reject a Certificate Request and refuse to
                                issue any ordered Certificate in its sole
                                discretion.`,
  },
  {
    id: 4,
    content: `2.4. Multiple Certificates. This Agreement
                                applies to multiple future Certificate Requests
                                and any resulting Certificates, regardless of
                                when the Certificate is requested or issued.`,
  },
  {
    id: 5,
    content: `2.5. License. Subject to the terms and
                                conditions stated herein, after issuance of a
                                Certificate, Sectigo grants Subscriber a
                                revocable, non-exclusive, non- transferable
                                license to use the issued Certificate (i) on the
                                server hosting the domain name(s) listed in the
                                Certificate if the Certificate is a DV
                                Certificate, OV Certificate, or EV Certificate,
                                or (ii) for purposes of encrypting email
                                messages, if the Certificate is a Client
                                Certificate, until the earlier of expiration or
                                revocation of the Certificate. All rights not
                                expressly granted herein to Subscriber are
                                reserved to Sectigo.`,
  },
  {
    id: 6,
    content: `2.6. TrustLogos. To the extent included with the
                                purchased Services, Sectigo grants Subscriber a
                                license to display purchased TrustLogos on
                                domain(s) secured by a Sectigo Certificate. When
                                revoking a Certificate, Sectigo may also revoke
                                any TrustLogos issued to the same site.
                                Subscriber shall not modify a TrustLogo in any
                                manner. Subscriber shall not display or use a
                                TrustLogo`,
  },
];
export const subscriptionServiceDataPoints = [
  {
    id: 1,
    content: `1) to represent that Sectigo guarantees any
                                non-Sectigo products or services,`,
  },
  {
    id: 2,
    content: `2) on a site that is misleading, defamatory,
                                libelous, disparaging, obscene or otherwise
                                objectionable to Sectigo, or`,
  },
  {
    id: 3,
    content: `3) in a way that harms Sectigo’s rights to its
                                trademarks or harms Sectigo’s business
                                reputation.`,
  },
];
export const subscriberResponsibilitiesData31 = [
  {
    id: 1,
    content: `3.1. Subscriber Obligations. Subscriber shall:`,
  },
];

export const subscriberResponsibilitiesDataPoints1 = [
  {
    id: 1,
    content: `(i) provide accurate and complete
                                    information at all times to SectigoSectigo
                                    in the Certificate request and as otherwise
                                    requested in connection with the issuance of
                                    Certificates;`,
  },
];

export const subscriberResponsibilitiesDataPoints2 = [
  {
    id: 1,
    content: `(ii) install and use each Certificate`,
  },
];
export const installCertificatePoints2 = [
  {
    id: 1,
    content: `1) only on domains owned or controlled
                                        by Subscriber and`,
  },
  {
    id: 2,
    content: `2) only on the server(s) accessible at
                                        the domain name listed in the
                                        Certificate if the Certificate is a DV
                                        Certificate, OV Certificate, or EV
                                        Certificate;`,
  },
];
export const subscriberResponsibilitiesDataPoints3and4 = [
  {
    id: 1,
    content: `(iii) use the Certificates only for the
                                    purposes listed in the Sectigo CPS;`,
  },
  {
    id: 2,
    content: `(iv) review and verify the accuracy of the
                                    data in each Certificate prior to installing
                                    and using the Certificate, and immediately
                                    inform Sectigo if any data listed in a
                                    Certificate changes or ceases to be
                                    accurate;`,
  },
];

export const subscriberResponsibilitiesDataPoints5 = [
  {
    id: 1,
    content: `(v) be responsible, at Subscriber’s expense,
                                    for`,
  },
];
export const responsiblePoints5 = [
  {
    id: 1,
    content: `1) all computers, telecommunication
                                        equipment, software, access to the
                                        Internet, and communications networks
                                        (if any) required to use the
                                        Certificates,`,
  },
  {
    id: 2,
    content: `2) Subscriber’s conduct and its website
                                        maintenance, operation, development, and
                                        content;`,
  },
];
export const subscriberResponsibilitiesDataPoints6and7 = [
  {
    id: 1,
    content: `(vi) promptly inform Sectigo if Subscriber
                                    becomes aware of any misuse of the
                                    Certificates and assist Sectigo in
                                    preventing, curing, and rectifying any
                                    misuse;`,
  },
  {
    id: 2,
    content: `(vii) take all reasonable measures to assure
                                    control of, keep confidential, and properly
                                    protect at all times the Private Key that
                                    corresponds to the Public Key to be included
                                    in a Certificate;`,
  },
];
export const subscriberResponsibilitiesDataPoints8 = [
  {
    id: 1,
    content: `(viii) immediately cease using a Certificate
                                    and the related Private Key and request
                                    revocation of the Certificate if`,
  },
];
export const privateKeyPoints8 = [
  {
    id: 1,
    content: `1) any information in the Certificate is
                                        or becomes incorrect or inaccurate, or`,
  },
  {
    id: 2,
    content: `2) there is any actual or suspected
                                        misuse orcompromise of the Private Key
                                        associated with the Certificate;`,
  },
];

export const subscriberResponsibilitiesDataPoints9to11 = [
  {
    id: 1,
    content: `(ix) cease all use of the Certificate and
                                    its Private Key upon expiration or
                                    revocation of the Certificate;`,
  },
  {
    id: 2,
    content: `(x) comply with all regulations, policies,
                                    and procedures of its networks while using
                                    Certificates,`,
  },
  {
    id: 3,
    content: `(xi) obtain and keep in force any consent,
                                    authorization, permission or license that
                                    may be required for Subscriber’s lawfuluse
                                    of the Certificates; and`,
  },
  {
    id: 4,
    content: `(xii) abide by all applicable laws, rules,
                                    regulations, and guidelines when using a
                                    Certificate.`,
  },
];
export const subscriberResponsibilitiesData32 = [
  {
    id: 1,
    content: `3.2. Restrictions. Subscriber shall not:`,
  },
];
export const subscriberResponsibilitiesData32Points1to4 = [
  {
    id: 1,
    content: `(i) impersonate or misrepresent Subscriber’s
                                    affiliation with any entity,`,
  },

  {
    id: 2,
    content: `(ii) modify, license, create a derivative
                                    work of, or transfer any Certificate (except
                                    as required to use the Certificate) or
                                    Private Key;`,
  },
  {
    id: 3,
    content: `(iii) install or use an issued Certificate
                                    until after Subscriber has reviewed and
                                    verified the Certificate data’s accuracy;`,
  },
  {
    id: 4,
    content: `(iv) upload or distribute any files or
                                    software that may damage the operation of
                                    another’s computer,`,
  },
];
export const subscriberResponsibilitiesDataPoints32point5 = [
  {
    id: 1,
    content: `(v) use the Services to`,
  },
];
export const service32point5 = [
  {
    id: 1,
    content: `1) engage in conduct that is offensive,
                                        abusive, contrary to public morality,
                                        indecent, defamatory, obscene, or
                                        menacing,`,
  },
  {
    id: 2,
    content: `2) breach the confidence of a third
                                        party,`,
  },
  {
    id: 3,
    content: `3) cause Sectigo or a third party
                                        distress, annoyance, denial of any
                                        service, disruption or inconvenience,`,
  },
  {
    id: 4,
    content: `4) send or receive unsolicited bulk
                                        correspondence or`,
  },
  {
    id: 5,
    content: `5) create a Private Key that is
                                        substantially similar to a Sectigo or
                                        third party’s Private Key, and/or`,
  },
];
export const subscriberResponsibilitiesData32Points6 = [
  {
    id: 1,
    content: `(vi) make representations regarding the
                                    Service to any third party except as agreed
                                    to in writing by Sectigo.`,
  },
];
export const revocationData41 = [
  {
    id: 1,
    content: `4.1. Sectigo may revoke a Certificate if Sectigo
                                believes or has reason to believe that:`,
  },
];
export const revocationData41Points1to5 = [
  {
    id: 1,
    content: `(i) Subscriber requested revocation of the
                                    Certificate;`,
  },
  {
    id: 2,
    content: `(ii) Subscriber did not authorize the
                                    Certificate Request and has not
                                    retroactively granted authorization;`,
  },
  {
    id: 3,
    content: `(iii) Subscriber breached the terms of this
                                    Agreement;`,
  },
  {
    id: 4,
    content: `(iv) the Private Key corresponding to the
                                    Public Key in the Certificate has been
                                    disclosed, compromised, or no longer
                                    complies with industry standards;`,
  },
  {
    id: 5,
    content: `(v) the Private Key of the subordinate
                                    Certificate used to issue the Certificate
                                    has been compromised or no longer complies
                                    with industry standards;`,
  },
];
export const revocationData41Points6 = [
  {
    id: 1,
    content: `(vi) the Certificate has been`,
  },
];
export const certificate41point6 = [
  {
    id: 1,
    content: `1) misused,`,
  },
  {
    id: 2,
    content: `2) used contrary to law, rule,
                                        regulation, or industry standard or`,
  },
  {
    id: 3,
    content: `3) used, directly or indirectly, for
                                        illegal or fraudulent purposes;`,
  },
];

export const revocationData41Points7to10 = [
  {
    id: 1,
    content: `(vii) information in the Certificate is
                                    inaccurate or misleading,`,
  },
  {
    id: 2,
    content: `(viii) the technical content or the format
                                    of the Certificate presents an unacceptable
                                    risk, in Sectigo’s opinion, to Application
                                    Software Suppliers or Relying Parties;`,
  },
  {
    id: 3,
    content: `(ix) for EV Certificates, Subscriber loses
                                    exclusive control over a domain name listed
                                    in the Certificate;`,
  },
  {
    id: 4,
    content: `(x) the Certificate was not issued or used
                                    in accordance with Sectigo’s CPS, industry
                                    standards, or, for EV Certificates, the EV
                                    Guidelines;`,
  },
];
export const revocationData41Points11 = [
  {
    id: 1,
    content: `(xi) Sectigo`,
  },
];
export const sectigo41Point11 = [
  {
    id: 1,
    content: `1) ceased operations or`,
  },
  {
    id: 2,
    content: `2) is no longer allowed to issue the
                                        Certificate, and no other certificate
                                        authority has agreed to provide
                                        revocation support for the Certificate;`,
  },
];
export const revocationData41Points12to16 = [
  {
    id: 1,
    content: `(xii) for wildcard Certificates, the
                                    Certificate has been used to authenticate a
                                    fraudulently misleading subordinate
                                    fullyqualified domain name;`,
  },
  {
    id: 2,
    content: `(xiii) Subscriber is added as a denied party
                                    or prohibited person to a blacklist, or is
                                    operating from a prohibited destination
                                    under the laws of Sectigo’s jurisdiction of
                                    operation;`,
  },
  {
    id: 3,
    content: `(xiv) the Certificate was issued to
                                    publishers of malicious software;`,
  },
  {
    id: 4,
    content: `(xv) Sectigo’s CPS authorizes revocation of
                                    the Certificate; or`,
  },
  {
    id: 5,
    content: `(xvi) the Certificate, if not revoked, will
                                    compromise the trust status of Sectigo.`,
  },
];
export const revocationData42 = [
  {
    id: 1,
    content: `4.2. After revoking a Certificate, Sectigo may,
                                in its sole discretion, reissue the Certificate
                                to Subscriber and/or terminate this Agreement.`,
  },
];

export const paymentData51 = [
  {
    id: 1,
    content: `5.1. Payment. Subscriber shall pay all
                                applicable fees for the Certificate before the
                                Certificate is issued. Certificate fees are
                                provided to Subscriber during the application
                                process. All payments are non- refundable,
                                except that the Certificate’s seller will refund
                                a payment if, before twenty (20) business days
                                after the Certificate’s issuance, the Subscriber
                                has`,
  },
];
export const paymentData51Point1 = [
  {
    id: 1,
    content: `1) not used the Certificate, and`,
  },
  {
    id: 2,
    content: `2) made a written request to Sectigo for the
                                    Certificate’s revocation.`,
  },
];
export const paymentData52 = [
  {
    id: 1,
    content: `5.2. Taxes. All amounts payable by Subscriber
                                under this Agreement are net amounts and are
                                payable in full, without deduction for taxes or
                                duties of any kind. Subscriber will be
                                responsible for, and will promptly pay, all
                                required taxes and duties of any kind
                                (including, but not limited to, sales, use and
                                withholding taxes) associated with this
                                Agreement, except for taxes based on Sectigo’s
                                net income. If Sectigo is required to collect,
                                or pays on Subscriber’s behalf, any taxes or
                                duties for which Subscriber is responsible, then
                                Subscriber will pay or reimburse Sectigo, as the
                                case may be, for all such amounts.`,
  },
];

export const terminationData61 = [
  {
    id: 1,
    content: `6.1. Term. Unless otherwise terminated as
                                allowed herein, this Agreement is effective upon
                                Subscriber’s acceptance and lasts for as long as
                                a Certificate issued under this Agreement is
                                valid.`,
  },
];
export const terminationData62 = [
  {
    id: 1,
    content: `6.2. Termination. Either party may terminate
                                this Agreement with twenty (20) business days’
                                notice for convenience. Sectigo may terminate
                                this Agreement immediately without notice if`,
  },
];
export const termsAndTerminationPoints62 = [
  {
    id: 1,
    content: `(i) Subscriber materially breaches this
                                    Agreement,`,
  },
  {
    id: 2,
    content: `(ii) if Sectigo revokes a Certificate as
                                    allowed herein,`,
  },
  {
    id: 3,
    content: `(iii) if Sectigo rejects Subscriber’s
                                    initial Certificate Request,`,
  },
  {
    id: 4,
    content: `(iv) Sectigo cannot satisfactorily validate
                                    Subscriber in accordance with section 2.3,
                                    or`,
  },
  {
    id: 5,
    content: `(v) if industry standards change in a way
                                    that affects the validity of the
                                    Certificates ordered by Subscriber.`,
  },
];
export const terminationData63 = [
  {
    id: 1,
    content: `6.3. Events Upon Termination. After termination,
                                Sectigo may revoke any other Certificates issued
                                to Subscriber without further notice. Subscriber
                                shall pay any amounts still owed for the
                                Certificates. Sectigo is not obligated to refund
                                any payment made by Subscriber upon termination
                                of this Agreement.`,
  },
];
export const intellectualData71 = [
  {
    id: 1,
    content: `7.1. Sectigo IP Rights. Sectigo retains, and
                                Subscriber shall not obtain or claim, all title,
                                interest, and ownership rights in:`,
  },
];
export const intellectualData71Points = [
  {
    id: 1,
    content: `(i) the Services, including issued
                                    Certificates,`,
  },
  {
    id: 2,
    content: `(ii) all copies or derivative works of the
                                    Services, regardless of who produced,
                                    requested, or suggested the copy or
                                    derivative work,`,
  },
  {
    id: 3,
    content: `(iii) all documentation and materials
                                    provided by Sectigo, and`,
  },
  {
    id: 4,
    content: `(iv) all of Sectigo’s copyrights, patent
                                    rights, trade secret rights and other
                                    proprietary rights.`,
  },
];
export const intellectualData72 = [
  {
    id: 1,
    content: `7.2. Trademarks. Subscriber shall not use a
                                Sectigo trademark without Sectigo’s written
                                consent. Sectigo consents to use of trademarks
                                in connection with purchased TrustLogos.`,
  },
];

export const intellectualData73 = [
  {
    id: 1,
    content: `7.3. Subscriber IP. Subscriber grants Sectigo a
                                worldwide, non-exclusive, non-sublicensable
                                right, to use any Subscriber trademark, service
                                mark or tradename, to perform its obligations
                                under this Agreement, and to identify Subscriber
                                in Sectigo’s customer lists and other marketing
                                and promotional materials and communications
                                referencing Subscriber as a customer of Sectigo.`,
  },
];

export const confidentialityData81 = [
  {
    id: 1,
    content: `8.1. Except as allowed herein, a party
                                ("Receiving Party") shall not use or disclose
                                any Confidential Information provided by the
                                other party (the "Disclosing Party") other than
                                for the purpose of performing its obligations
                                under this Agreement. The Receiving Party shall
                                take reasonable measures to prevent unauthorized
                                disclosure and shall ensure that any person
                                receiving Confidential Information complies with
                                the restrictions in this section. The Receiving
                                Party may disclose Confidential Information if
                                the information:`,
  },
];
export const confidentialityData81Points = [
  {
    id: 1,
    content: `(i) is already possessed by the Receiving
                                    Party before receipt from the Disclosing
                                    Party;`,
  },
  {
    id: 2,
    content: `(ii) is or becomes public domain without
                                    fault of the Receiving Party;`,
  },
  {
    id: 3,
    content: `(iii) is received by the Receiving Party
                                    from a third party who is not under an
                                    obligation of confidentiality or a
                                    restriction on the use and disclosure of the
                                    information,`,
  },
  {
    id: 4,
    content: `(iv) is disclosed in response to the
                                    requirements of a law, governmental order,
                                    regulation, or legal process and the
                                    Receiving Party first gives prior notice to
                                    the Disclosing Party of the requirement to
                                    disclose the information, or`,
  },
  {
    id: 5,
    content: `(v) is disclosed under operation of law to
                                    the public without a duty of
                                    confidentiality.`,
  },
];
export const confidentialityData82 = [
  {
    id: 1,
    content: `8.2. A party asserting one of the exceptions to
                                Confidential Information above shall prove the
                                assertion using verifiable documentary evidence.
                                The confidentiality obligations contained in
                                this section apply for the duration of this
                                Agreement plus five years after its termination;
                                provided, however, with respect to Confidential
                                Information that constitutes a trade secret, for
                                as long as that Confidential Information remains
                                a trade secret.`,
  },
];
export const privacyData91 = [
  {
    id: 1,
    content: `9.1. Privacy Policy. Sectigo shall follow its
                                Privacy Policy when processing Subscriber’s
                                information. Sectigo may amend its Privacy
                                Policy at any time in accordance with the
                                process outlined therein. Subject to Section
                                9.2, Sectigo shall use reasonable efforts in
                                protecting Subscriber’s information. Subscriber
                                acknowledges that risks remain that are beyond
                                Sectigo’s reasonable control and waives all
                                liability of Sectigo for these risks.`,
  },
];
export const privacyData92 = [
  {
    id: 1,
    content: `9.2. Disclosures. Subscriber acknowledges and
                                understands that`,
  },
];
export const privacyData92Points = [
  {
    id: 1,
    content: `(i) issued Certificates are embedded with
                                    information about Subscriber (such as
                                    Subscriber’s domain name, jurisdiction of
                                    incorporation, or email address), which
                                    varies depending on the type Certificate
                                    ordered by Subscriber,`,
  },
  {
    id: 2,
    content: `(ii) issued Certificates may be logged in
                                    publicly-accessible Certificate transparency
                                    databases for purposes of detecting and
                                    preventing phishing attacks and other forms
                                    of fraud, and`,
  },
  {
    id: 3,
    content: `(iii) Certificates logged in
                                    publicly-accessible Certificate transparency
                                    databases cannot be removed.`,
  },
];
export const privacyData93 = [
  {
    id: 1,
    content: `9.3. Retention. Information provided by
                                Subscriber for the validation of a Certificate
                                shall be retained by Sectigo in accordance with
                                the CPS and industry standards for not less than
                                seven (7) years, or as necessary to comply with
                                applicable laws. The retention period shall
                                begin on the date of the rejection, expiration,
                                or revocation of a Certificate. Copies of
                                Certificates are held, regardless of their
                                status, whether valid, expired, or revoked.`,
  },
];

export const indemnificationData10 = [
  {
    id: 1,
    content: `10.1. Indemnification. Subscriber shall
                                indemnify Sectigo and its affiliates and their
                                respective directors, officers, employees, and
                                agents (each an "Indemnified Person") against
                                all liabilities, losses, expenses, or costs
                                (including reasonable attorney’s fees) that,
                                directly or indirectly, are based on
                                Subscriber’s breach of this Agreement,
                                information provided by Subscriber, or
                                Subscriber’s or its customers’ infringement on
                                the rights of a third party.`,
  },
  {
    id: 2,
    content: `10.2. Indemnification Procedure. Sectigo shall
                                notify Subscriber promptly of any demand for
                                indemnification. However, Sectigo’s failure to
                                notify will not relieve Subscriber from its
                                indemnification obligations except to the extent
                                that the failure to provide timely notice
                                materially prejudices Subscriber. Subscriber may
                                assume the defense of any action, suit, or
                                proceeding giving rise to an indemnification
                                obligation unless assuming the defense would
                                result in potential conflicting interests as
                                determined by the Indemnified Person in good
                                faith. Subscriber may not settle any claim,
                                action, suit or proceeding related to this
                                Agreement unless the settlement also includes an
                                unconditional release of all Indemnified Persons
                                from liability.`,
  },
  {
    id: 3,
    content: `10.3. Additional Liability. The indemnification
                                obligations of Subscriber are not Sectigo’s sole
                                remedy for Subscriber’s breach and are in
                                addition to any other remedies Sectigo may have
                                against Subscriber under this Agreement.
                                Subscriber’s indemnification obligations survive
                                the termination of this Agreement.`,
  },
];

export const warrantiesAndRepresentationData = [
  {
    id: 1,
    content: `(i) the subject named in each ordered EV
                                Certificate has exclusive control of the domain
                                name(s) listed in such Certificate;`,
  },
  {
    id: 2,
    content: `(ii) Subscriber has all necessary consents to
                                appoint each Certificate Requester and
                                Certificate Approver, and that each Certificate
                                Requester and Certificate Approver has been
                                provided a copy of, or an opportunity to review,
                                this Agreement and Sectigo’s Privacy Policy;`,
  },
  {
    id: 3,
    content: `(iii) Subscriber has full power and authority to
                                enter into this Agreement and perform its
                                obligations hereunder; and`,
  },
  {
    id: 4,
    content: `(iv) for EV Certificates, the individual
                                accepting this Agreement is expressly authorized
                                by Subscriber to sign this Agreement for
                                Subscriber.`,
  },
];

export const disclaimersData121 = [
  {
    id: 1,
    content: `12.1. Relying Party Warranties. Subscriber
                                acknowledges that the Relying Party Warranty is
                                only for the benefit of Relying Parties.
                                Subscriber does not have rights under the
                                warranty, including any right to enforce the
                                terms of the warranty or make a claim under the
                                warranty.`,
  },
];
export const disclaimersData122 = [
  {
    id: 1,
    content: `12.2. Exclusion of Warranties. THE SERVICES ARE
                                PROVIDED "AS IS" AND "AS AVAILABLE". Sectigo
                                EXPRESSLY DISCLAIMS ALL IMPLIED AND EXPRESS
                                WARRANTIES IN THE SERVICES. THIS DISCLAIMER
                                INCLUDES ALL WARRANTIES OF MERCHANTABILITY,
                                FITNESS FOR A PARTICULAR PURPOSE, AND
                                NON-INFRINGEMENT AND IS EFFECTIVE TO THE MAXIMUM
                                EXTENT ALLOWED BY LAW. Sectigo DOES NOT
                                GUARANTEE THAT`,
  },
];
export const disclaimersData122Points = [
  {
    id: 1,
    content: `1) THE SERVICES WILL MEET SUBSCRIBER’S
                                    REQUIREMENTS OR EXPECTATIONS OR`,
  },
  {
    id: 2,
    content: `2) THAT ACCESS TO THE SERVICES WILL BE
                                    UNINTERRUPTED, TIMELY, SECURE, OR
                                    ERROR-FREE.`,
  },
];
export const disclaimersData123 = [
  {
    id: 1,
    content: `12.3. Limitation on Liability. SUBJECT TO
                                SECTION 12.4, THE TOTAL LIABILITY OF Sectigo AND
                                ITS AFFILIATES, AND EACH OF THEIR OFFICERS,
                                DIRECTORS, PARTNERS, EMPLOYEES, AND CONTRACTORS,
                                RESULTING FROM OR CONNECTED TO THIS AGREEMENT IS
                                LIMITED TO THE AMOUNT PAID BY SUBSCRIBER FOR THE
                                SERVICES GIVING RISE TO THE LIABILITY.
                                SUBSCRIBER WAIVES ALL LIABILITY FOR ANY SPECIAL,
                                INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES.
                                THIS WAIVER INCLUDES ALL DAMAGES FOR LOST
                                PROFITS, REVENUE, USE, OR DATA AND APPLIES EVEN
                                IF SECTIGO IS AWARE OF THE POSSIBILITY OF SUCH
                                DAMAGES. These limitations apply to the maximum
                                extent permitted by law regardless of`,
  },
];
export const disclaimersData123Points = [
  {
    id: 1,
    content: `1) the reason for or nature of the
                                    liability, including tort claims,`,
  },
  {
    id: 2,
    content: `2) the number of any claims,`,
  },
  {
    id: 3,
    content: `3) the extent or nature of the damages, and`,
  },
  {
    id: 4,
    content: `4) whether any other provisions of this
                                    Agreement have been breached or proven
                                    ineffective.`,
  },
];
export const disclaimersData124 = [
  {
    id: 1,
    content: `12.4. Exception. Nothing in this Agreement
                                excludes or limits the liability of either party
                                for death or personal injury resulting from the
                                negligence of that party or for any statements
                                made fraudulently by either party.`,
  },
];
export const miscellaneousData13Points1to4 = [
  {
    id: 1,
    content: `13.1. Relationship of the Parties. The status of
                                a party under this Agreement shall be that of an
                                independent contractor. Nothing contained in
                                this Agreement shall be construed as creating a
                                partnership, joint venture or agency
                                relationship between the parties or, except as
                                otherwise expressly provided in this Agreement,
                                as granting either party the authority to bind
                                or contract any obligation in the name of or on
                                the account of the other party or to make any
                                statements, representations, warranties or
                                commitments on behalf of the other party. All
                                persons employed by a party shall be employees
                                of such party and not of the other party and all
                                costs and obligations incurred by reason of any
                                such employment shall be for the account and
                                expense of such party.`,
  },
  {
    id: 2,
    content: `13.2. Force Majeure and Internet Frailties.
                                Other than for payment obligations by
                                Subscriber, neither party will be liable for a
                                delay or failure to perform an obligation to the
                                extent that the delay or failure is caused by an
                                occurrence beyond the party's reasonable
                                control. Each party acknowledges that the
                                operation of the Internet is beyond the other
                                party’s reasonable control, and neither party
                                will be liable for a delay or failure caused by
                                an interruption or failure of telecommunication
                                or digital transmission links, Internet
                                slow-downs or failures, or other such
                                transmission failure.`,
  },
  {
    id: 3,
    content: `13.3. Injunctive Relief. Subscriber acknowledges
                                that its breach of this Agreement will result in
                                irreparable harm to Sectigo that cannot
                                adequately be redressed by compensatory damages.
                                Accordingly, in addition to any other legal
                                remedies which may be available, Sectigo may
                                seek and obtain an injunctive order against a
                                breach or threatened breach of this Agreement by
                                Subscriber.`,
  },
  {
    id: 4,
    content: `13.4. Limitation on Actions. Except for actions
                                and claims related to a party’s indemnification
                                and confidentiality obligations, all claims and
                                actions arising from this Agreement must be
                                brought within one (1) year from the date when
                                the cause of action occurred.`,
  },
];
export const miscellaneousData13Points5 = [
  {
    id: 1,
    content: `13.5. Remedy. Subscriber’s sole remedy for a
                                defect in the Services is to have Sectigo use
                                reasonable efforts to correct the defect.
                                Sectigo is not obligated to correct a defect if`,
  },
];
export const miscellaneousData13Point5points = [
  {
    id: 1,
    content: `(i) the Service was misused, damaged, or
                                    modified,`,
  },
  {
    id: 2,
    content: `(ii) Subscriber did not immediately report
                                    the defect to Sectigo, or`,
  },
  {
    id: 3,
    content: `(iii) Subscriber breached any provision of
                                    this Agreement.`,
  },
];
export const miscellaneousData13Points6to8 = [
  {
    id: 1,
    content: `13.6. Notices. Subscriber shall send all notices
                                to Sectigo by first className mail in writing in
                                English, with return receipt requested, to
                                Sectigo CA Limited, 26 Office Village, 3rd
                                Floor, Exchange Quay, Trafford Road, Salford,
                                Manchester M5 3EQ, United Kingdom. Sectigo shall
                                send all notices to Subscriber’s contact
                                information listed on its Certificate
                                application. Sectigo may send notices by mail,
                                email, or facsimile.`,
  },
  {
    id: 2,
    content: `13.7. Entire Agreement. This Agreement and all
                                documents referred to herein constitutes the
                                entire agreement between the parties,
                                superseding all other agreements that may exist
                                with respect to the subject matter. Section
                                headings are for reference and convenience only
                                and are not part of the interpretation of this
                                Agreement.`,
  },
  {
    id: 3,
    content: `13.8. Amendments. Sectigo may amend this
                                Agreement, the CPS, the Relying Party Agreement,
                                the Relying Party Warranty, its website, and any
                                documents listed in its Repository at any time
                                by posting either the amendment or the amended
                                document in the Repository. Subscriber shall
                                periodically review the Repository to be aware
                                of any changes. Subscriber may terminate this
                                Agreement if Subscriber does not agree to the
                                amendment. Subscriber’s continued use of the
                                Services after an amendment is posted
                                constitutes Subscriber’s acceptance of the
                                amendment.`,
  },
];
export const miscellaneousData13Point9 = [
  {
    id: 1,
    content: `13.9. Rules of Interpretation. Except as
                                otherwise expressly provided in this Agreement,
                                the following rules of interpretation apply to
                                this Agreement:`,
  },
];
export const miscellaneousData13Point9Points = [
  {
    id: 1,
    content: `(i) the singular includes the plural and the
                                    plural includes the singular;`,
  },
  {
    id: 2,
    content: `(ii) "or" and "any" are not exclusive and
                                    "include" and "including" are not limiting;`,
  },
  {
    id: 3,
    content: `(iii) a reference to any agreement or other
                                    contract includes permitted supplements and
                                    amendments thereto;`,
  },
  {
    id: 4,
    content: `(iv) a reference to a law includes any
                                    amendment or modification to such law and
                                    any rules or regulations issued thereunder;`,
  },
  {
    id: 5,
    content: `(v) a reference to a person or entity
                                    includes its permitted successors and
                                    assigns; and`,
  },
  {
    id: 6,
    content: `(vi) a reference in this Agreement to an
                                    Article, Section, Annex, Exhibit or Schedule
                                    is to the Article, Section, Annex, Exhibit
                                    or Schedule of this Agreement.`,
  },
];
export const miscellaneousData13Points10to11 = [
  {
    id: 1,
    content: `13.10. Waiver. A party’s failure to enforce a
                                provision of this Agreement will not waive the
                                party’s right to enforce the same provision
                                later or right to enforce any other provision of
                                this Agreement. To be effective, all waivers
                                must be both in writing and signed by the party
                                benefiting from the waived provision.`,
  },
  {
    id: 2,
    content: `13.11. Assignment. Subscriber may not assign any
                                of its rights or obligations under this
                                Agreement without the prior written consent of
                                Sectigo. Any transfer without consent is void.
                                Sectigo may assign its rights and obligations
                                without Subscriber’s consent.`,
  },
];
export const miscellaneousData13Point12 = [
  {
    id: 1,
    content: `13.12. Governing Law and Venue. This Agreement
                                and any disputes relating to the Certificates
                                provided hereunder shall be governed and
                                interpreted according to each of the following
                                laws, respectively, without regard to its
                                conflicts of law provisions:`,
  },
];
export const miscellaneousData13Point12Poins = [
  {
    id: 1,
    content: `(a) the laws of the State of New Jersey, if
                                    Subscriber is located in North America; or`,
  },
  {
    id: 2,
    content: `(b) the laws of England and Wales, if
                                    Subscriber is located outside of North
                                    America. The parties agree to the exclusive
                                    jurisdiction of`,
  },
  {
    id: 3,
    content: `(a) the courts of New Jersey if Subscriber
                                    is located in North America, or`,
  },
  {
    id: 4,
    content: `(b) the courts of England and Wales if the
                                    Subscriber is located outside of North
                                    America.`,
  },
];
export const miscellaneousData13Point13to16 = [
  {
    id: 1,
    content: `13.13. Severability. Any provision determined
                                invalid or unenforceable by rule of law will be
                                reformed to the minimum extent necessary to make
                                the provision valid and enforceable. If
                                reformation is not possible, the provision is
                                deemed omitted and the balance of this Agreement
                                remains valid and enforceable.`,
  },
  {
    id: 2,
    content: ` 13.14. Survival. All provisions of this
                                Agreement related to confidentiality,
                                proprietary rights, indemnification, and
                                limitations of liability survive the termination
                                of this Agreement.`,
  },
  {
    id: 3,
    content: `13.15. Rights of Third Parties. Except for
                                Application Software Suppliers, nothing in this
                                Agreement is intended or shall be construed to
                                give any person or entity any legal or equitable
                                right, remedy, or claim under or in respect of
                                this Agreement.`,
  },
  {
    id: 4,
    content: `13.16. Counterparts; Integration; Effectiveness.
                                This Agreement may be executed by written,
                                facsimile or electronic means, and in one or
                                more counterparts (and by different parties
                                hereto in different counterparts), each of which
                                shall constitute an original, but all of which
                                when taken together shall constitute a single
                                contract.`,
  },
];
