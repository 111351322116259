import React from "react";
import { Spinner } from "react-bootstrap";
import csrLoadingImage from "assets/images/loading.gif";

const Loader = () => {
  return (
    <div className="loading">
      <img alt="Sample CSR" src={csrLoadingImage} className="loader" />
    </div>

    // <Spinner className="loader" animation="border" role="status">
    //   <span className="visually-hidden">Loading...</span>
    // </Spinner>
  );
};

export default Loader;
