import { ProductDetailsContext } from "Context/ProductDetailContext";
import React, { useContext, useEffect, useState } from "react";
import Navbar from "Shared/Navbar";
import sectigologo from "assets/images/sectigo-logo.svg";
import { useNavigate, useParams } from "react-router-dom";
import Header from "Shared/Header";
import {
  countryListData,
  stateListDataIndia,
  stateListDataUsa,
} from "Utils/Data";
import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Error, { ErrorCsr, ErrorValidEmail } from "Shared/Error";
import {
  COMPANY_EMAIL_REGEX,
  EMAIL_REGEX,
  INDIA,
  UNITED_STATES,
} from "Utils/Constants";
import Loader from "Shared/Loader";

const CodeSigning = () => {
  const {
    step,
    isOv,
    setStepOrganizationDetail,
    productName,
    loading,
    isCodeSigning,
    setLoading,
    values,
    generateCertificate,
    error,
    setError,
    getProductDetailsByDecodingCsr,
    valuesCsr,
    inviteKeyObj,
    getJWTByUid,
    productType,
  } = useContext(ProductDetailsContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { id } = useParams();
  let navigate = useNavigate();
  const [selectedCountryName, setSelectedCountryName] = useState("");
  const [show, setShow] = useState(false);
  const [individualRadio, setIndividualRadio] = useState(false);
  const [origanizationRadio, setOriganizationRadio] = useState(false);
  const testKeySet = localStorage.setItem("inviteKey", id);
  const testKey = localStorage.getItem("inviteKey");

  const countryListDropdown = countryListData.map((item) => {
    return (
      <option key={item.id} value={item.CountryValue}>
        {item.name}
      </option>
    );
  });
  const stateListDropdownUSA = stateListDataUsa.map((state) => {
    return (
      <option key={state.stateID} value={state.stateName}>
        {state.stateName}
      </option>
    );
  });
  const stateListDropdownIndia = stateListDataIndia.map((state) => {
    return (
      <option key={state.stateID} value={state.stateName}>
        {state.stateName}
      </option>
    );
  });
  const handleCountryChange = (e) => {
    setSelectedCountryName(e.target.value);
  };

  const onSubmit = async (data) => {
    console.log("data", data);
    setLoading("true");
    values.csr = encodeURIComponent(data.csr);
    valuesCsr.csr = encodeURIComponent(data.csr);
    const resultCsr = await getProductDetailsByDecodingCsr();
    if (parseInt(resultCsr.data.keySize) < 3072) {
      setError("Csr key size should be at least 3072 bits in length");
    }
    values.orgLegalName = data.orgLegalName;
    values.organizationalUnitName = data.organizationalUnitName;
    values.orgAddress1 = data.orgAddress1;
    values.orgCity = data.orgCity;
    values.orgState = data.orgState;
    values.orgCountry = data.orgCountry;
    values.orgZip = data.orgZip;
    values.orgFax = data.orgFax;
    values.postOfficeBox = data.postOfficeBox;
    values.orgDUNS = data.orgDUNS;
    values.orgAssumedName = data.orgAssumedName;
    values.techTitle = data.techTitle;
    values.techFirstName = data.techFirstName;
    values.techLastName = data.techLastName;
    values.techEmail = data.techEmail;
    values.techPhone = data.techPhone;
    values.stateOrProvinceName = data.orgState;
    if (parseInt(resultCsr.data.keySize) >= 3072) {
      const result = await generateCertificate();
      if (result.data.errorCode === "0") {
        navigate("/thankyou");
      } else {
        setError(result.data.errorMessage);
      }
    }
  };
  useEffect(() => {
    if (error !== "") {
      window.scrollTo(0, 0);
      alert(error);
      setLoading("");
    }
  }, [error]);
  useEffect(() => {
    setSelectedCountryName(selectedCountryName);
  }, [selectedCountryName]);
  useEffect(() => {
    setShow(true);
  }, []);
  useEffect(() => {
    inviteKeyObj.inviteKey = id;
    getJWTByUid();
    setStepOrganizationDetail();
  }, [id]);
  useEffect(() => {
    console.log("isCodeSigning", isCodeSigning);
    if (productType.length > 0) {
      if (isCodeSigning === false) {
        alert("Please Enter Valid Invite-Key");
        navigate("/error");
      }
    }
  }, [isCodeSigning]);

  return (
    <div>
      <Header />
      <section className="py-3 border-top">
        <div className="container">
          <div className="d-flex flex-sm-row flex-column justify-content-between align-items-center">
            <h1 className="mb-md-0 mb-2 text-md-left text-center fs-3">
              {isCodeSigning ? "Sectigo Code Signing" : productName}
            </h1>
            <span className="brand-logo">
              <img alt="Certificate Generation" src={sectigologo} />
            </span>
          </div>
        </div>
      </section>
      <div aria-disabled={loading === "true"} className="content bg-light py-5">
        <div className="container">
          <Navbar step={step} isOv={isOv} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-area bg-white p-md-5 p-3">
              {loading === "true" ? <Loader /> : ""}
              {error ? <div className="errormsg mb-2">{error}</div> : ""}
              <div id="msg"></div>
              <div className="form-area">
                <h3 className="mb-3">Certificate Details</h3>
                <input
                  id="hdnshowcomopop"
                  name="hdnshowcomopop"
                  type="hidden"
                />
                <input
                  id="hdnshowBrowser"
                  name="hdnshowBrowser"
                  type="hidden"
                />
                <div className="mb-3 row">
                  <label className="col-sm-4 fw-bold align-self-center">
                    Primary Contact Email Address:
                    <span className="text-danger"> </span>
                  </label>
                  <div className="col-sm-8">
                    <input
                      className="form-control"
                      id="primaryContactEmail"
                      name="primaryContactEmail"
                      {...register("primaryContactEmail", {
                        required: true,
                        pattern: {
                          value: COMPANY_EMAIL_REGEX,
                        },
                      })}
                      type="text"
                    />
                    <small className="text-muted">
                      (This will appear in your Certificate)
                    </small>
                    {errors.primaryContactEmail ? (
                      <p className="field-validation-error">
                        {errors.primaryContactEmail.type === "required" && (
                          <Error />
                        )}
                        {errors.primaryContactEmail.type === "pattern" &&
                          "This product requires a company email address"}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-4 fw-bold align-self-center">
                    Validity:
                  </label>
                  <div className="col-sm-8">12 Month(s)</div>
                </div>
                <div className="mb-3 row d-none">
                  <label className="col-sm-4 fw-bold align-self-center">
                    Delivery Option:
                  </label>
                  <div className="col-sm-8">
                    <div className="custom-control custom-radio custom-control-inline">
                      <input
                        // checked="true"
                        className="custom-control-input"
                        id="rbtStandard"
                        name="DeliveryOption"
                        type="radio"
                        // value="496"
                      />
                      <label className="custom-control-label" htmlFor="new">
                        Standard
                      </label>
                    </div>
                    <div className="custom-control custom-radio custom-control-inline">
                      <input
                        className="custom-control-input"
                        id="rbtExpedited"
                        name="DeliveryOption"
                        type="radio"
                        // value="497"
                      />
                      <label className="custom-control-label" htmlFor="Renew">
                        Expedited
                      </label>
                    </div>
                    <div className="custom-control custom-radio custom-control-inline">
                      <input
                        className="custom-control-input"
                        id="rbtInternational"
                        name="DeliveryOption"
                        type="radio"
                        // value="498"
                      />
                      <label className="custom-control-label" htmlFor="Renew">
                        International
                      </label>
                    </div>
                  </div>
                </div>
                <Modal centered show={show}>
                  <div className="modal-header">
                    <h5 className="modal-title">Individual or Company?</h5>
                  </div>
                  <div className="modal-body">
                    <p>
                      Are you trying to obtain a Code Signing Certificate as an
                      Individual developer on behalf of your
                      Company/Organization? Please select whether this is for an
                      Individual or Organization.
                    </p>
                    <div className="d-flex">
                      <div className="ms-1">
                        <input
                          type="radio"
                          id="rbtindividual"
                          value="Individual"
                          onClick={() => {
                            setIndividualRadio(true);
                            setShow(false);
                          }}
                        />
                        <label className="ms-2" htmlFor="rbtindividual">
                          Individual
                        </label>
                      </div>
                      <div className="ms-3">
                        <input
                          type="radio"
                          id="rbtorganisation"
                          value="Organization"
                          onClick={() => {
                            setOriganizationRadio(true);
                            setShow(false);
                          }}
                        />
                        <label className="ms-2" htmlFor="rbtorganisation">
                          Organization
                        </label>
                      </div>
                    </div>
                  </div>
                </Modal>
                <h3 className="subheading border-top pt-3 mt-4 mb-3">
                  {individualRadio
                    ? "Individual Details"
                    : "Organization Details"}
                </h3>
                <div className="mb-3 row position-relative">
                  <label className="col-sm-4 fw-bold align-self-center">
                    {individualRadio
                      ? "Full Name:"
                      : "Organization Name (business entity):"}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="col-sm-8">
                    <Form.Control
                      className={
                        errors.orgLegalName
                          ? "form-control border-danger"
                          : "form-control"
                      }
                      id="orgLegalName"
                      name="orgLegalName"
                      type="text"
                      {...register("orgLegalName", { required: true })}
                    />
                    {errors.orgLegalName && <Error />}
                  </div>
                </div>
                <div className="mb-3 row position-relative">
                  <label className="col-sm-4 fw-bold align-self-center">
                    Organizational Unit Name (department):
                    <span className="text-danger">*</span>
                  </label>
                  <div className="col-sm-8">
                    <Form.Control
                      className={
                        errors.organizationalUnitName
                          ? "form-control border-danger"
                          : "form-control"
                      }
                      id="organizationalUnitName"
                      name="organizationalUnitName"
                      type="text"
                      {...register("organizationalUnitName", {
                        required: true,
                      })}
                    />
                    {errors.organizationalUnitName && <Error />}
                  </div>
                </div>
                <div className="mb-3 row position-relative">
                  <label className="col-sm-4 fw-bold align-self-center">
                    Address:<span className="text-danger">*</span>
                  </label>
                  <div className="col-sm-8">
                    <Form.Control
                      className={
                        errors.orgAddress1
                          ? "form-control border-danger"
                          : "form-control"
                      }
                      id="orgAddress1"
                      type="text"
                      name="orgAddress1"
                      {...register("orgAddress1", { required: true })}
                    />
                    {errors.orgAddress1 && <Error />}
                  </div>
                </div>
                <div className="mb-3 row position-relative">
                  <label className="col-sm-4 fw-bold align-self-center">
                    City:<span className="text-danger">*</span>
                  </label>
                  <div className="col-sm-8">
                    <Form.Control
                      className={
                        errors.orgCity
                          ? "form-control border-danger"
                          : "form-control"
                      }
                      type="text"
                      name="orgCity"
                      {...register("orgCity", { required: true })}
                    />
                    {errors.orgCity && <Error />}
                  </div>
                </div>
                <div className="mb-3 row position-relative">
                  <label className="col-sm-4 fw-bold">
                    State/Province: <span className="text-danger">*</span>
                  </label>
                  {selectedCountryName === UNITED_STATES ? (
                    <div className="col-sm-8">
                      <Form.Select
                        className={
                          errors.orgState
                            ? "form-select border-danger"
                            : "form-select"
                        }
                        name="orgState"
                        defaultValue=""
                        {...register("orgState", { required: true })}
                      >
                        <option value="">--Select state--</option>
                        {stateListDropdownUSA}
                      </Form.Select>
                      {errors.orgState && <Error />}
                    </div>
                  ) : selectedCountryName === INDIA ? (
                    <div className="col-sm-8">
                      <Form.Select
                        className={
                          errors.orgState
                            ? "form-select border-danger"
                            : "form-select"
                        }
                        name="state"
                        defaultValue=""
                        {...register("orgState", { required: true })}
                      >
                        <option value="">--Select state--</option>
                        {stateListDropdownIndia}
                      </Form.Select>
                      {errors.orgState && <Error />}
                    </div>
                  ) : (
                    <div className="col-sm-8">
                      <Form.Control
                        className={
                          errors.orgState
                            ? "form-control border-danger"
                            : "form-control"
                        }
                        type="text"
                        name="orgState"
                        defaultValue=""
                        {...register("orgState", { required: true })}
                      />
                      {errors.orgState && <Error />}
                    </div>
                  )}
                </div>
                <div className="mb-3 row position-relative">
                  <label className="col-sm-4 fw-bold align-self-center">
                    Country:<span className="text-danger">*</span>
                  </label>
                  <div className="col-sm-8">
                    <Form.Select
                      className={
                        errors.orgCountry
                          ? "form-select border-danger"
                          : "form-select"
                      }
                      name="orgCountry"
                      {...register("orgCountry", { required: true })}
                      aria-label="Default select example"
                      data-live-search="true"
                      defaultValue=""
                      onChange={handleCountryChange}
                    >
                      {countryListDropdown}
                    </Form.Select>
                    {errors.orgCountry && <Error />}
                  </div>
                </div>
                <div className="mb-3 row position-relative">
                  <label className="col-sm-4 fw-bold align-self-center">
                    Postal or Zip Code:<span className="text-danger">*</span>
                  </label>
                  <div className="col-sm-8">
                    <Form.Control
                      type="text"
                      className={
                        errors.orgZip
                          ? "form-control border-danger"
                          : "form-control"
                      }
                      name="orgZip"
                      {...register("orgZip", { required: true })}
                    />
                    {errors.orgZip && <Error />}
                  </div>
                </div>
                <div className="mb-3 row position-relative">
                  <label className="col-sm-4 fw-bold align-self-center">
                    PO Box:
                  </label>
                  <div className="col-sm-8">
                    <Form.Control
                      className="form-control"
                      id="postOfficeBox"
                      name="postOfficeBox"
                      type="text"
                      {...register("postOfficeBox")}
                    />
                  </div>
                </div>
                <div className="mb-3 row position-relative">
                  <label className="col-sm-4 fw-bold align-self-center">
                    D-U-N-S Number:
                  </label>
                  <div className="col-sm-8">
                    <Form.Control
                      className="form-control"
                      id="orgDUNS"
                      name="orgDUNS"
                      type="text"
                      {...register("orgDUNS")}
                    />
                  </div>
                </div>
                <div className="mb-3 row position-relative">
                  <label className="col-sm-4 fw-bold align-self-center">
                    Assumed Name (DBA):
                  </label>
                  <div className="col-sm-8">
                    <Form.Control
                      className="form-control"
                      id="orgAssumedName"
                      name="orgAssumedName"
                      type="text"
                      {...register("orgAssumedName")}
                    />
                  </div>
                </div>
                <h3 className="subheading border-top pt-3 mt-4">
                  Technical Contact Information
                </h3>
                <p>
                  The Technical contact will receive the certificate and
                  generally be the individual to install the certificate on the
                  web server. They will also receive renewal notices when the
                  certificate nears expiration.
                </p>
                <div className="mb-3 row position-relative">
                  <label className="col-sm-4 fw-bold align-self-center">
                    Title:<span className="text-danger">*</span>
                  </label>
                  <div className="col-sm-8">
                    <Form.Control
                      className={
                        errors.techTitle
                          ? "form-control border-danger"
                          : "form-control"
                      }
                      name="techTitle"
                      length="50"
                      type="text"
                      {...register("techTitle", { required: true })}
                    />
                    {errors.techTitle && <Error />}
                  </div>
                </div>
                <div className="mb-3 row position-relative">
                  <label className="col-sm-4 fw-bold align-self-center">
                    First Name: <span className="text-danger">*</span>
                  </label>
                  <div className="col-sm-8">
                    <Form.Control
                      className={
                        errors.techFirstName
                          ? "form-control border-danger"
                          : "form-control"
                      }
                      name="techFirstName"
                      length="50"
                      type="text"
                      {...register("techFirstName", { required: true })}
                    />
                    {errors.techFirstName && <Error />}
                  </div>
                </div>
                <div className="mb-3 row position-relative">
                  <label className="col-sm-4 fw-bold align-self-center">
                    Last Name: <span className="text-danger">*</span>
                  </label>
                  <div className="col-sm-8">
                    <Form.Control
                      className={
                        errors.techLastName
                          ? "form-control border-danger"
                          : "form-control"
                      }
                      name="techLastName"
                      length="50"
                      type="text"
                      {...register("techLastName", { required: true })}
                    />
                    {errors.techLastName && <Error />}
                  </div>
                </div>
                <div className="mb-3 row position-relative">
                  <label className="col-sm-4 fw-bold align-self-center">
                    Email Address: <span className="text-danger">*</span>
                  </label>
                  <div className="col-sm-8">
                    <Form.Control
                      className={
                        errors.techEmail
                          ? "form-control border-danger"
                          : "form-control"
                      }
                      name="techEmail"
                      length="100"
                      {...register("techEmail", {
                        required: true,
                        pattern: {
                          value: EMAIL_REGEX,
                        },
                      })}
                    />
                    {errors.techEmail ? (
                      <>
                        {errors.techEmail.type === "required" && <Error />}
                        {errors.techEmail.type === "pattern" && (
                          <ErrorValidEmail />
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="mb-3 row position-relative">
                  <label className="col-sm-4 fw-bold align-self-center">
                    Phone Number: <span className="text-danger">*</span>
                  </label>
                  <div className="col-sm-8">
                    <Form.Control
                      className={
                        errors.techPhone
                          ? "form-control border-danger"
                          : "form-control"
                      }
                      name="techPhone"
                      length="100"
                      type="number"
                      {...register("techPhone", { required: true })}
                    />
                    {errors.techPhone && <Error />}
                  </div>
                </div>
                <div className="mb-3 row position-relative">
                  <label className="col-sm-4 fw-bold align-self-center">
                    Fax Number:
                  </label>
                  <div className="col-sm-8">
                    <Form.Control
                      className="form-control"
                      id="orgFax"
                      name="orgFax"
                      type="text"
                      {...register("orgFax")}
                    />
                  </div>
                </div>
                <div id="csrsection">
                  <div className="mb-3 row position-relative">
                    <div className="col-sm-4">
                      <label className="fw-bold align-self-center">CSR:</label>
                    </div>
                    <div className="col-sm-8 d-flex">
                      <div className="form-check custom-control-inline ml-3">
                        <input
                          id="rbtmanualcsr"
                          type="radio"
                          name="GCSR"
                          value="ManualCSR"
                          className="form-check-input"
                          defaultChecked
                        />
                        <label
                          className="form-check-label"
                          htmlFor="rbtmanualcsr"
                        >
                          Manually Enter My CSR{" "}
                        </label>
                      </div>
                      <div className="form-check custom-control-inline ms-3">
                        <input
                          id="rbtgeneratedcsr"
                          type="radio"
                          name="GCSR"
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="rbtgeneratedcsr"
                        >
                          Use My Browser to generate the CSR
                        </label>
                      </div>
                    </div>
                  </div>
                  <div id="manuallycsr">
                    <div className="mb-3 row position-relative">
                      <label className="col-sm-4 fw-bold align-self-center">
                        <span className="text-danger"></span>
                      </label>
                      <div className="col-sm-8">
                        <Form.Control
                          as="textarea"
                          className={
                            errors.csr
                              ? "form-control border-danger"
                              : "form-control"
                          }
                          id="csr"
                          length="100"
                          name="csr"
                          placeholder="Paste CSR here"
                          rows="4"
                          {...register("csr", { required: true })}
                        />
                        <small className="form-text text-muted">
                          To remain secure, certificates must use keys which are
                          at least 3072 bits in length.{" "}
                        </small>
                        {errors.csr ? (
                          <>
                            {errors.csr.type === "required" && <Error />}
                            {errors.csr.type === "pattern" && <ErrorCsr />}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-3 " id="certEnroll_advancedOptions"></div>
                <div className="mb-3 row">
                  <label className="col-sm-4 fw-bold">
                    Subscriber Agreement:<span className="text-danger">*</span>
                  </label>
                  <div className="col-sm-8">
                    <p>
                      Please carefully read the following agreement and mark the
                      checkbox below.
                    </p>
                    <div className="agreement border overflow-auto p-3">
                      <div className="certiagreement ">
                        COMODO CODE SIGNING CERTIFICATE SUBSCRIBER AGREEMENT
                        <br />
                        <br />
                        IMPORTANT—PLEASE READ THIS CODE SIGNING CERTIFICATE
                        SUBSCRIBER AGREEMENT CAREFULLY BEFORE APPLYING FOR,
                        ACCEPTING, OR USING A COMODO CERTIFICATE. BY USING,
                        APPLYING FOR, OR ACCEPTING A COMODO CERTIFICATE OR BY
                        CLICKING ON "I AGREE", YOU ACKNOWLEDGE THAT YOU HAVE
                        READ THIS AGREEMENT, THAT YOU UNDERSTAND IT, AND THAT
                        YOU AGREE TO IT. IF YOU DO NOT ACCEPT THIS AGREEMENT, DO
                        NOT APPLY FOR, ACCEPT, OR USE A COMODO CERTIFICATE AND
                        DO NOT CLICK "I AGREE".
                        <br />
                        <br />
                        This agreement is between you ("Subscriber") and Comodo
                        CA Limited ("Comodo"), a United Kingdom company. The
                        agreement governs your application for and use of an SSL
                        Certificate issued from Comodo. You and Comodo agree as
                        follows:
                        <br />
                        <br />
                        1. Subscription Service.
                        <br />
                        <br />
                        1.1. Request. Subscriber shall submit a certificate
                        request in a form specified by Comodo for each ordered
                        certificate ("Certificate Request"). Forms for
                        Certificate Request are available on the Comodo website
                        and may be completed electronically. This agreement
                        applies to multiple future Certificate request and any
                        resulting Certificates, regardless of when the
                        Certificate is requested or issued.
                        <br />
                        <br />
                        1.2. Issuance. Upon Comodo's acceptance of the
                        Certificate Request, Comodo shall attempt to validate
                        the information provided in the request form in
                        accordance with the Comodo CPS and, for EV Code Signing
                        Certificates, the EV Guidelines. If Comodo chooses to
                        accept the certificate request and can validate
                        Subscriber to Comodo's satisfaction, Comodo shall issue
                        the ordered Certificate(s) to Subscriber. Comodo may
                        reject any certificate request and refuse to issue any
                        ordered Certificate in its sole discretion.
                        <br />
                        <br />
                        1.3. License. After issuance, Comodo grants Subscriber a
                        revocable, non-exclusive, non-transferable license to
                        use the issued Certificates to sign software code. All
                        rights not expressly granted herein to Subscriber are
                        reserved to Comodo.
                        <br />
                        <br />
                        1.4. Fee. Subscriber shall pay all fees applicable to
                        the ordered Certificate prior to Comodo validating and
                        issuing the Certificate. Fees for ordered Certificates
                        are presented to Subscriber during the application
                        process. All fees are non-refundable, except that the
                        Certificate's seller will refund a payment if, before 20
                        business days after the Certificate's issuance, the
                        Subscriber has 1) not used the Certificate and 2) made a
                        written request to Comodo for the Certificate's
                        revocation.
                        <br />
                        <br />
                        1.5. Subscriber Obligations. Subscriber shall:
                        <br />
                        <br />
                        (i) use the Certificates only for the purposes listed in
                        the Comodo CPS;
                        <br />
                        <br />
                        (ii) only install an issued Certificate on one machine
                        at a time, and only use an issued Certificate for
                        authorized business of the Subscriber;
                        <br />
                        <br />
                        (iii) be responsible for any computer hardware,
                        telecommunications hardware, and software necessary to
                        use the Certificate;
                        <br />
                        <br />
                        (iv) obtain and maintain any authorization or license
                        necessary to use the Certificate;
                        <br />
                        <br />
                        (v) bind each Relying Party to Comodo's Relying Party
                        Agreement;
                        <br />
                        <br />
                        (vi) keep Confidential Information confidential and
                        uncompromised, and immediately inform Comodo and request
                        revocation of any affected Certificates if Subscriber
                        reasonably believes that Confidential Information has
                        been disclosed or compromised;
                        <br />
                        <br />
                        (vii) ensure that all information provided to Comodo is
                        complete and accurate and does not include any
                        information that would be unlawful, contrary to public
                        interest, or otherwise likely to damage the business or
                        reputation of Comodo if used in any way;
                        <br />
                        <br />
                        (viii) for Code Signing Certificates, To inform Comodo
                        if: 1) It is discovered (by whatever means) that code
                        that has been signed is suspect; 2) Information in a
                        certificate is or becomes invalid; or 3) The Subscriber
                        discovers or suspects that a copy of its private key, or
                        key-activation data, is no longer under its sole
                        control.
                        <br />
                        <br />
                        (ix) immediately cease using a Certificate and
                        associated Private Key 1) if the Private Key is
                        compromised or 2) after the Certificate is expired or
                        revoked,
                        <br />
                        <br />
                        (x) immediately notify Comodo of 1) any a breach of this
                        agreement or 2) any information provided to Comodo
                        changes, ceases to be accurate, or becomes inconsistent
                        with the warranties made by Subscriber herein, and
                        <br />
                        <br />
                        (xi) comply with all applicable local and international
                        laws when receiving or using a Certificate, including
                        all export laws. Subscriber shall not export or
                        re-export, either directly or indirectly, any
                        Certificate to a country or entity under United Kingdom
                        or United States restrictions. SUBSCRIBER ASSUMES ALL
                        LIABILITY FOR ITS VIOLATION OF EXPORT LAWS.
                        <br />
                        <br />
                        1.6. Restrictions. Subscriber shall not:
                        <br />
                        <br />
                        (i) impersonate or misrepresent Subscriber's affiliation
                        with any entity,
                        <br />
                        <br />
                        (ii) modify, license, create a derivative work of, or
                        transfer any Certificate (except as required to use the
                        Certificate) or Private Key;
                        <br />
                        <br />
                        (iii) install or use an issued Certificate until after
                        Subscriber has reviewed and verified the Certificate
                        data's accuracy;
                        <br />
                        <br />
                        (iv) upload or distribute any files or software that may
                        damage the operation of another's computer,
                        <br />
                        <br />
                        (v) use the Services with any on-line control equipment
                        in hazardous environments requiring fail-safe
                        performance where the failure of the Services could lead
                        directly to death, personal injury or severe physical or
                        environmental damage.
                        <br />
                        <br />
                        (vi) use the Services to 1) engage in conduct that is
                        offensive, abusive, contrary to public morality,
                        indecent, defamatory, obscene, or menacing, 2) breach
                        the confidence of a third party, 3) infringe on the
                        intellectual property rights of a third party, 4) cause
                        Comodo or a third party distress, annoyance, denial of
                        any service, disruption or inconvenience, 5) send or
                        receive unsolicited bulk correspondence or 6) create a
                        Private Key that is substantially similar to a Comodo or
                        third party's Private Key,
                        <br />
                        <br />
                        (vii) make representations regarding the Service to any
                        third party except as agreed to in writing by Comodo.
                        <br />
                        <br />
                        2. Warranties and Representations.
                        <br />
                        <br />
                        2.1. Subscriber warrants that:
                        <br />
                        <br />
                        (i) If has full power and authority to enter into this
                        agreement and perform its obligations hereunder;
                        <br />
                        <br />
                        (ii) the information provided in the Certificate request
                        is truthful, accurate and not misleading;
                        <br />
                        <br />
                        (iii) no Certificate information you provided (including
                        your e-mail address) infringes the intellectual property
                        rights of any third parties;
                        <br />
                        <br />
                        (iv) the Certificate Application information you
                        provided (including your email address) has not been and
                        will not be used for any unlawful purpose;
                        <br />
                        <br />
                        (v) you have been (since the time of its creation) and
                        will remain the only person possessing your private key
                        and no unauthorized person has had or will have access
                        to your private key;
                        <br />
                        <br />
                        (vi) you have been (since the time of its creation) and
                        will remain the only person possessing any challenge
                        phrase, PIN, software, or hardware mechanism protecting
                        your private key and no unauthorized person has had or
                        will have access to the same;
                        <br />
                        <br />
                        (vii) you will use your Certificate exclusively for
                        authorized and legal purposes consistent with this
                        Subscriber Agreement;
                        <br />
                        <br />
                        (viii) you will use your Certificate as an end-user
                        Subscriber and not as a Certification Authority issuing
                        Certificates, certification revocation lists, or
                        otherwise;
                        <br />
                        <br />
                        (ix) each digital signature created using your private
                        key is your digital signature, and the Certificate has
                        been accepted and is operational (not expired or
                        revoked) at the time the digital signature is created;
                        <br />
                        <br />
                        (x) you manifest assent to this Subscriber Agreement as
                        a condition of obtaining a Certificate; and
                        <br />
                        <br />
                        (xi) you will not monitor, interfere with, or reverse
                        engineer the technical implementation of the Comodo PKI,
                        except with the prior written approval from Comodo, and
                        shall not otherwise intentionally compromise the
                        security of the Comodo PKI.
                        <br />
                        <br />
                        2.2 For Code Signing Certificates, Subscriber warrants
                        that:
                        <br />
                        <br />
                        (i) the individual accepting the agreement is expressly
                        authorized by Subscriber to sign the agreement for
                        Subscriber.
                        <br />
                        <br />
                        (ii) it will not knowingly use the Certificate to sign
                        software that contains suspect code;
                        <br />
                        <br />
                        3. Revocation. Comodo may revoke a Certificate if Comodo
                        believes that:
                        <br />
                        <br />
                        (i) Subscriber requested revocation of the Certificate;
                        <br />
                        <br />
                        (ii) Subscriber did not authorize the Certificate and
                        has not retroactively granted authorization;
                        <br />
                        <br />
                        (iii) Subscriber breached this Agreement;
                        <br />
                        <br />
                        (iv) Confidential Information related to the Certificate
                        has been disclosed or compromised;
                        <br />
                        <br />
                        (v) the Certificate has been 1) misused, 2) used
                        contrary to law, rule, or regulation or 3) used,
                        directly or indirectly, for illegal or fraudulent
                        purposes;
                        <br />
                        <br />
                        (vi) information in the Certificate is inaccurate or
                        misleading,
                        <br />
                        <br />
                        (vii) Subscriber loses exclusive control over a domain
                        name listed in the Certificate;
                        <br />
                        <br />
                        (viii) the Certificate was not issued or used in
                        accordance with Comodo's CPS or industry standards, or,
                        for EV Code Signing Certificates, the EV Guidelines;
                        <br />
                        <br />
                        (ix) Comodo 1) ceased operations or 2) is no longer
                        allowed to issue the Certificate, and no other
                        certificate authority has agreed to provide revocation
                        support for the Certificate;
                        <br />
                        <br />
                        (x) Subscriber is added as a denied party or prohibited
                        person to a blacklist, or is operating from a prohibited
                        destination under the laws of Comodo's jurisdiction of
                        operation;
                        <br />
                        <br />
                        (xi) the Certificate was issued to publishers of
                        malicious software;
                        <br />
                        <br />
                        (xii) the Certificate may have been used to digitally
                        sign hostile code, including spyware or other malicious
                        software;
                        <br />
                        <br />
                        (xiii) the CPS authorizes revocation of the Certificate;
                        or
                        <br />
                        <br />
                        (xiv) the Certificate, if not revoked, will compromise
                        the trust status of Comodo.
                        <br />
                        <br />
                        After revoking the Certificate, Comodo may, in its sole
                        discretion, reissue the Certificate to Subscriber or
                        terminate the agreement.
                        <br />
                        <br />
                        4. Intellectual Property Rights.
                        <br />
                        <br />
                        4.1. Comodo IP Rights. Comodo retains, and Subscriber
                        shall not obtain or claim, all title, interest, and
                        ownership rights in:
                        <br />
                        <br />
                        (i) the services and products provided by Comodo,
                        including issued Certificates,
                        <br />
                        <br />
                        (ii) all copies or derivative works of the Comodo's
                        services and products, regardless of who produced,
                        requested, or suggested the copy or derivative work,
                        <br />
                        <br />
                        (iii) documentation, software, and other supporting
                        materials provided by Comodo, and
                        <br />
                        <br />
                        (iv) Comodo's copyrights, patent rights, trademarks,
                        trade secret rights and other proprietary rights.
                        <br />
                        <br />
                        4.2. Trademarks. Subscriber shall not use a Comodo
                        trademark without Comodo's written consent. Comodo
                        consents to use of trademarks in connection with
                        provided TrustLogos.
                        <br />
                        <br />
                        5. Indemnification.
                        <br />
                        <br />
                        5.1. Indemnification. Subscriber shall indemnify Comodo
                        and its affiliates and their respective directors,
                        officers, employees, and agents (each an "Indemnified
                        Person") against all liabilities, losses, expenses, or
                        costs (including reasonable attorney's fees)
                        (collectively "Losses") that, directly or indirectly,
                        are based on Subscriber's breach of this agreement,
                        information provided by Subscriber, or Subscriber's or
                        its customers' infringement on the rights of a third
                        party.
                        <br />
                        <br />
                        5.2. Indemnification Procedure. Comodo shall notify
                        Subscriber promptly of any demand for indemnification.
                        However, Comodo's failure to notify will not relieve
                        Subscriber from its indemnification obligations except
                        to the extent that the failure to provide timely notice
                        materially prejudices Subscriber. Subscriber may assume
                        the defense of any action, suit, or proceeding giving
                        rise to an indemnification obligation unless assuming
                        the defense would result in potential conflicting
                        interests as determined by the Indemnified Person in
                        good faith. Subscriber may not settle any claim, action,
                        suit or proceeding related to this agreement unless the
                        settlement also includes an unconditional release of all
                        Indemnified Persons from liability.
                        <br />
                        <br />
                        5.3. Additional Liability. The indemnification
                        obligations of Subscriber are not Comodo's sole remedy
                        for Subscriber's breach and are in addition to any other
                        remedies Comodo may have against Subscriber under this
                        agreement. Subscriber's indemnification obligations
                        survive the termination of this agreement.
                        <br />
                        <br />
                        6. Term and Termination.
                        <br />
                        <br />
                        6.1. Term. Unless otherwise terminated as allowed
                        herein, this agreement is effective upon Subscriber's
                        acceptance and lasts for as long as a Certificate issued
                        under the agreement is valid.
                        <br />
                        <br />
                        6.2. Termination. Either party may terminate the
                        agreement with 20 business days notice for convenience.
                        Comodo may terminate this agreement immediately without
                        notice if
                        <br />
                        <br />
                        (i) Subscriber materially breaches this agreement,
                        <br />
                        <br />
                        (ii) if Comodo revokes a Certificate as allowed herein,
                        <br />
                        <br />
                        (iii) if Comodo rejects Subscriber's Certificate
                        application,
                        <br />
                        <br />
                        (iv) Comodo cannot satisfactorily validate Subscriber in
                        accordance with section 1.2, or
                        <br />
                        <br />
                        (v) if industry standards change in a way that affects
                        the validity of the Certificates ordered by Subscriber.
                        <br />
                        <br />
                        6.3. Events Upon Termination. After termination, Comodo
                        may revoke any other Certificate's issued to Subscriber
                        without further notice. Subscriber shall pay any amounts
                        still owed for the Certificates. Comodo is not obligated
                        to refund any payment made by Subscriber upon
                        termination of this Agreement.
                        <br />
                        <br />
                        7. Disclaimers and Limitation of Liability.
                        <br />
                        <br />
                        7.1. Relying Party Warranties. Subscriber acknowledges
                        that the Relying Party Warranty is only for the benefit
                        of Relying Parties. Subscriber does not have rights
                        under the warranty, including any right to enforce the
                        terms of the warranty or make a claim under the
                        warranty.
                        <br />
                        <br />
                        7.2. Exclusion of Warranties. THE SERVICES ARE PROVIDED
                        "AS IS" AND "AS AVAILABLE". COMODO EXPRESSLY DISCLAIMS
                        ALL IMPLIED AND EXPRESS WARRANTIES IN THE SERVICES. THIS
                        DISCLAIMER INCLUDES ALL WARRANTIES OF MERCHANTABILITY,
                        FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT
                        AND IS EFFECTIVE TO THE MAXIMUM EXTENT ALLOWED BY LAW.
                        COMODO DOES NOT GUARANTEE THAT 1) THE SERVICES WILL MEET
                        SUBSCRIBER'S REQUIREMENTS OR EXPECTATIONS OR 2) THAT
                        ACCESS TO THE SERVICES WILL BE UNINTERRUPTED, TIMELY,
                        SECURE, OR ERROR-FREE.
                        <br />
                        <br />
                        7.3. Limitation on Liability. SUBJECT TO SECTION 7.4,
                        THE TOTAL LIABILITY OF COMODO AND ITS AFFILIATES, AND
                        EACH OF THEIR OFFICERS, DIRECTORS, PARTNERS, EMPLOYEES,
                        AND CONTRACTORS, RESULTING FROM OR CONNECTED TO THIS
                        AGREEMENT IS LIMITED TO THE AMOUNT PAID BY SUBSCRIBER
                        FOR THE SERVICES GIVING RISE TO THE LIABILITY.
                        SUBSCRIBER WAIVES ALL LIABILITY FOR ANY SPECIAL,
                        INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES. THIS
                        WAIVER INCLUDES ALL DAMAGES FOR LOST PROFITS, REVENUE,
                        USE, OR DATA AND APPLIES EVEN IF COMODO IS AWARE OF THE
                        POSSIBILITY OF SUCH DAMAGES. These limitations apply to
                        the maximum extent permitted by law regardless of 1) the
                        reason for or nature of the liability, including tort
                        claims, 2) the number of any claims, 3) the extent or
                        nature of the damages, and 4) whether any other
                        provisions of this agreement have been breached or
                        proven ineffective.
                        <br />
                        <br />
                        7.4. Exception. Nothing in this agreement excludes or
                        limits the liability of either party for death or
                        personal injury resulting from the negligence of that
                        party or for any statements made fraudulently by either
                        party.
                        <br />
                        <br />
                        8. Remedy.
                        <br />
                        <br />
                        8.1. Injunctive Relief. Subscriber acknowledges that its
                        breach of this agreement will result in irreparable harm
                        to Comodo that cannot adequately be redressed by
                        compensatory damages. Accordingly, in addition to any
                        other legal remedies which may be available, Comodo may
                        seek and obtain an injunctive order against a breach or
                        threatened breach of the agreement by Subscriber.
                        <br />
                        <br />
                        8.2. Limitation on Actions. Except for actions and
                        claims related to a party's indemnification and
                        confidentiality obligations, all claims and actions
                        arising from this agreement must be brought within one
                        (1) year from the date when the cause of action
                        occurred.
                        <br />
                        <br />
                        8.3. Remedy. Subscriber's sole remedy for a defect in
                        the Services is to have Comodo use reasonable efforts to
                        correct the defect. Comodo is not obligated to correct a
                        defect if (i) the Service was misused, damaged, or
                        modified, (ii) Subscriber did not immediately report the
                        defect to Comodo, or (iii) Subscriber breached any
                        provision of this agreement.
                        <br />
                        <br />
                        9. Confidentiality. Except as allowed herein, a party
                        ("Receiving Party") shall not use or disclose any
                        Confidential Information provided by the other party
                        (the "Disclosing Party") other than for the purpose of
                        performing its obligations under this agreement. The
                        Receiving Party shall take reasonable measures to
                        prevent unauthorized disclosure and shall ensure that
                        any person receiving Confidential Information complies
                        with the restrictions in this section. The Receiving
                        Party may disclose Confidential Information if the
                        information:
                        <br />
                        <br />
                        (i) is already possessed by the Receiving Party before
                        receipt from the Disclosing Party;
                        <br />
                        <br />
                        (ii) is or becomes public domain without fault of the
                        Receiving Party;
                        <br />
                        <br />
                        (iii) is received by the Receiving Party from a third
                        party who is not under an obligation of confidentiality
                        or a restriction on the use and disclosure of the
                        information,
                        <br />
                        <br />
                        (iv) is disclosed in response to the requirements of a
                        law, governmental order, regulation, or legal process
                        and the Receiving Party first gives prior notice to the
                        Disclosing Party of the requirement to disclose the
                        information, or
                        <br />
                        <br />
                        (v) is disclosed under operation of law to the public
                        without a duty of confidentiality.
                        <br />
                        <br />
                        A party asserting one of the exceptions to Confidential
                        Information above shall prove the assertion using
                        verifiable documentary evidence. The restrictions
                        contained in this section apply for the duration of the
                        agreement plus five years after its termination.
                        <br />
                        <br />
                        10. Privacy.
                        <br />
                        <br />
                        (i) Comodo shall follow the privacy policy posted on its
                        website when receiving and using information from the
                        Subscriber. Comodo may amend the privacy policy at any
                        time by posting the amended privacy policy on its
                        website. Subject to Section 10(ii), Comodo shall use
                        reasonable efforts in protecting Subscriber's
                        information. Subscriber acknowledges that risks remain
                        that are beyond Comodo's reasonable control and waives
                        all liability of Comodo for these risks.
                        <br />
                        <br />
                        (ii) Subscriber consents to 1) Comodo disclosing
                        Subscriber's information publicly by embedding the
                        information in issued Certificates and 2) Comodo
                        disclosing and transferring Subscriber's information to
                        third parties located outside of the European Union as
                        necessary to validate and issue Certificates.
                        <br />
                        <br />
                        (iii) Subscriber may opt-out of having information used
                        for purposes not directly related to the Services by
                        emailing a clear notice to optout@comodo.com. By
                        clicking "I AGREE", Subscriber affirmatively consents to
                        receiving Comodo's and its affiliates marketing
                        material.
                        <br />
                        <br />
                        11. Miscellaneous.
                        <br />
                        <br />
                        11.1. Force Majeure and Internet Frailties. Other than
                        for payment obligations by Subscriber, neither party
                        will be liable for a delay or failure to perform an
                        obligation to the extent that the delay or failure is
                        caused by an occurrence beyond the party's reasonable
                        control. Each party acknowledges that the operation of
                        the Internet is beyond the other party's reasonable
                        control, and neither party will be liable for a delay or
                        failure caused by an interruption or failure of
                        telecommunication or digital transmission links,
                        Internet slow-downs or failures, or other such
                        transmission failure.
                        <br />
                        <br />
                        11.2. Notices. Subscriber shall send all notices to
                        Comodo by first class mail in English writing, with
                        return receipt requested, to Comodo CA Limited, 26
                        Office Village, 3<sup>rd</sup> Floor, Exchange Quay,
                        Trafford Road, Salford, Manchester M5 3EQ, United
                        Kingdom. Comodo shall send all notices to Subscriber's
                        contact information listed on its Certificate
                        application. Comodo may send notices by mail, email, or
                        facsimile.
                        <br />
                        <br />
                        11.3. Entire Agreement. This agreement and all documents
                        referred to herein constitute the entire agreement
                        between the parties with respect to the subject matter,
                        superseding all other agreements that may exist. Section
                        headings are for reference and convenience only and are
                        not part of the interpretation of this agreement.
                        <br />
                        <br />
                        11.4. Amendments. Comodo may amend this agreement, the
                        CPS, the Relying Party Agreement, the Relying Party
                        Warranty, its website, and any documents listed in its
                        Repository at any time by posting the amendment or
                        amended document on its website. Subscriber shall
                        periodically review the website to be aware of any
                        changes. Subscriber may terminate the agreement if
                        Subscriber does not agree to the amendment. Subscriber's
                        continued use of the Services after an amendment is
                        posted constitutes Subscriber's acceptance of the
                        amendment.
                        <br />
                        <br />
                        11.5. Waiver. A party's failure to enforce a provision
                        of this agreement will not waive the party's right to
                        enforce the same provision later or right to enforce any
                        other provision of this agreement. To be effective, all
                        waivers must be both in writing and signed by the party
                        benefiting form the waived provision.
                        <br />
                        <br />
                        11.6. Assignment. Subscriber shall not assign any of its
                        rights or obligations under this agreement without the
                        prior written consent of Comodo. Any transfer without
                        consent will be void. Comodo may assign its rights and
                        obligations without Subscriber's consent.
                        <br />
                        <br />
                        11.7. Governing Law and Venue. The laws of England and
                        Wales govern the interpretation, construction, and
                        enforcement of this agreement and all proceedings
                        arising out of it, including tort claims, without regard
                        to any conflicts of law principles. All proceedings or
                        legal action arising from this agreement must be
                        commenced in the courts of England and Wales. Both
                        parties agree to the exclusive venue and jurisdiction of
                        these courts.
                        <br />
                        <br />
                        11.8. Severability. Any provision determined invalid or
                        unenforceable by rule of law will be reformed to the
                        minimum extent necessary to make the provision valid and
                        enforceable. If reformation is not possible, the
                        provision will be deemed omitted and the balance of the
                        agreement will remain valid and enforceable.
                        <br />
                        <br />
                        11.9. Survival. All provisions of the agreement related
                        to confidentiality, proprietary rights, indemnification,
                        and limitations of liability survive the termination of
                        the agreement.
                        <br />
                        <br />
                        11.10. Rights of Third Parties. The Certificate
                        Beneficiaries are express third party beneficiaries of
                        Subscriber's obligations and warranties in this
                        agreement.
                        <br />
                        <br />
                        12. Definitions.
                        <br />
                        <br />
                        12.1. "Certificate" means a digitally signed electronic
                        data file issued by Comodo to a person or entity seeking
                        to conduct business over a communications network which
                        contains the identity of the person authorized to use
                        the Digital Signature, a copy of their Public Key, a
                        serial number, a time period during which the data file
                        may be used, and a Digital Signature issued by Comodo.
                        <br />
                        <br />
                        12.2. "CPS" refers to the documents explaining Comodo's
                        polices and procedures when operating its PKI
                        infrastructure.
                        <br />
                        <br />
                        12.3. "Code Signing Certificate" means a Certificate
                        informing customers that they can trust the software
                        download by verifying code integrity and company
                        legitimacy.
                        <br />
                        <br />
                        12.4. "Confidential Information" means all material,
                        data, systems, technical operations, and other
                        information concerning Comodo's business operations that
                        is not known to the general public, including all
                        information about the Certificate issuance services
                        (such as all Private Keys, personal identification
                        numbers and passwords).
                        <br />
                        <br />
                        12.5. "Certificate Beneficiaries" means the Subject
                        named in the Certificate, any third parties with whom
                        Comodo has entered into a contract for inclusion of its
                        root certificate, and all Relying Parties that actually
                        rely on such Certificate during the period when it is
                        valid.
                        <br />
                        <br />
                        12.6. "Digital Signature" means an encrypted electronic
                        data file which is attached to or logically associated
                        with other electronic data and which identifies and is
                        uniquely linked to the signatory of the electronic data,
                        is created using the signatory's Private Key and is
                        linked in a way so as to make any subsequent changes to
                        the electronic data detectable.
                        <br />
                        <br />
                        12.7. "EV Code Signing Certificate" means a Code Signing
                        Certificate issued in compliance with the EV Guidelines.
                        <br />
                        <br />
                        12.8. "Private Key" means a confidential encrypted
                        electronic data file designed to interface with a Public
                        Key using the same encryption algorithm and which may be
                        used to create Digital Signatures, and decrypt files or
                        messages which have been encrypted with a Public Key.
                        <br />
                        <br />
                        12.9. "Public Key" means a publicly available encrypted
                        electronic data file designed to interface with a
                        Private Key using the same encryption algorithm and
                        which may be used to verify Digital Signatures and
                        encrypt files or messages.
                        <br />
                        <br />
                        12.10. "Relying Party" means an entity that acts in
                        reliance on a Certificate or a Digital Signature.
                        <br />
                        <br />
                        12.11. "Relying Party Agreement" refers to an agreement
                        located on the Comodo Repository that governs a Relying
                        Party's use of the Certificate when transacting business
                        with the Subscriber's website.
                        <br />
                        <br />
                        12.12. "Relying Party Warranty" refers to a warranty
                        offered by Comodo to a Relying Party under the terms and
                        conditions found in the Comodo Relying Party Agreement
                        in connection with the Relying Party's use of a
                        Certificate.
                        <br />
                        <br />
                        12.13. "Repository" means a publicly available
                        collection of information and databases relating to
                        Comodo's Certificate practices and which is available at
                        http://www.comodo.com/repository.
                        <br />
                        <br />
                        12.14. "Services" means the Certificates ordered
                        hereunder along with any related TrustLogos, software,
                        and documentation.
                        <br />
                        <br />
                        12.15. "TrustLogo" means a logo provided by Comodo for
                        use on a Subscriber's site in connection with an issued
                        Certificate.
                        <br />
                        <br />
                        ACCEPTANCE
                        <br />
                        <br />
                        BY CLICKING "I AGREE", YOU ACKNOWLEDGE THAT YOU HAVE
                        READ AND UNDERSTAND THIS AGREEMENT AND THAT YOU AGREE TO
                        COMPLY WITH ITS TERMS. DO NOT CLICK "I AGREE" IF YOU DO
                        NOT ACCEPT THIS AGREEMENT.
                      </div>
                    </div>
                    <p className="infomsg">
                      By marking the checkbox below you confirm that you have
                      carefully read, understood, and accept the terms and
                      conditions of the Subscriber Agreement from the issuing
                      certificate authority.
                    </p>
                    <div className="custom-control custom-checkbox mt-3">
                      <input
                        className="form-check-input"
                        name="terms"
                        id="terms"
                        type="checkbox"
                        {...register("terms", { required: true })}
                      />
                      <label
                        className="custom-control-label ms-2"
                        htmlFor="terms"
                      >
                        I have read and accept the terms and conditions of the
                        subscriber agreement.
                      </label>
                      {errors.terms && (
                        <p className="border-danger text-danger errorText fst-italic">
                          Please Check Terms and Conditions
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mb-3 row mb-0">
                  <label className="col-sm-4 fw-bold align-self-center text-align"></label>
                  <div className="col-sm-8">
                    <input
                      className="button button-green"
                      type="submit"
                      name="continue"
                      id="continue"
                      value="Continue"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default CodeSigning;
