import React from "react";

const Footer = () => {
  return (
    <footer className="footer py-3">
      <p className="mb-0 text-center text-white">sandbox.thesslstore.com</p>
    </footer>
  );
};

export default Footer;
