import React, { useContext, useEffect, useState } from "react";
import Header from "Shared/Header";
import Navbar from "Shared/Navbar";
import secticologo from "assets/images/sectigo-logo.svg";
import infoIcon from "assets/images/infoicon.png";
import { Button, Form } from "react-bootstrap";
import Error, { ErrorValidEmail } from "Shared/Error";
import {
  ACCEPTANCE,
  AGREEMENT_STATEMENT,
  AGREE_STATEMENT_CHECKBOX,
  AGREE_STATEMENT_INFOICON,
  AGREE_STATEMENT_LAST,
  AUTOMATED_DV_AUTH_OPTIONS_INFO,
  AUTOMATED_DV_AUTH_OPTIONS_TITLE,
  CERTIFICATE_SUBSCRIBER_AGREEMENT,
  CONFIDENTIALITY,
  DEFINITIONS,
  DISCLAIMERS_AND_LIMITATION,
  EMAIL,
  EMAIL_REGEX,
  IMPORTANT,
  INDEMNIFICATION,
  INTELL_ACTUAL_PROPERTY_RIGHTS,
  MISCELLANEOUS,
  PAYMENT,
  PRIVACY_AND_DATA_PROTECTION,
  PRODUCT_INFO,
  PRODUCT_INFO_TITLE,
  REVOCATION,
  SAME_AS_ADMIN,
  SELECT_CERTIFICATE_APPROVER_EMAIL_INFO,
  SELECT_CERTIFICATE_APPROVER_EMAIL_TITLE,
  SITE_ADMIN_CONTACT_INFO,
  SITE_ADMIN_CONTACT_INFO_TITLE,
  SUBSCRIBER_AGREEMENT_INFO,
  SUBSCRIBER_RESPONSIBILITIES,
  SUBSCRIPTION_SERVICE,
  TECHNICAL_CONTACT_INFO,
  TECHNICAL_CONTACT_INFO_TITLE,
  TERMINATION,
  WARRANTIES_AND_REPRESENATATION,
} from "Utils/Constants";
import {
  certificate41point6,
  confidentialityData81,
  confidentialityData81Points,
  confidentialityData82,
  definitionsData,
  disclaimersData121,
  disclaimersData122,
  disclaimersData122Points,
  disclaimersData123,
  disclaimersData123Points,
  disclaimersData124,
  indemnificationData10,
  installCertificatePoints2,
  intellectualData71,
  intellectualData71Points,
  intellectualData72,
  intellectualData73,
  miscellaneousData13Point12,
  miscellaneousData13Point12Poins,
  miscellaneousData13Point13to16,
  miscellaneousData13Point5points,
  miscellaneousData13Point9,
  miscellaneousData13Point9Points,
  miscellaneousData13Points10to11,
  miscellaneousData13Points1to4,
  miscellaneousData13Points5,
  miscellaneousData13Points6to8,
  paymentData51,
  paymentData51Point1,
  paymentData52,
  privacyData91,
  privacyData92,
  privacyData92Points,
  privacyData93,
  privateKeyPoints8,
  responsiblePoints5,
  revocationData41,
  revocationData41Points11,
  revocationData41Points12to16,
  revocationData41Points1to5,
  revocationData41Points6,
  revocationData41Points7to10,
  revocationData42,
  sectigo41Point11,
  service32point5,
  subscriberResponsibilitiesData31,
  subscriberResponsibilitiesData32,
  subscriberResponsibilitiesData32Points1to4,
  subscriberResponsibilitiesData32Points6,
  subscriberResponsibilitiesDataPoints1,
  subscriberResponsibilitiesDataPoints2,
  subscriberResponsibilitiesDataPoints32point5,
  subscriberResponsibilitiesDataPoints3and4,
  subscriberResponsibilitiesDataPoints5,
  subscriberResponsibilitiesDataPoints6and7,
  subscriberResponsibilitiesDataPoints8,
  subscriberResponsibilitiesDataPoints9to11,
  subscriptionServiceData,
  subscriptionServiceDataPoints,
  terminationData61,
  terminationData62,
  terminationData63,
  termsAndTerminationPoints62,
  warrantiesAndRepresentationData,
} from "Utils/Data";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Footer from "Shared/Footer";
import { ProductDetailsContext } from "Context/ProductDetailContext";
import Loader from "Shared/Loader";
import ShortUniqueId from "short-unique-id";

const VerifyDetails = () => {
  const {
    step,
    isOv,
    setStepVerifyDetail,
    values,
    productName,
    dcvValues,
    csrInfo,
    validity,
    retriveEmailValues,
    generateCertificate,
    loading,
    setLoading,
    subscriberAgreement,
    dcvObj,
    sansValues,
    getRetriveEmailsDropdownData,
    retriveEmailObj,
    retriveEmailValuesObj,
    setRetiveEmailValuesObj,
    inviteKeyObj,
    error,
    setError,
    wildcardProduct,
    getHttpAuthFileDetails,
    getCnameDetails,
    httpAuthDetails,
    cnameAuthDetails,
  } = useContext(ProductDetailsContext);

  let navigate = useNavigate();
  const { state } = useLocation();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const [valueDVC, setValueDVC] = useState(EMAIL);
  const adminFieldTitleValueChanged = watch("adminTitle");
  const adminFieldFirstNameValueChanged = watch("adminFirstName");
  const adminFieldLastNameValueChanged = watch("adminLastName");
  const adminFieldEmailValueChanged = watch("adminEmail");
  const adminFieldPhoneValueChanged = watch("adminPhone");
  const [uuidvalue, setUuidValue] = useState("");
  const [domainName, setDomainName] = useState("");
  const [data, setData] = useState([]);
  const [primaryDomainRetriveEmailObject, setPrimaryDomainRetriveEmailObject] =
    useState([]);
  const [primaryDomainApproverMail, setPrimaryDomainApproverMail] =
    useState("");

  // const showSubscriberAgreementBox =
  //   subscriberAgreement &&
  //   subscriberAgreement.map((sa) => {
  //     return sa && sa.agreement.split("\n");
  //   });
  const uid = new ShortUniqueId({ length: 20 });
  function setUniqueValue() {
    const test = uid();
    setUuidValue(test);
  }
  const handleDvcDropDown = (e) => {
    const valueChecked = e.target.value;
    setValueDVC(valueChecked);
    values.dcvMethod = valueChecked;
    if (valueChecked !== "email") {
      setUniqueValue();
    }
  };
  const copyData = (e) => {
    if (e.target.checked) {
      setValue("techTitle", adminFieldTitleValueChanged, {
        shouldValidate: true,
      });
      setValue("techFirstName", adminFieldFirstNameValueChanged, {
        shouldValidate: true,
      });
      setValue("techLastName", adminFieldLastNameValueChanged, {
        shouldValidate: true,
      });
      setValue("techEmail", adminFieldEmailValueChanged, {
        shouldValidate: true,
      });
      setValue("techPhone", adminFieldPhoneValueChanged, {
        shouldValidate: true,
      });
    } else {
      setValue("techTitle", "");
      setValue("techFirstName", "");
      setValue("techLastName", "");
      setValue("techEmail", "");
      setValue("techPhone", "");
    }
  };
  const handlePrimaryDomainApproverEmail = (e) => {
    setPrimaryDomainApproverMail(e.target.value);
  };
  const handleRetriveEmailChange = (val, index) => {
    var test = data;
    test[index] = val.target.value;
    const result = test.filter(
      (val) =>
        val !== "" && val !== undefined && val !== "empty" && val !== null
    );
    result.unshift(primaryDomainApproverMail);
    if (valueDVC === EMAIL) {
      values.approverEmail = result.toString();
    } else {
      values.approverEmail = "";
    }
  };
  const wildcardDcvDropdown = dcvValues.map((type, id) => {
    return type.dcvAvailable
      .filter((dc) => dc !== "https")
      .map((dc, id) => {
        return (
          <option key={id} value={dc}>
            {dc}
          </option>
        );
      });
  });
  const dcvTypeDropdown = dcvValues.map((item) => {
    return item.dcvAvailable.map((dc, id) => {
      return (
        <option key={id} value={dc}>
          {dc}
        </option>
      );
    });
  });
  const dcvMailTypeDropdown = retriveEmailValues.map((item) => {
    return item.dcvEmailAddresses.map((re, id) => {
      return (
        <option
          key={id}
          value={re}
          onChange={(val) => {
            handleRetriveEmailChange(val, id);
          }}
        >
          {re}
        </option>
      );
    });
  });
  const primaryDomainRetriveEmailsDropdown =
    primaryDomainRetriveEmailObject.map((item) => {
      return item.dcvEmailAddresses.map((re, id) => {
        return (
          <option
            key={id}
            value={re}
            onChange={(val) => {
              handleRetriveEmailChange(val, id);
            }}
          >
            {re}
          </option>
        );
      });
    });

  const retriveEmailsArray = [
    "admin@",
    "administrator@",
    "hostmaster@",
    "postmaster@",
    "webmaster@",
  ];
  const onBackClick = () => {
    values.csr = "";
    setLoading("");
    setError("");
    navigate(`/entercsr/${state.id}`);
  };
  const onSubmit = async (data) => {
    if (!isOv) {
      setLoading("true");
    }
    values.sans = sansValues.toString();
    values.primaryDomainName = csrInfo.commonName;
    values.InviteKey = state.id;
    values.appRepLoginPassword = uuidvalue;
    values.adminTitle = data.adminTitle;
    values.adminFirstName = data.adminFirstName;
    values.adminLastName = data.adminLastName;
    values.adminEmail = data.adminEmail;
    values.adminPhone = data.adminPhone;
    values.techTitle = data.techTitle;
    values.techFirstName = data.techFirstName;
    values.techLastName = data.techLastName;
    values.techEmail = data.techEmail;
    values.techPhone = data.techPhone;
    values.organizationalUnitName = csrInfo.organizationalUnitName;
    values.stateOrProvinceName = csrInfo.stateOrProvinceName;
    values.localityName = csrInfo.localityName;
    values.streetAddress3 = csrInfo.streetAddress3;
    values.postalCode = csrInfo.postalCode;
    values.foreignOrderNumber = state.id;
    if (values.dcvMethod === "https") {
      values.httpsFileAuthDVIndicator = true;
      values.approverEmail = "";
    }
    if (values.dcvMethod === "cname") {
      values.cnameAuthDVIndicator = true;
      values.approverEmail = "";
    }
    if (valueDVC !== "email") {
      values.csrUniqueValue = uuidvalue;
      dcvObj.csrUniqueValue = uuidvalue;
    }
    if (valueDVC === "email") {
      if (sansValues.length < 1 && state.sansWildcardValues === "") {
        // values.approverEmail = retriveEmailValues[0].dcvEmailAddresses[0];
        values.approverEmail = primaryDomainApproverMail;
      }
      // else {
      //   values.approverEmail = ;
      // }
    }
    if (!isOv) {
      if (values.dcvMethod === "https") {
        await getHttpAuthFileDetails();
      }
      if (values.dcvMethod === "cname") {
        await getCnameDetails();
      }
      const testResult = await generateCertificate();
      if (testResult.data.errorCode === "0") {
        setError("");
        navigate("/thankyou");
        setLoading("");
      }
    } else {
      setError("");
      navigate("/organisationdetail");
    }
  };
  useEffect(() => {
    setUniqueValue();
    setStepVerifyDetail();
    setPrimaryDomainApproverMail(retriveEmailValues[0].dcvEmailAddresses[0]);
    setPrimaryDomainRetriveEmailObject(retriveEmailValues);
  }, []);
  useEffect(() => {
    if (error !== "" && error !== null) {
      setLoading("");
      alert(error);
      navigate("/error");
    }
  }, [error]);

  return (
    <div>
      <Header />
      <main>
        {!isOv && loading === "true" ? <Loader /> : ""}
        <section className="py-3 border-top">
          <div className="container">
            <div className="d-flex flex-sm-row flex-column justify-content-between align-items-center">
              <h1 className="mb-md-0 mb-2 text-md-left text-center fs-3">
                {productName}
              </h1>
              <span className="brand-logo">
                <img alt="Certificate Generation" src={secticologo} />
              </span>
            </div>
          </div>
        </section>
        <section className="content bg-light py-5">
          <div className="container">
            <Navbar step={step} isOv={isOv} />
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="bg-white p-4 p-md-5">
                <div className="mb-4 row g-3">
                  <label className="col-lg-6 fw-bold">
                    The CSR you generated is designed to work with the following
                    URL:
                  </label>
                  <div className="col-lg-3 fw-bold">{csrInfo.commonName}</div>
                </div>
                <hr />
                <div className="mb-4 row align-items-center">
                  <div className="col-sm-12">
                    <h4>CSR Information</h4>
                    <p className="m-0">
                      Please verify that your information collected from your
                      CSR is correct.
                    </p>
                  </div>
                </div>
                <div className="mb-4 row">
                  <label className="col-sm-4 fw-bold">
                    Common Name (domain):
                  </label>
                  <div className="col-sm-8">
                    <p className="m-0">{csrInfo.commonName}</p>
                  </div>
                </div>
                <div className="mb-4 row">
                  <label className="col-sm-4 fw-bold">
                    Organization Name (business entity):
                  </label>
                  <div className="col-sm-8">
                    <p className="m-0">{csrInfo.organizationName}</p>
                  </div>
                </div>
                <div className="mb-4 row">
                  <label className="col-sm-4 fw-bold">
                    Organizational Unit Name (department):
                  </label>
                  <div className="col-sm-8">
                    <p className="m-0">{csrInfo.organizationalUnitName}</p>
                  </div>
                </div>
                <div className="mb-4 row">
                  <label className="col-sm-4 fw-bold">Locality (city):</label>
                  <div className="col-sm-8">
                    <p className="m-0">{csrInfo.localityName}</p>
                  </div>
                </div>
                <div className="mb-4 row">
                  <label className="col-sm-4 fw-bold">State/Province:</label>
                  <div className="col-sm-8">
                    <p className="m-0">{csrInfo.stateOrProvinceName}</p>
                  </div>
                </div>
                <div className="mb-4 row">
                  <label className="col-sm-4 fw-bold">Country:</label>
                  <div className="col-sm-8">
                    <p className="m-0">{csrInfo.countryName}</p>
                  </div>
                </div>
                <hr />
                <div className="mb-4 row">
                  <div className="col-sm-12">
                    <h4>{PRODUCT_INFO_TITLE}</h4>
                    <p className="m-0">{PRODUCT_INFO}</p>
                  </div>
                </div>
                <div className="mb-4 row">
                  <label className="col-sm-4 fw-bold">Product Name:</label>
                  <div className="col-sm-8">
                    <p className="m-0"> {productName}</p>
                  </div>
                </div>
                <div className="mb-4 row">
                  <label htmlFor="inputPassword" className="col-sm-4 fw-bold">
                    Validity:
                  </label>
                  <div className="col-sm-8">
                    <p className="m-0">{validity}</p>
                  </div>
                </div>
                <div className="mb-4 row">
                  <label className="col-sm-4 fw-bold">Order Type:</label>
                  <div className="col-sm-8">
                    <p className="m-0">
                      {values.new === true ? "New" : "Renew"}
                    </p>
                  </div>
                </div>
                <hr />
                <div className="mb-4 row">
                  <div className="col-sm-12">
                    <h4>{AUTOMATED_DV_AUTH_OPTIONS_TITLE}</h4>
                    <p className="m-0">{AUTOMATED_DV_AUTH_OPTIONS_INFO}</p>
                  </div>
                </div>
                <div className="mb-4 row g-3">
                  <label className="col-sm-4 fw-bold">
                    Domain Control Validation (DCV):
                  </label>
                  <div className="col-sm-8">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      defaultValue={valueDVC}
                      onChange={handleDvcDropDown}
                    >
                      {wildcardProduct ? wildcardDcvDropdown : dcvTypeDropdown}
                    </select>
                  </div>
                  {valueDVC === "email" && <hr />}
                  {valueDVC === "email" ? (
                    <div className="mb-4 row bg-light p-3">
                      <div className="col-sm-12">
                        <h4>{SELECT_CERTIFICATE_APPROVER_EMAIL_TITLE}</h4>
                        <p>{SELECT_CERTIFICATE_APPROVER_EMAIL_INFO}</p>

                        <div className="row align-items-center g-3 mt-1">
                          <label className="col-lg-3 fw-bold">
                            {csrInfo.commonName}
                          </label>
                          <div className="col-lg-6 col-md-6">
                            <Form.Select
                              className="form-select"
                              aria-label="Default select example"
                              onChange={handlePrimaryDomainApproverEmail}
                              // defaultValue={`admin@${csrInfo.commonName}`}
                              // defaultValue={
                              //   retriveEmailValues[0].dcvEmailAddresses[0]
                              // }
                            >
                              {primaryDomainRetriveEmailsDropdown}
                            </Form.Select>
                          </div>
                          <div className="col-lg-3 col-md-6">
                            <Button
                              className="btn btn-success"
                              name="Retrieve All Emails"
                              id="retrieveallemails"
                              onClick={() => {
                                setLoading("true");
                                setTimeout(() => {
                                  setLoading("false");
                                }, 800);
                              }}
                            >
                              Retrieve All Emails
                            </Button>
                          </div>
                        </div>
                        {sansValues &&
                          sansValues.map((san, id) => {
                            const test = san.includes("*.");
                            let newSan;
                            if (test === true) {
                              newSan = san.slice(2);
                            }
                            return (
                              <div
                                className="row align-items-center g-3 mt-1"
                                key={id}
                              >
                                <div className="col-lg-3 col-md-6">{san}</div>
                                <div className="col-lg-6 col-md-6">
                                  <Form.Select
                                    className={
                                      errors.approverEmail
                                        ? "form-select border-danger"
                                        : "form-select"
                                    }
                                    aria-label="Default select example"
                                    {...register("approverEmail", {
                                      required: true,
                                    })}
                                    onChange={(val) => {
                                      handleRetriveEmailChange(val, id);
                                    }}
                                    defaultValue="3"
                                  >
                                    <option value="">
                                      ---Select Approver Email---
                                    </option>
                                    {san === domainName
                                      ? dcvMailTypeDropdown
                                      : retriveEmailsArray.map((item, id) => {
                                          return (
                                            <option
                                              key={id}
                                              value={
                                                san !== domainName
                                                  ? test
                                                    ? item + newSan
                                                    : item + san
                                                  : dcvMailTypeDropdown
                                              }
                                              onChange={(val) => {
                                                handleRetriveEmailChange(
                                                  val,
                                                  id
                                                );
                                              }}
                                            >
                                              {san === domainName
                                                ? dcvMailTypeDropdown
                                                : test
                                                ? item + newSan
                                                : item + san}
                                            </option>
                                          );
                                        })}
                                  </Form.Select>
                                  {errors.approverEmail && <Error />}
                                </div>
                                <div className="col-lg-3 col-md-6">
                                  <Button
                                    className="btn btn-success"
                                    name="Retrieve All Emails"
                                    onClick={async () => {
                                      setDomainName(san);
                                      retriveEmailValuesObj.domainName = san;
                                      setLoading("true");
                                      const result =
                                        await getRetriveEmailsDropdownData();
                                      if (result.data.errorCode === "0") {
                                        setLoading("false");
                                      }
                                    }}
                                  >
                                    Retrieve All Emails
                                  </Button>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  ) : (
                    <div className="row mt-3">
                      <label className="col-sm-4 fw-bold">
                        20 Character Unique Variable:
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control ms-1 inputUniqueCode"
                          defaultValue={uuidvalue}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <hr />
                <div className="mb-4 row mt-4 g-4">
                  <div className="col-lg-6 col-md-12 col-sm-12 pe-lg-5">
                    <h4>{SITE_ADMIN_CONTACT_INFO_TITLE}</h4>
                    <p className="min-height">{SITE_ADMIN_CONTACT_INFO}</p>
                    <Form.Group className="form-group row mt-4 pt-3 mb-4 position-relative">
                      <Form.Label className="col-sm-4 fw-bold">
                        Title:<span className="text-danger">*</span>
                      </Form.Label>
                      <div className="col-sm-8">
                        <Form.Control
                          className={
                            errors.adminTitle
                              ? "form-control border-danger"
                              : "form-control"
                          }
                          name="adminTitle"
                          length="50"
                          type="text"
                          {...register("adminTitle", { required: true })}
                          defaultValue=""
                        />
                        {errors.adminTitle && <Error />}
                      </div>
                    </Form.Group>
                    <div className="form-group row mb-4 position-relative">
                      <label className="col-sm-4 fw-bold">
                        First Name: <span className="text-danger">*</span>
                      </label>
                      <div className="col-sm-8">
                        <Form.Control
                          className={
                            errors.adminFirstName
                              ? "form-control border-danger"
                              : "form-control"
                          }
                          name="adminFirstName"
                          length="50"
                          type="text"
                          {...register("adminFirstName", { required: true })}
                        />
                        {errors.adminFirstName && <Error />}
                      </div>
                    </div>
                    <div className="form-group row mb-4 position-relative">
                      <label className="col-sm-4 fw-bold">
                        Last Name: <span className="text-danger">*</span>
                      </label>
                      <div className="col-sm-8">
                        <Form.Control
                          className={
                            errors.adminLastName
                              ? "form-control border-danger"
                              : "form-control"
                          }
                          name="adminLastName"
                          length="50"
                          type="text"
                          {...register("adminLastName", { required: true })}
                        />
                        {errors.adminLastName && <Error />}
                      </div>
                    </div>
                    <div className="form-group row mb-4 position-relative">
                      <label className="col-sm-4 fw-bold">
                        Email Address: <span className="text-danger">*</span>
                      </label>
                      <div className="col-sm-8">
                        <Form.Control
                          className={
                            errors.adminEmail
                              ? "form-control border-danger"
                              : "form-control"
                          }
                          name="adminEmail"
                          length="100"
                          {...register("adminEmail", {
                            required: true,
                            pattern: {
                              value: EMAIL_REGEX,
                              message: "Please enter a valid email",
                            },
                          })}
                        />
                        {errors.adminEmail ? (
                          <>
                            {errors.adminEmail.type === "required" && <Error />}
                            {errors.adminEmail.type === "pattern" && (
                              <ErrorValidEmail />
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="form-group row mb-4 position-relative">
                      <label className="col-sm-4 fw-bold">
                        Phone Number: <span className="text-danger">*</span>
                      </label>
                      <div className="col-sm-8">
                        <Form.Control
                          className={
                            errors.adminPhone
                              ? "form-control border-danger"
                              : "form-control"
                          }
                          name="adminPhone"
                          length="100"
                          type="number"
                          {...register("adminPhone", { required: true })}
                        />
                        {errors.adminPhone && <Error />}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 ps-lg-5">
                    <h4>{TECHNICAL_CONTACT_INFO_TITLE}</h4>
                    <p>{TECHNICAL_CONTACT_INFO}</p>
                    <div className="form-check check-info">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="sameAsAdmin"
                        onChange={copyData}
                      />
                      <label className="form-check-label" htmlFor="sameAsAdmin">
                        {SAME_AS_ADMIN}
                      </label>
                    </div>
                    <div className="form-group row mt-3 mb-4 position-relative">
                      <label className="col-sm-4 fw-bold">
                        Title:<span className="text-danger">*</span>
                      </label>
                      <div className="col-sm-8">
                        <Form.Control
                          className={
                            errors.techTitle
                              ? "form-control border-danger"
                              : "form-control"
                          }
                          name="techTitle"
                          length="50"
                          type="text"
                          {...register("techTitle", { required: true })}
                        />
                        {errors.techTitle && <Error />}
                      </div>
                    </div>
                    <div className="form-group row mb-4 position-relative">
                      <label className="col-sm-4 fw-bold">
                        First Name: <span className="text-danger">*</span>
                      </label>
                      <div className="col-sm-8">
                        <Form.Control
                          className={
                            errors.techFirstName
                              ? "form-control border-danger"
                              : "form-control"
                          }
                          name="techFirstName"
                          length="50"
                          type="text"
                          {...register("techFirstName", { required: true })}
                        />
                        {errors.techFirstName && <Error />}
                      </div>
                    </div>
                    <div className="form-group row mb-4 position-relative">
                      <label className="col-sm-4 fw-bold">
                        Last Name: <span className="text-danger">*</span>
                      </label>
                      <div className="col-sm-8">
                        <Form.Control
                          className={
                            errors.techLastName
                              ? "form-control border-danger"
                              : "form-control"
                          }
                          name="techLastName"
                          length="50"
                          type="text"
                          {...register("techLastName", { required: true })}
                        />
                        {errors.techLastName && <Error />}
                      </div>
                    </div>
                    <div className="form-group row mb-4 position-relative">
                      <label className="col-sm-4 fw-bold">
                        Email Address: <span className="text-danger">*</span>
                      </label>
                      <div className="col-sm-8">
                        <Form.Control
                          className={
                            errors.techEmail
                              ? "form-control border-danger"
                              : "form-control"
                          }
                          name="techEmail"
                          length="100"
                          {...register("techEmail", {
                            required: true,
                            pattern: {
                              value: EMAIL_REGEX,
                            },
                          })}
                        />
                        {errors.techEmail ? (
                          <>
                            {errors.techEmail.type === "required" && <Error />}
                            {errors.techEmail.type === "pattern" && (
                              <ErrorValidEmail />
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="form-group row mb-4 position-relative">
                      <label className="col-sm-4 fw-bold">
                        Phone Number: <span className="text-danger">*</span>
                      </label>
                      <div className="col-sm-8">
                        <Form.Control
                          className={
                            errors.techPhone
                              ? "form-control border-danger"
                              : "form-control"
                          }
                          name="techPhone"
                          length="100"
                          type="number"
                          {...register("techPhone", { required: true })}
                        />
                        {errors.techPhone && <Error />}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="mb-4 row">
                  <label className="col-sm-4 fw-bold">
                    Subscriber Agreement: *
                  </label>
                  <div className="col-sm-8">
                    <p className="m-0">{SUBSCRIBER_AGREEMENT_INFO}</p>
                  </div>
                </div>
                <div className="mb-4 row">
                  <div className="col-sm-12">
                    <div className="agreement border overflow-auto p-3">
                      <div
                        id="ltrAgreement"
                        className="text-area-box formatagree"
                      >
                        <p>
                          <strong>{CERTIFICATE_SUBSCRIBER_AGREEMENT}</strong>
                        </p>
                        {/* <ul>
                          {showSubscriberAgreementBox.map((sa) => {
                            return sa.map((sp, index) => {
                              return <li key={index}>{sp}</li>;
                            });
                          })}
                        </ul> */}
                        <p>{IMPORTANT}</p>
                        <p>{AGREEMENT_STATEMENT}</p>
                        {subscriberAgreement.agreement}
                        <ul className="decimallist">
                          <li>
                            {DEFINITIONS}
                            <ul>
                              {definitionsData.map((data) => (
                                <li key={data.id}>{data.content}</li>
                              ))}
                            </ul>
                          </li>
                          <li>
                            {SUBSCRIPTION_SERVICE}
                            <ul>
                              {subscriptionServiceData.map((data) => (
                                <li key={data.id}>{data.content}</li>
                              ))}
                              <li>
                                <ul>
                                  {subscriptionServiceDataPoints.map((data) => (
                                    <li key={data.id}>{data.content}</li>
                                  ))}
                                </ul>
                              </li>
                            </ul>
                          </li>
                          <li>
                            {SUBSCRIBER_RESPONSIBILITIES}
                            <ul>
                              {subscriberResponsibilitiesData31.map((data) => (
                                <li key={data.id}>
                                  {data.content}
                                  <ul>
                                    {subscriberResponsibilitiesDataPoints1.map(
                                      (data) => (
                                        <li key={data.id}>{data.content}</li>
                                      )
                                    )}
                                    {subscriberResponsibilitiesDataPoints2.map(
                                      (data) => (
                                        <li key={data.id}>
                                          {data.content}
                                          <ul>
                                            {installCertificatePoints2.map(
                                              (data) => (
                                                <li key={data.id}>
                                                  {data.content}
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        </li>
                                      )
                                    )}
                                    {subscriberResponsibilitiesDataPoints3and4.map(
                                      (data) => (
                                        <li key={data.id}>{data.content}</li>
                                      )
                                    )}
                                    {subscriberResponsibilitiesDataPoints5.map(
                                      (data) => (
                                        <li key={data.id}>
                                          {data.content}
                                          <ul>
                                            {responsiblePoints5.map((data) => (
                                              <li key={data.id}>
                                                {data.content}
                                              </li>
                                            ))}
                                          </ul>
                                        </li>
                                      )
                                    )}
                                    {subscriberResponsibilitiesDataPoints6and7.map(
                                      (data) => (
                                        <li key={data.id}>{data.content}</li>
                                      )
                                    )}
                                    {subscriberResponsibilitiesDataPoints8.map(
                                      (data) => (
                                        <li key={data.id}>
                                          {data.content}
                                          <ul>
                                            {privateKeyPoints8.map((data) => (
                                              <li key={data.id}>
                                                {data.content}
                                              </li>
                                            ))}
                                          </ul>
                                        </li>
                                      )
                                    )}
                                    {subscriberResponsibilitiesDataPoints9to11.map(
                                      (data) => (
                                        <li key={data.id}>{data.content}</li>
                                      )
                                    )}
                                  </ul>
                                </li>
                              ))}
                              {subscriberResponsibilitiesData32.map((data) => (
                                <li key={data.id}>
                                  {data.content}
                                  <ul>
                                    {subscriberResponsibilitiesData32Points1to4.map(
                                      (data) => (
                                        <li key={data.id}>{data.content}</li>
                                      )
                                    )}
                                    {subscriberResponsibilitiesDataPoints32point5.map(
                                      (data) => (
                                        <li key={data.id}>
                                          {data.content}
                                          <ul>
                                            {service32point5.map((data) => (
                                              <li key={data.id}>
                                                {data.content}
                                              </li>
                                            ))}
                                          </ul>
                                        </li>
                                      )
                                    )}
                                    {subscriberResponsibilitiesData32Points6.map(
                                      (data) => (
                                        <li key={data.id}>{data.content}</li>
                                      )
                                    )}
                                  </ul>
                                </li>
                              ))}
                            </ul>
                          </li>
                          <li>
                            {REVOCATION}
                            <ul>
                              {revocationData41.map((data) => (
                                <li key={data.id}>
                                  {data.content}
                                  <ul>
                                    {revocationData41Points1to5.map((data) => (
                                      <li key={data.id}>{data.content}</li>
                                    ))}
                                    {revocationData41Points6.map((data) => (
                                      <li key={data.id}>
                                        {data.content}
                                        <ul>
                                          {certificate41point6.map((data) => (
                                            <li key={data.id}>
                                              {data.content}
                                            </li>
                                          ))}
                                        </ul>
                                      </li>
                                    ))}
                                    {revocationData41Points7to10.map((data) => (
                                      <li key={data.id}>{data.content}</li>
                                    ))}
                                    {revocationData41Points11.map((data) => (
                                      <li key={data.id}>
                                        {data.content}
                                        <ul>
                                          {sectigo41Point11.map((data) => (
                                            <li key={data.id}>
                                              {data.content}
                                            </li>
                                          ))}
                                        </ul>
                                      </li>
                                    ))}
                                    {revocationData41Points12to16.map(
                                      (data) => (
                                        <li key={data.id}>{data.content}</li>
                                      )
                                    )}
                                  </ul>
                                </li>
                              ))}
                              {revocationData42.map((data) => (
                                <li key={data.id}>{data.content}</li>
                              ))}
                            </ul>
                          </li>
                          <li>
                            {PAYMENT}
                            <ul>
                              {paymentData51.map((data) => (
                                <li key={data.id}>
                                  {data.content}
                                  <ul>
                                    {paymentData51Point1.map((data) => (
                                      <li key={data.id}>{data.content}</li>
                                    ))}
                                  </ul>
                                </li>
                              ))}
                              {paymentData52.map((data) => (
                                <li key={data.id}>{data.content}</li>
                              ))}
                            </ul>
                          </li>
                          <li>
                            {TERMINATION}
                            <ul>
                              {terminationData61.map((data) => (
                                <li key={data.id}>{data.content}</li>
                              ))}
                              {terminationData62.map((data) => (
                                <li key={data.id}>
                                  {data.content}
                                  <ul>
                                    {termsAndTerminationPoints62.map((data) => (
                                      <li key={data.id}>{data.content}</li>
                                    ))}
                                  </ul>
                                </li>
                              ))}
                              {terminationData63.map((data) => (
                                <li key={data.id}>{data.content}</li>
                              ))}
                            </ul>
                          </li>
                          <li>
                            {INTELL_ACTUAL_PROPERTY_RIGHTS}
                            <ul>
                              {intellectualData71.map((data) => (
                                <li key={data.id}>
                                  {data.content}
                                  <ul>
                                    {intellectualData71Points.map((data) => (
                                      <li key={data.id}>{data.content}</li>
                                    ))}
                                  </ul>
                                </li>
                              ))}
                              {intellectualData72.map((data) => (
                                <li key={data.id}>{data.content}</li>
                              ))}
                              {intellectualData73.map((data) => (
                                <li key={data.id}>{data.content}</li>
                              ))}
                            </ul>
                          </li>
                          <li>
                            {CONFIDENTIALITY}
                            <ul>
                              {confidentialityData81.map((data) => (
                                <li key={data.id}>
                                  {data.content}
                                  <ul>
                                    {confidentialityData81Points.map((data) => (
                                      <li key={data.id}>{data.content}</li>
                                    ))}
                                  </ul>
                                </li>
                              ))}
                              {confidentialityData82.map((data) => (
                                <li key={data.id}>{data.content}</li>
                              ))}
                            </ul>
                          </li>
                          <li>
                            {PRIVACY_AND_DATA_PROTECTION}
                            <ul>
                              {privacyData91.map((data) => (
                                <li key={data.id}>{data.content}</li>
                              ))}
                              {privacyData92.map((data) => (
                                <li key={data.id}>
                                  {data.content}
                                  <ul>
                                    {privacyData92Points.map((data) => (
                                      <li key={data.id}>{data.content}</li>
                                    ))}
                                  </ul>
                                </li>
                              ))}
                              {privacyData93.map((data) => (
                                <li key={data.id}>{data.content}</li>
                              ))}
                            </ul>
                          </li>
                          <li>
                            {INDEMNIFICATION}
                            <ul>
                              {indemnificationData10.map((data) => (
                                <li key={data.id}>{data.content}</li>
                              ))}
                            </ul>
                          </li>
                          <li>
                            {WARRANTIES_AND_REPRESENATATION}
                            <ul>
                              {warrantiesAndRepresentationData.map((data) => (
                                <li key={data.id}>{data.content}</li>
                              ))}
                            </ul>
                          </li>
                          <li>
                            {DISCLAIMERS_AND_LIMITATION}
                            <ul>
                              {disclaimersData121.map((data) => (
                                <li key={data.id}>{data.content}</li>
                              ))}
                              {disclaimersData122.map((data) => (
                                <li key={data.id}>
                                  {data.content}
                                  <ul>
                                    {disclaimersData122Points.map((data) => (
                                      <li key={data.id}>{data.content}</li>
                                    ))}
                                  </ul>
                                </li>
                              ))}
                              {disclaimersData123.map((data) => (
                                <li key={data.id}>
                                  {data.content}
                                  <ul>
                                    {disclaimersData123Points.map((data) => (
                                      <li key={data.id}>{data.content}</li>
                                    ))}
                                  </ul>
                                </li>
                              ))}
                              {disclaimersData124.map((data) => (
                                <li key={data.id}>{data.content}</li>
                              ))}
                            </ul>
                          </li>
                          <li>
                            {MISCELLANEOUS}
                            <ul>
                              {miscellaneousData13Points1to4.map((data) => (
                                <li key={data.id}>{data.content}</li>
                              ))}
                              {miscellaneousData13Points5.map((data) => (
                                <li key={data.id}>
                                  {data.content}
                                  <ul>
                                    {miscellaneousData13Point5points.map(
                                      (data) => (
                                        <li key={data.id}>{data.content}</li>
                                      )
                                    )}
                                  </ul>
                                </li>
                              ))}
                              {miscellaneousData13Points6to8.map((data) => (
                                <li key={data.id}>{data.content}</li>
                              ))}
                              {miscellaneousData13Point9.map((data) => (
                                <li key={data.id}>
                                  {data.content}
                                  <ul>
                                    {miscellaneousData13Point9Points.map(
                                      (data) => (
                                        <li key={data.id}>{data.content}</li>
                                      )
                                    )}
                                  </ul>
                                </li>
                              ))}
                              {miscellaneousData13Points10to11.map((data) => (
                                <li key={data.id}>{data.content}</li>
                              ))}
                              {miscellaneousData13Point12.map((data) => (
                                <li key={data.id}>
                                  {data.content}
                                  <ul>
                                    {miscellaneousData13Point12Poins.map(
                                      (data) => (
                                        <li key={data.id}>{data.content}</li>
                                      )
                                    )}
                                  </ul>
                                </li>
                              ))}
                              {miscellaneousData13Point13to16.map((data) => (
                                <li key={data.id}>{data.content}</li>
                              ))}
                            </ul>
                          </li>
                        </ul>
                        <p>
                          <strong>{ACCEPTANCE}</strong>
                        </p>
                        <p>{AGREE_STATEMENT_LAST}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-4 row">
                  <div className="sm-12">
                    <p className="alert-warning p-4 d-flex">
                      <img
                        alt="Info"
                        src={infoIcon}
                        width="24"
                        height="24"
                        className="me-2"
                      />
                      {AGREE_STATEMENT_INFOICON}
                    </p>
                  </div>
                </div>
                <div className="form-check check-info mb-5">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="terms"
                    id="termsCheckbox"
                    {...register("terms", { required: true })}
                  />
                  <label className="form-check-label" htmlFor="termsCheckbox">
                    {AGREE_STATEMENT_CHECKBOX}
                  </label>
                  {errors.terms && (
                    <p className="border-danger text-danger errorText fst-italic">
                      Please Check Terms and Conditions
                    </p>
                  )}
                </div>
                <div className="pt-4 mt-5 border-top d-flex justify-content-between">
                  <Button
                    className="btn btn-secondary btn-lg"
                    type="button"
                    onClick={onBackClick}
                    name="Back"
                    id="back"
                  >
                    Back
                  </Button>
                  <Button
                    className="btn btn-success btn-lg"
                    type="submit"
                    name="Continue"
                    id="continue"
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default VerifyDetails;
