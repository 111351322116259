import React, { useContext, useEffect, useState } from "react";
import bciconentercsr from "assets/images/bcicon-entercsr.svg";
import bciconverify from "assets/images/bcicon-verify.svg";
import bciconthanku from "assets/images/bcicon-thanku.svg";
import bciconorganization from "assets/images/bcicon-organization.svg";
import { ProductDetailsContext } from "Context/ProductDetailContext";

const Navbar = () => {
  const { step, isOv, isCodeSigning } = useContext(ProductDetailsContext);
  const [navStep, setNavStep] = useState(step);
  const ovStepClassActive = "breadcrumb-item w-25 active";
  const ovstepClass = "breadcrumb-item w-25";
  const stepClassActive = "breadcrumb-item active";
  const stepClass = "breadcrumb-item";
  const csStepClass = "breadcrumb-item w-50";
  const csStepClassActive = "breadcrumb-item w-50 active";

  let classNameDependsOnStep1Ov =
    navStep === 1 ? ovStepClassActive : ovstepClass;
  let classNameDependsOnStep1 = navStep === 1 ? stepClassActive : stepClass;
  let classNameDependsOnStep1Cs =
    navStep === 1 ? csStepClassActive : csStepClass;

  let classNameDependsOnCondtion2 =
    navStep === 2 ? ovStepClassActive : ovstepClass;
  let classNameDependsOnCondtionOV2 =
    navStep === 2 ? stepClassActive : stepClass;
  let classNameDependsOnCondtionCs2 =
    navStep === 2 ? csStepClassActive : csStepClass;
  let classNameDependsOnCondtion3 =
    navStep === 3 ? ovStepClassActive : ovstepClass;
  let classNameDependsOnCondtionOV3 =
    navStep === 3 ? stepClassActive : stepClass;

  let classNameDependsOnCondtion4 =
    navStep === 4 ? ovStepClassActive : ovstepClass;
  let classNameDependsOnCondtionOV4 =
    navStep === 4 ? stepClassActive : stepClass;

  let classNameDependsOnCondtionOVThankYou = isOv
    ? classNameDependsOnCondtion4
    : classNameDependsOnCondtionOV4;

  let classNameDependsOnCondtionOVThankYouOv = isOv
    ? classNameDependsOnCondtion3
    : classNameDependsOnCondtionOV3;

  let classNameDependsOnCondtionThankYouCs = isCodeSigning
    ? classNameDependsOnCondtionCs2
    : classNameDependsOnCondtionOV2;

  let classNameDependsOnCondition1Cs = isCodeSigning
    ? classNameDependsOnStep1Cs
    : classNameDependsOnStep1;

  useEffect(() => {
    setNavStep(step);
  }, [step]);

  return (
    <nav aria-label="breadcrumb">
      <ul className="breadcrumb mb-0 stepperClass">
        {!isCodeSigning && (
          <li
            className={
              isOv && !isCodeSigning
                ? classNameDependsOnStep1Ov
                : classNameDependsOnStep1
            }
            aria-current="page"
          >
            <i className="bc-icon">
              <img alt="Certificate Generation" src={bciconentercsr} />
            </i>
            Enter CSR
          </li>
        )}
        {!isCodeSigning && (
          <li
            className={
              isOv && !isCodeSigning
                ? classNameDependsOnCondtion2
                : classNameDependsOnCondtionOV2
            }
            aria-current="page"
          >
            <i className="bc-icon">
              <img alt="Verify URL" src={bciconverify} />
            </i>
            Verify URL
          </li>
        )}
        {isOv || isCodeSigning ? (
          <li
            className={
              isOv && !isCodeSigning
                ? classNameDependsOnCondtion3
                : isCodeSigning
                ? classNameDependsOnCondition1Cs
                : classNameDependsOnCondtionOV3
            }
            aria-current="page"
          >
            <i className="bc-icon">
              <img alt="Organization Info" src={bciconorganization} />
            </i>
            Organization Info
          </li>
        ) : (
          ""
        )}
        <li
          className={
            isOv && !isCodeSigning
              ? classNameDependsOnCondtionOVThankYou
              : isCodeSigning
              ? classNameDependsOnCondtionThankYouCs
              : classNameDependsOnCondtionOVThankYouOv
          }
          aria-current="page"
        >
          <i className="bc-icon">
            <img alt="Thank You" src={bciconthanku} />
          </i>
          Thank You
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
