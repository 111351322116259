import React, { useContext } from "react";
import logo from "assets/images/logo.svg";
import sectigoFinalLogo from "assets/images/SECTIGOStoreFinalLogo.png";
import { ProductDetailsContext } from "Context/ProductDetailContext";

const Header = () => {
  const { isCodeSigning } = useContext(ProductDetailsContext);
  return (
    <div className="header py-4">
      <div className="container">
        <div className="row g-4 align-items-center">
          <div className="col-sm-4 text-sm-start text-center logo">
            {isCodeSigning ? (
              <img alt="Certificate Generation" src={sectigoFinalLogo} />
            ) : (
              <img alt="Certificate Generation" src={logo} />
            )}
          </div>
          <div className="col-sm-8 text-sm-end text-center fs-4">
            <p className="mb-md-3 mb-2">
              {isCodeSigning ? "+1(888) 481.5388" : "8123456789"}
            </p>
            <p className="mb-0">support@sectigostore.com</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
