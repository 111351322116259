import React, { useContext } from "react";
import ordercertnotfound from "assets/images/ordercertnotfound.svg";
import { ProductDetailsContext } from "Context/ProductDetailContext";

const PageNotFound = () => {
  const { error } = useContext(ProductDetailsContext);

  return (
    <div className="errorpage">
      <div className="content">
        <section className="entry-content py-md-5 py-3">
          <div className="container">
            <div className="form-area bg-white p-md-5 p-3">
              <div className="row">
                <div className="col-sm-4">
                  <img
                    className="error-icon"
                    alt="Error"
                    src={ordercertnotfound}
                  />
                </div>
                <div className="col-sm-8 page-cnt align-self-center">
                  <h2>{error ? error : "NO CERTIFICATE DETAIL FOUND."} </h2>
                  There is a problem with the resource you are looking for, and
                  it cannot be displayed.
                </div>
              </div>
              <div>
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
              <div className="clear"></div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default PageNotFound;
