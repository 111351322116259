import React, { createContext, useEffect, useState } from "react";
import * as ProductDetailService from "Services/ProductDetailService";

const ProductDetailsContext = createContext();
const ProductDetailsContextProvider = ({ children }) => {
  const [productName, setProductName] = useState("");
  const [csrInfo, setCsrInfo] = useState({});
  const [jwtToken, setJwtToken] = useState("");
  const [multiDomainSansCount, setMultiDomainSansCount] = useState("");
  const [multiDomainSansCountWildcard, setMultiDomainSansCountWildcard] =
    useState("");
  const [productId, setProductId] = useState("");
  const [validity, setValidity] = useState(0);
  const [dcvValues, setDcvValues] = useState([]);
  const [retriveEmailValues, setRetriveEmailValues] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(null);
  const [orderDetails, setOrderDetails] = useState({});
  const [subscriberAgreement, setSubscriberAgreement] = useState([{}]);
  const [httpAuthDetails, setHttpAuthDetails] = useState({});
  const [cnameAuthDetails, setCnameAuthDetails] = useState({});
  const [sansValues, setSansValues] = useState("");
  const [step, setStep] = useState(1);
  const [isOv, setIsOv] = useState(false);
  const [enrollPin, setEnrollPin] = useState("");
  const [productType, setProductType] = useState([]);
  const [inviteKeyError, setInviteKeyError] = useState(false);
  const [domainName, setDomainName] = useState("");
  const [orderIdExisted, setOrderIdExisted] = useState("");
  const [tokenData, setTokenData] = useState([]);

  const productTypeLower =
    productType && productType.map((val) => val.toLowerCase());
  const isCodeSigning =
    productTypeLower && productTypeLower.includes("code signing");
  const IsEvFlag = productTypeLower && productTypeLower.includes("ev");
  const IsOvFlag = productTypeLower && productTypeLower.includes("ov");
  const wildcardProduct =
    (productTypeLower && productTypeLower.includes("wildcard")) ||
    productTypeLower.includes("san wildcard");
  const DVProduct = productTypeLower && productTypeLower.includes("dv");
  const formData = {
    new: true,
    csr: "",
    serverType: "2",
    sans: sansValues,
    dcvMethod: "email",
    dcvValue: "",
    approverEmail: "",
    primaryDomainName: "",
    adminTitle: "",
    adminFirstName: "",
    adminLastName: "",
    adminEmail: "",
    adminPhone: "",
    techTitle: "",
    techFirstName: "",
    techLastName: "",
    techEmail: "",
    techPhone: "",
    orgLegalName: "",
    orgAssumedName: "",
    orgDUNS: "",
    orgAddress1: "",
    orgAddress2: "",
    orgCity: "",
    orgState: "",
    orgZip: "",
    orgCountry: "",
    orgPhone: "",
    orgFax: "",
    productIDs: "",
    noOfServers: 1,
    maxSubjectCNs: 1,
    prioritiseCSRValues: true,
    organizationalUnitName: "",
    postOfficeBox: "",
    streetAddress3: "",
    localityName: "",
    stateOrProvinceName: "",
    postalCode: "",
    localityNameForEV: "",
    stateOrProvinceNameForEV: "",
    countryNameForEV: "",
    dateOfIncorporation: "",
    assumedNameForEV: "",
    contactEmailAddress: "info@thesslstore.com",
    foreignOrderNumber: "",
    checkFONIsUnique: true,
    httpsFileAuthDVIndicator: false,
    cnameAuthDVIndicator: false,
    appRepForename: "aaaa",
    appRepLoginPassword: "",
    signatureHash: "PREFER_SHA2",
    csrUniqueValue: "",
    previousOrderNumber: "",
    includeIndividual: "",
    offerType: 0,
    InviteKey: "",
    authFileName: "",
    authFileContent: "",
  };
  const [values, setValues] = useState(formData);
  const inviteKeyObj = {
    inviteKey: "",
  };
  const retriveEmailObj = {
    domainName: "",
  };
  const [retriveEmailValuesObj, setRetiveEmailValuesObj] =
    useState(retriveEmailObj);
  const valuesCsr = {
    csr: values ? values.csr : "",
    productID: !isCodeSigning ? productId : "",
  };
  const dcvObj = {
    csr: values ? values.csr : "",
    csrUniqueValue: values ? values.csrUniqueValue : "",
    productID: productId,
  };
  const setStepIndex = () => {
    !isCodeSigning && setStep(1);
  };
  const setStepVerifyDetail = () => {
    !isCodeSigning && setStep(2);
  };
  const setStepOrganizationDetail = () => {
    isOv && !isCodeSigning && setStep(3);
    isCodeSigning && setStep(1);
  };
  const setStepThankyou = () => {
    isOv && !isCodeSigning ? setStep(4) : setStep(3);
    isCodeSigning && setStep(2);
  };
  // const isCodeSigning = true;
  // console.log("isCodeSigning", isCodeSigning);
  // console.log("DVProduct", DVProduct);
  // console.log("productTypeLower", productTypeLower);
  // console.log("wildcardProduct", wildcardProduct);
  const getJWTByUid = async () => {
    try {
      const result = await ProductDetailService.getProductDetails(inviteKeyObj);
      // console.log("JWT", res.data);
      if (result.data.isValid === false) {
        if (!result.data.errors) {
          alert("Invalid Invite-Key");
          setError("Invalid Invite-Key");
          setInviteKeyError(true);
        }
        setInviteKeyError(true);
        alert(result.data.errors[0].message);
        setError(result.data.errors[0].message);
      } else {
        setTokenData([result.data]);
        //setOrderIdExisted(result.data.orderItemId);
        setInviteKeyError(false);
        setProductName(result.data.productName);
        setProductType(result.data.productTypes);
        values.productIDs = result.data.vendorProductCode;
        setJwtToken(result.data.jwtToken);
        setValidity(result.data.validity);
        setProductId(result.data.vendorProductCode);
        setMultiDomainSansCount(result.data.additionalDomains);
        setMultiDomainSansCountWildcard(result.data.additionalWildcardDomains);
        setEnrollPin(result.data.enrollmentPin);
      }
      return result;
    } catch (error) {
      setError(error);
      console.log("Error while generating token");
    }
  };
  const getProductDetailsByDecodingCsr = async () => {
    try {
      const result = await ProductDetailService.postCsrDecoded(
        valuesCsr,
        jwtToken
      );
      if (parseInt(result.status) === 200 && result.data.errorCode === "0") {
        setCsrInfo(result.data);
        setDomainName(result.data.commonName);
        retriveEmailValuesObj.domainName = result.data.commonName;
        if (error !== "" && !isCodeSigning) {
          setLoading("true");
          getRetriveEmailsDropdownData();
        }
      } else {
        setLoading("");
        setCsrInfo(result.data);
        // setError(result.data.errorMessage);
      }
      console.log("result.data", result.data);
      return result;
    } catch (error) {
      console.log("Error while decoding csr");
      console.log("error dd", error);
      setLoading("");
    }
  };
  const getDcvDropdownData = async () => {
    try {
      const result = await ProductDetailService.postDcvDropdownData(
        {},
        jwtToken
      );
      setDcvValues([result.data]);
      //console.log("dcv dropdown values", result.data);
      return result;
    } catch (error) {
      console.log("Error while fetching values");
    }
  };
  const getRetriveEmailsDropdownData = async () => {
    try {
      const result = await ProductDetailService.postRetrieveDropdownData(
        retriveEmailValuesObj,
        jwtToken
      );
      setRetriveEmailValues([result.data]);
      // getSubscriberAgreementDetails();
      if (result.data.errorCode === "0") {
        setLoading("false");
      }
      return result;
    } catch (error) {
      console.log("Error while fetching values");
    }
  };
  const getHttpAuthFileDetails = async () => {
    try {
      const result = await ProductDetailService.postDcvDropdownHttp(
        dcvObj,
        jwtToken
      );
      //console.log("Auth details", result.data);
      setHttpAuthDetails(result.data);
      values.authFileName = result.data.cnameAuthName;
      values.authFileContent = result.data.cnameAuthValue;
      // setLoading("false");
      // console.log("httpAuthDetails", httpAuthDetails);
      return result;
    } catch (error) {
      console.log("Error while fetching values");
    }
  };
  const getCnameDetails = async () => {
    try {
      const result = await ProductDetailService.postDcvDropdownCname(
        dcvObj,
        jwtToken
      );
      // console.log("Cname details", result.data);
      setCnameAuthDetails(result.data);
      values.authFileName = result.data.cnameAuthName;
      values.authFileContent = result.data.cnameAuthValue;
      return result;
    } catch (error) {
      console.log("Error while fetching values");
    }
  };
  const getSubscriberAgreementDetails = async () => {
    try {
      const result = await ProductDetailService.postsubscriberAgreement(
        {},
        jwtToken
      );
      setSubscriberAgreement([result.data]);
      //console.log("subscriber agreement", result.data);
      return result;
    } catch (error) {
      console.log("Error while fetching values");
    }
    // console.log("subscriber agreement obj", subscriberAgreement);
  };
  const generateCertificate = async () => {
    try {
      const result = await ProductDetailService.postSubmitOrder(
        values,
        jwtToken
      );
      if (parseInt(result.status) === 200 && result.data.errorCode === "0") {
        setOrderDetails(result.data);
        setLoading("false");
        console.log("order", result.data);
      } else {
        setLoading("");
        setError(result.data.errorMessage);
      }
      console.log("order details", orderDetails);
      return result;
    } catch (error) {
      console.log("Error while generating certificate");
      console.log("Error", error);
      // setError(error);
    }
  };
  const reIssueCertificate = async () => {
    try {
      const result = await ProductDetailService.postReissue(values, jwtToken);
      // console.log("reissue", result.data);
      return result;
    } catch (error) {
      console.log("Error while reissuing certificate");
    }
  };
  // console.log("productType", productType);
  useEffect(() => {
    console.log("IsEvFlag", IsEvFlag);
    console.log("IsOvFlag", IsOvFlag);
    if (IsEvFlag || IsOvFlag) {
      setIsOv(true);
    }
  }, [IsEvFlag, IsOvFlag]);
  useEffect(() => {
    setError(error);
  }, [error]);

  return (
    <ProductDetailsContext.Provider
      value={{
        jwtToken,
        productName,
        values,
        setValues,
        valuesCsr,
        getJWTByUid,
        getProductDetailsByDecodingCsr,
        getDcvDropdownData,
        dcvValues,
        dcvObj,
        csrInfo,
        setCsrInfo,
        validity,
        getRetriveEmailsDropdownData,
        getSubscriberAgreementDetails,
        retriveEmailValues,
        error,
        setError,
        getCnameDetails,
        getHttpAuthFileDetails,
        loading,
        setLoading,
        generateCertificate,
        step,
        setStepIndex,
        setStepVerifyDetail,
        setStepOrganizationDetail,
        setStepThankyou,
        isOv,
        orderDetails,
        subscriberAgreement,
        httpAuthDetails,
        multiDomainSansCount,
        cnameAuthDetails,
        multiDomainSansCountWildcard,
        sansValues,
        setSansValues,
        retriveEmailObj,
        retriveEmailValuesObj,
        setRetiveEmailValuesObj,
        inviteKeyObj,
        enrollPin,
        inviteKeyError,
        setInviteKeyError,
        productType,
        productTypeLower,
        wildcardProduct,
        DVProduct,
        domainName,
        setDomainName,
        isCodeSigning,
        orderIdExisted,
        setOrderIdExisted,
        tokenData,
      }}
    >
      {children}
    </ProductDetailsContext.Provider>
  );
};

export { ProductDetailsContextProvider, ProductDetailsContext };
