export const MAIN_URL =
  "https://dev.enrollapi.thesslstore.com/api/InternalApi/";
export const GET_TOKEN = "getToken";
export const CSR = "csr";
export const DCV_AVAILABLE = "dcvAvailable";
export const RETRIVE_EMAILS = "retriveEmails";
export const DCV = "dcv";
export const CNAME = "cname";
export const SUBSCRIBER_AGREEMENT = "subscriberAgreement";
export const SUBMIT_ORDER = "submitOrder";
export const REISSUE = "reIssue";
export const INDIA = "IN";
export const UNITED_STATES = "US";
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const COMPANY_EMAIL_REGEX =
  /^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/;
export const CSR_REGEX =
  /^(?:(?!-{3,}(?:BEGIN|END) CERTIFICATE REQUEST)[\s\S])*(-{3,}BEGIN CERTIFICATE REQUEST(?:(?!-{3,}END CERTIFICATE REQUEST)[\s\S])*?-{3,}END CERTIFICATE REQUEST-{3,})(?![\s\S]*?-{3,}BEGIN CERTIFICATE REQUEST[\s\S]+?-{3,}END CERTIFICATE REQUEST[\s\S]*?$)/;
export const BEGIN_CERTIFICATE_REQUEST = "BEGIN CERTIFICATE REQUEST";
export const END_CERTIFICATE_REQUEST = "END CERTIFICATE REQUEST";
export const PRIVATE_KEY = "PRIVATE KEY";
export const SELECT_COUNTRY = "--Select Country--";

export const ATTENTION_ORG_INFO = `ATTENTION: Your product is a has premium business-level
                    authentication, also known as Organization Validation (OV)
                    or Extended Validation (EV). The information entered below
                    will appear in your certificate. Please make sure this
                    information matches EXACTLY with your CSR information and
                    with your legal business registration documentation. The
                    most common reason for delays in approval/issuance are
                    because information does not match EXACTLY to what is listed
                    in your Articles of Incorporation for your company listed in
                    your region’s official third-party databases. Although a
                    D&B/D-U-N-S/Dun & Bradstreet listing is not required, it
                    will certainly help expedite your request. If your
                    organization is in the UK, please provide us your
                    Registration Number in the D-U-N-S field below.`;

export const CSR_INFO = `Paste your CSR in the form below. Please make sure that it
                      contains the complete header and footer "BEGIN" and "END"
                      lines exactly`;
export const CSR_TOOLTIP = `A CSR, or Certificate Signing Request, is a unique
                          text file created from your server that contains
                          encrypted information about your website and/or
                          organization. It includes your organization name,
                          common name (domain name), locality (city) and
                          country. The CSR is one half of your necessary "key
                          pair", the other half is your private key, which is
                          created simultaneously during the CSR generation
                          process.
                          <br />A CSR is required by any Certificate Authority
                          (CA) in order to obtain a certificate, but the CA does
                          not need your Private Key. You will need to keep your
                          private key in a safe place. The certificate created
                          with a particular CSR will only work with the private
                          key that was generated with it. So, if you lose the
                          private key, the certificate will no longer work. The
                          private key is required in order to establish secure
                          browsing for your website visitors.`;
export const SERVER_TYPE_TOOLTIP = `Your server selection will indicate to the vendor
                            the format type in which to issue your SSL. If you
                            do NOT see your server on the list, simply select
                            "Other" and you'll receive your SSL in the standard
                            X.509 (PEM) format.`;
export const PRODUCT_INFO_TITLE = "Product Information";
export const PRODUCT_INFO = `Make sure this is the product you were expecting to issue
                      to the above domain.`;
export const AUTOMATED_DV_AUTH_OPTIONS_TITLE =
  "Automated DV Authentication Options";
export const AUTOMATED_DV_AUTH_OPTIONS_INFO = `All domain vetted certificates must have Domain Control
                      Validation (DCV) performed on them. DCV is a way to prove
                      some level of control of a registered domain name and can
                      be performed using any one of below methods:`;

export const SELECT_CERTIFICATE_APPROVER_EMAIL_TITLE =
  "Select Certificate Approver Email";
export const SELECT_CERTIFICATE_APPROVER_EMAIL_INFO = `Select Email for Certificate Approval Please select
                          Authorized Administrator email account to approve all
                          certificate requests. The following approval email
                          addresses can be used. You must make sure that the
                          email account has been set up and is available before
                          you submit this order, or the approval email will not
                          be delivered`;
export const SITE_ADMIN_CONTACT_INFO_TITLE =
  "Site Administrator Contact Information";
export const SITE_ADMIN_CONTACT_INFO = `The administrative contact is the primary contact and will
                      be contacted to assist in resolution of any questions
                      about the order.`;
export const TECHNICAL_CONTACT_INFO_TITLE = "Technical Contact Information";
export const TECHNICAL_CONTACT_INFO = `The Technical contact will receive the certificate and
                      generally be the individual to install the certificate on
                      the web server. They will also receive renewal notices
                      when the certificate nears expiration.`;
export const SAME_AS_ADMIN = "Same as Administrator";
export const SUBSCRIBER_AGREEMENT_INFO = `Please carefully read the following agreement and mark the
                      checkbox below.`;
export const CERTIFICATE_SUBSCRIBER_AGREEMENT =
  "CERTIFICATE SUBSCRIBER AGREEMENT";
export const IMPORTANT = ` IMPORTANT - PLEASE READ THIS CERTIFICATE SUBSCRIBER
                          AGREEMENT CAREFULLY BEFORE APPLYING FOR, ACCEPTING, OR
                          USING A Sectigo CERTIFICATE. BY USING, APPLYING FOR,
                          OR ACCEPTING A Sectigo CERTIFICATE OR BY CLICKING ON
                          "I AGREE", YOU ACKNOWLEDGE THAT YOU HAVE READ THIS
                          AGREEMENT, THAT YOU UNDERSTAND IT, AND THAT YOU AGREE
                          TO ITS TERMS. IF YOU DO NOT ACCEPT THIS AGREEMENT, DO
                          NOT APPLY FOR, ACCEPT, OR USE A Sectigo CERTIFICATE
                          AND DO NOT CLICK "I AGREE".`;
export const AGREEMENT_STATEMENT = `This agreement is between you ("Subscriber") and
                          Sectigo CA Limited ("Sectigo"), a United Kingdom
                          company. The agreement governs your application for
                          and use of an SSL Certificate issued from Sectigo. You
                          and Sectigo agree as follows:`;
export const DEFINITIONS = "1. Definitions.";
export const SUBSCRIPTION_SERVICE = "2. Subscription Service and Products.";
export const SUBSCRIBER_RESPONSIBILITIES =
  "3. Subscriber Responsibilities and Obligations.";
export const REVOCATION = "4. Revocation.";
export const PAYMENT = "5. Payment.";
export const TERMINATION = "6. Term and Termination.";
export const INTELL_ACTUAL_PROPERTY_RIGHTS = "7. Intellectual Property Rights.";
export const CONFIDENTIALITY = "8. Confidentiality.";
export const PRIVACY_AND_DATA_PROTECTION = "9. Privacy and Data Protection.";
export const INDEMNIFICATION = "10. Indemnification.";
export const WARRANTIES_AND_REPRESENATATION = `11. Warranties and Representations. Subscriber
                            represents and warrants that:`;
export const DISCLAIMERS_AND_LIMITATION =
  "12. Disclaimers and Limitation of Liability.";
export const MISCELLANEOUS = "13. Miscellaneous.";
export const ACCEPTANCE = "ACCEPTANCE";
export const AGREE_STATEMENT_LAST = `BY CLICKING "I AGREE", YOU ACKNOWLEDGE THAT YOU HAVE
                          READ AND UNDERSTAND THIS AGREEMENT AND THAT YOU AGREE
                          TO COMPLY WITH ITS TERMS. DO NOT CLICK "I AGREE" IF
                          YOU DO NOT ACCEPT THIS AGREEMENT.`;
export const AGREE_STATEMENT_INFOICON = `By marking the checkbox below you confirm that you have
                      carefully read, understood, and accept the terms and
                      conditions of the Subscriber Agreement from the issuing
                      certificate authority.`;
export const AGREE_STATEMENT_CHECKBOX = `I have read and accept the terms and conditions of the
                    subscriber agreement..`;
export const EMAIL = "email";
export const THANK_YOU = "Thank You!";
export const ENROLLMENT_PROCESS_COMPLETED =
  "The Enrollment Process is Complete";
export const EMAIL_US = "Email us! ";
export const CONTACT_US = "Need Help? Contact Us";
export const CALL_US = `Call us! 8123456789`;
export const SUPPORT_EMAIL = "support@sbsslstore.com";
export const SENDBOX_URL = "sandbox.thesslstore.com";
