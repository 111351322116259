import React, { useContext, useEffect, useState } from "react";
import Header from "Shared/Header";
import Footer from "Shared/Footer";
import Navbar from "Shared/Navbar";
import sectigologo from "assets/images/sectigo-logo.svg";
import toolTipIcon from "assets/images/tooltip-icon.svg";
import { Button, Form } from "react-bootstrap";
import Error, { ErrorCsr } from "Shared/Error";
import { useForm } from "react-hook-form";
import { CSR_INFO, CSR_REGEX, SERVER_TYPE_TOOLTIP } from "Utils/Constants";
import { serverTypeDropdownData } from "Utils/Data";
import { Link, useNavigate } from "react-router-dom";
import CsrSampleModal from "Shared/CsrSampleModal";
import { ProductDetailsContext } from "Context/ProductDetailContext";
import Loader from "Shared/Loader";
import validator from "validator";
import isValidDomain from "is-valid-domain";
import { useParams } from "react-router-dom";

const IndexCsr = () => {
  const { id } = useParams();
  const testKeySet = localStorage.setItem("inviteKey", id);
  const testKey = localStorage.getItem("inviteKey");

  const {
    step,
    isOv,
    setStepIndex,
    values,
    productName,
    getJWTByUid,
    getProductDetailsByDecodingCsr,
    valuesCsr,
    csrInfo,
    setCsrInfo,
    loading,
    setLoading,
    dcvObj,
    getDcvDropdownData,
    getRetriveEmailsDropdownData,
    sansValues,
    setSansValues,
    multiDomainSansCount,
    multiDomainSansCountWildcard,
    retriveEmailValuesObj,
    inviteKeyObj,
    inviteKeyError,
    setInviteKeyError,
    error,
    setError,
    DVProduct,
    domainName,
    setDomainName,
    orderIdExisted,
  } = useContext(ProductDetailsContext);
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [sansType, setSansType] = useState("true");
  const [sansValuesShow, setSansValuesShow] = useState(false);
  const [sansWildcardValues, setSansWildcardValues] = useState("");
  const [data, setData] = useState([]);
  const [commonDomainError, setCommonDomainError] = useState("");
  var isValid = true;
  var isStar = true;
  var isValidWildcard = true;
  var isStarWildcard = true;
  const serverTypeDropdown = serverTypeDropdownData.map((item, index) => {
    return (
      <option
        // defaultValue={serverTypeDropdownData[2]}
        key={index}
        value={item.id}
      >
        {item.type}
      </option>
    );
  });
  const handlePasteSansChangeInput = (e) => {
    const addSans = [];
    addSans.push(e.target.value.split(","));
    setSansValues(addSans[0]);
  };
  const handlePasteSansChangeInputWildcard = (e) => {
    const addSansWildcard = [];
    addSansWildcard.push(e.target.value.split(","));
    const result = addSansWildcard[0].filter(
      (val) =>
        val !== "" && val !== undefined && val !== "empty" && val !== null
    );
    setSansWildcardValues(result);
  };
  const handleMultipleInputValue = (val, index) => {
    var test = data;
    test[index] = val.target.value;
    const result = test.filter(
      (val) =>
        val !== "" && val !== undefined && val !== "empty" && val !== null
    );
    let resultFiltered = [...new Set(result)];
    setSansValues(resultFiltered);
  };
  const handleMultipleInputValueWildcard = (val, index) => {
    var test = data;
    test[index] = val.target.value;
    const result = test.filter(
      (val) =>
        val !== "" && val !== undefined && val !== "empty" && val !== null
    );
    setSansWildcardValues(result);
  };
  const sansValidation = () => {
    const sansCount = sansValues.length;
    sansValues &&
      sansValues.map((sv) => {
        isStar = sv.includes("*");
        if (!isStar) {
          const test = validator.isURL(sv.trim());
          if (!test) {
            isValid = false;
          }
        } else {
          isValid = false;
        }
      });
    if (sansCount > multiDomainSansCount) {
      isValid = false;
      alert("Please Enter valid number of domains");
    } else if (sansCount > 0 && isStar) {
      alert("* is only allowed in wildcard domain names");
    } else if (isValid && sansCount > 0) {
      isValid = true;
    } else {
      if (!isValid) {
        alert("Please Enter valid Domain");
      }
    }
  };
  const sansValidationWildcard = () => {
    var sansCount = sansWildcardValues && sansWildcardValues.length;
    sansWildcardValues &&
      sansWildcardValues.map((sv) => {
        isStarWildcard = sv.includes("*");
        if (isStarWildcard) {
          const test = isValidDomain(sv.trim(), { wildcard: true });
          if (!test) {
            isValidWildcard = false;
          }
        } else {
          isValidWildcard = false;
        }
      });
    if (sansCount > multiDomainSansCountWildcard) {
      isValidWildcard = false;
      alert("Please Enter valid number of domains");
    } else if (sansCount > 0 && !isStarWildcard) {
      alert("*. is mendetary at start of domain name for wildcard domain");
    } else if (sansCount > 0 && isValidWildcard && isStarWildcard) {
      isValidWildcard = true;
    } else {
      if (!isValidWildcard) {
        alert(
          `Please Enter valid Wildcard Domain. Please include "*." at the beginning of Wildcard Domain name.`
        );
      }
    }
  };
  const showSans = async () => {
    await sansValidation();
    await sansValidationWildcard();
    if (isValid && isValidWildcard) {
      setSansValuesShow(true);
    }
  };
  const inputsMultiDomain = [];
  const inputsMultiDomainWildcard = [];
  for (let i = 1; i <= multiDomainSansCount; i++) {
    inputsMultiDomain.push(
      <div className="form-group" key={i}>
        <label className="form-label font-weight-bold">Domain Name {i}:</label>
        <input
          className="form-control"
          length="256"
          name={`sansTxtBox${i}`}
          type="text"
          key={i}
          onBlur={(val) => {
            handleMultipleInputValue(val, i);
          }}
        />
      </div>
    );
  }
  for (let i = 1; i <= multiDomainSansCountWildcard; i++) {
    inputsMultiDomainWildcard.push(
      <div className="form-group" key={i}>
        <label className="form-label font-weight-bold">Domain Name {i}:</label>
        <input
          className="form-control"
          length="256"
          name={`sansTxtBox${i}`}
          onBlur={(val) => {
            handleMultipleInputValueWildcard(val, i);
          }}
          type="text"
        />
      </div>
    );
  }
  const handleSanChange = (e) => {
    setSansType(e.target.value);
  };

  var isBreakLoopSameAdditionalDomain = true;
  const checkSameAdditionalDomain = async () => {
    sansValues &&
      sansValues.map((val) => {
        if (val.toLowerCase() === domainName.toLowerCase()) {
          isBreakLoopSameAdditionalDomain = false;
        } else if (val.toLowerCase() !== domainName.toLowerCase()) {
          setCommonDomainError("false");
          setError("");
        } else {
          setCommonDomainError("");
        }
      });
    if (isBreakLoopSameAdditionalDomain === false) {
      setCommonDomainError("true");
      setError("Csr Domain Name can't be used in Additional Domain Names");
    }
  };

  const onSubmit = async (data) => {
    sansValidation();
    sansValidationWildcard();
    var isTrueSet = data.new === "true";
    var isFalseSet = data.new === "false";
    values.new = data.new ? isTrueSet : isFalseSet;
    values.serverType = data.serverType;
    values.csr = encodeURIComponent(data.csr);
    valuesCsr.csr = encodeURIComponent(data.csr);
    dcvObj.csr = encodeURIComponent(data.csr);
    if (isValid && isValidWildcard) {
      setSansValues([...sansValues, ...sansWildcardValues]);
      setLoading("true");
      const test = await getProductDetailsByDecodingCsr();
      setCsrInfo(test.data);
      setDomainName(test.data.commonName);
      retriveEmailValuesObj.domainName = test.data.commonName;
      await checkSameAdditionalDomain();
      if (
        commonDomainError === "true" ||
        (error !== "" && isBreakLoopSameAdditionalDomain === false)
      ) {
        setLoading("");
      }
      if (
        (commonDomainError === "false" || commonDomainError === "") &&
        error === "" &&
        test.data.errorCode === "0"
      ) {
        setLoading("true");
        await getRetriveEmailsDropdownData();
      }
    }
  };

  useEffect(() => {
    inviteKeyObj.inviteKey = id;
    getJWTByUid();
    setStepIndex();
  }, [id]);
  useEffect(() => {
    if (inviteKeyError === true) {
      navigate("/error", { replace: true });
      setInviteKeyError(false);
    }
  }, [inviteKeyError]);
  // useEffect(() => {
  //   if (orderIdExisted !== "") {
  //     setError("Enrollment process has already completed");
  //     alert("Enrollment process has already completed");
  //     navigate("/error", { replace: true });
  //     setError("");
  //   }
  // }, [orderIdExisted]);
  useEffect(() => {
    if (
      loading === "false" &&
      (commonDomainError === "false" || commonDomainError === "") &&
      error === "" &&
      csrInfo.errorCode === "0"
    ) {
      getDcvDropdownData();
      navigate("/verify-detail", {
        state: { id: id, sansWildcardValues: sansWildcardValues },
        replace: true,
      });
      setLoading("");
    }
  }, [loading]);
  useEffect(() => {
    checkSameAdditionalDomain();
  }, [csrInfo, loading, sansValues, domainName]);
  useEffect(() => {
    if (error !== "" || csrInfo.errorCode !== "0") {
      window.scrollTo(0, 0);
    }
  }, [error, csrInfo]);
  // console.log("error", error);
  // console.log("loading", loading);
  // console.log("csrInfo", csrInfo);
  // console.log("domainName", domainName);
  // console.log("commonDomainError", commonDomainError);
  // console.log("sansValues", sansValues);
  return (
    <div>
      <Header />
      <div>
        <section className="py-3 border-top">
          <div className="container">
            <div className="d-flex flex-sm-row flex-column justify-content-between align-items-center">
              <h1 className="mb-md-0 mb-2 text-md-left text-center fs-3">
                {productName}
              </h1>
              <span className="brand-logo">
                <img alt="Certificate Generation" src={sectigologo} />
              </span>
            </div>
          </div>
        </section>
        <div
          aria-disabled={loading === "true"}
          className="content bg-light py-5"
        >
          <div className="container">
            <Navbar step={step} isOv={isOv} />
            <form onSubmit={handleSubmit(onSubmit)}>
              {loading === "true" ? <Loader /> : ""}
              <div className="bg-white p-4 p-md-5">
                {csrInfo.errorCode !== "0" && loading === "" ? (
                  <div className="errormsg">{csrInfo.errorMessage}</div>
                ) : (
                  ""
                )}
                {error ? <div className="errormsg">{error}</div> : ""}
                <Form.Group className="mb-3 row align-items-center">
                  <Form.Label
                    htmlFor="staticEmail"
                    className="col-sm-3 col-form-label font-weight-bold"
                  >
                    Product Name:
                  </Form.Label>
                  <div className="col-sm-9 readOnlyInput">{productName}</div>
                </Form.Group>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Order Type:</label>
                  <div className="col-sm-9">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="new"
                        defaultChecked
                        value={true}
                        {...register("new")}
                      />
                      <label className="form-check-label">New</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="new"
                        value={false}
                        {...register("new")}
                      />
                      <label className="form-check-label">Renew</label>
                    </div>
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-md-3 col-form-label">
                    Enter Certificate Signing Request(CSR) Information:
                  </label>
                  <div className="col-md-9 position-relative">
                    <>
                      {CSR_INFO}
                      <p className="tooltips" href="#">
                        <img alt="Tooltip" src={toolTipIcon} width="16" />
                        <span className="bigtxt">
                          A CSR, or Certificate Signing Request, is a unique
                          text file created from your server that contains
                          encrypted information about your website and/or
                          organization. It includes your organization name,
                          common name (domain name), locality (city) and
                          country. The CSR is one half of your necessary "key
                          pair", the other half is your private key, which is
                          created simultaneously during the CSR generation
                          process.
                          <br />
                          <br />A CSR is required by any Certificate Authority
                          (CA) in order to obtain a certificate, but the CA does
                          not need your Private Key. You will need to keep your
                          private key in a safe place. The certificate created
                          with a particular CSR will only work with the private
                          key that was generated with it. So, if you lose the
                          private key, the certificate will no longer work. The
                          private key is required in order to establish secure
                          browsing for your website visitors.
                        </span>
                      </p>
                    </>
                    <p>
                      <button
                        data-bs-toggle="modal"
                        data-bs-target="#exampleCSR"
                        className="border-0"
                        type="button"
                      >
                        see example
                      </button>
                    </p>
                    <Form.Control
                      as="textarea"
                      className={
                        errors.csr
                          ? "form-control border-danger"
                          : "form-control"
                      }
                      id="exampleFormControlTextarea1"
                      name="csr"
                      rows="14"
                      {...register("csr", {
                        required: true,
                        pattern: {
                          value: CSR_REGEX,
                        },
                      })}
                    />
                    {errors.csr ? (
                      <>
                        {errors.csr.type === "required" && <Error />}
                        {errors.csr.type === "pattern" && <ErrorCsr />}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="mb-3 row">
                    <label className="col-md-3 col-form-label mt-2">
                      Server Type:
                    </label>
                    <div className="col-md-9">
                      <div className="d-inline-flex align-items-center mt-3 serverTypeDropDown">
                        <Form.Select
                          className="form-select"
                          aria-label="Default select example"
                          data-live-search="true"
                          defaultValue="2"
                          {...register("serverType")}
                        >
                          {serverTypeDropdown}
                        </Form.Select>
                        <Link className="tooltips ms-2" to="/">
                          <img alt="Tooltip" src={toolTipIcon} width="16" />
                          <span>{SERVER_TYPE_TOOLTIP}</span>
                        </Link>
                      </div>
                      <div
                        id="emailHelp"
                        className="form-text serverTypeDropDown"
                      >
                        Please select the type of server platform you are using.
                      </div>
                    </div>
                  </div>
                  {(multiDomainSansCount > 0 ||
                    multiDomainSansCountWildcard > 0) && (
                    <div>
                      <div className="form-group row mb-3">
                        <label className="col-sm-3 font-weight-bold">
                          Secure Additional Domains: <span></span>
                        </label>
                        <div className="col-sm-9">
                          This certificate request allows more than one domain
                          to be secured on the same certificate. Add domain
                          names using the fields below. Do not repeat the domain
                          used in your CSR.
                        </div>
                      </div>
                      <div className="form-group row mb-3">
                        <div className="col-sm-3 font-weight-bold"></div>
                        <div className="col-sm-9">
                          <div className="mb-4 d-flex">
                            <div className="form-check form-check-inline">
                              <input
                                type="radio"
                                name="radListAddDomain"
                                value={true}
                                className="form-check-input"
                                onChange={handleSanChange}
                                defaultChecked
                              />
                              <label className="custom-control-label ps-1">
                                Enter SANs Manually
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                type="radio"
                                name="radListAddDomain"
                                value={false}
                                className="form-check-input"
                                onChange={handleSanChange}
                              />
                              <label className="custom-control-label ps-1">
                                Paste a SAN List
                              </label>
                            </div>
                          </div>
                          {sansType === "true" ? (
                            <div className="domain-manual-list">
                              <div className="fw-bold mb-2">
                                Additional Domains :
                              </div>
                              {inputsMultiDomain}
                              <br />
                              {multiDomainSansCountWildcard > 0 && (
                                <div className="fw-bold mb-2">
                                  Additional Wildcard Domains :
                                </div>
                              )}
                              {inputsMultiDomainWildcard}
                            </div>
                          ) : (
                            <div className="domain-paste-list">
                              <p>
                                This tool allows you to paste a comma separated
                                list of domains to add to your certificate. Just
                                follow these steps and click upload:
                              </p>
                              <ul className="commonulli ms-4 customlist">
                                <li>Remove any spaces and line breaks</li>
                                <li>
                                  Create a comma separated list of domains
                                </li>
                                <li>Paste them below and press Add SANs</li>
                                <li>
                                  Do not include your certificate Common Name in
                                  this list
                                </li>
                              </ul>
                              <div className="form-group my-3">
                                <input
                                  type="hidden"
                                  id="hidAddDomainPaste"
                                  name="hidAddDomainPaste"
                                />
                                <div className="mb-2 fw-bold">
                                  Additional Domains:
                                </div>
                                <textarea
                                  rows="3"
                                  onChange={handlePasteSansChangeInput}
                                  className="form-control"
                                  placeholder="Paste here"
                                />
                              </div>
                              {multiDomainSansCountWildcard > 0 && (
                                <div className="form-group my-3">
                                  <input
                                    type="hidden"
                                    id="hidAddDomainPaste"
                                    name="hidAddDomainPaste"
                                  />
                                  <div className="mb-2 fw-bold">
                                    Additional Wildcard Domains:
                                  </div>
                                  <textarea
                                    rows="3"
                                    onChange={
                                      handlePasteSansChangeInputWildcard
                                    }
                                    className="form-control"
                                    placeholder="Paste here"
                                  />
                                </div>
                              )}
                              {sansValuesShow && (
                                <ul className="list-group mt-4">
                                  {sansValues &&
                                    sansValues.map((sa, id) => {
                                      return (
                                        <li
                                          className="list-group-item"
                                          key={id}
                                        >
                                          {sa}
                                        </li>
                                      );
                                    })}
                                  {sansWildcardValues &&
                                    sansWildcardValues.map((sa, id) => {
                                      return (
                                        <li
                                          className="list-group-item"
                                          key={id}
                                        >
                                          {sa}
                                        </li>
                                      );
                                    })}
                                </ul>
                              )}
                              <button
                                id="btnAddDomainPaste"
                                type="button"
                                className="button button-green mt-3 smallbutton"
                                onClick={showSans}
                              >
                                Add SANs
                              </button>
                              <div id="divUlAddDomains">
                                <ul
                                  id="ulAddDomains"
                                  className="list-group mt-4"
                                ></ul>
                              </div>
                              <p
                                id="lblReplaceAddSANListNote"
                                className="mt-2"
                                style={{ display: "none" }}
                              >
                                *** If you need to replace this list, just enter
                                a new set of domains and press Add SANs, this
                                list will be replaced.
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {DVProduct && (
                    <div className="mb-3 row ">
                      <label className="col-sm-3 col-form-label">
                        Include Trust Logo?
                      </label>
                      <div className="col-sm-9">
                        <p className="serverTypeDropDown">
                          Prove your identity, increase confidence & trust on
                          your site with a trusted seal.
                        </p>
                        <div className="form-check form-check-inline ms-1">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="trustLogo"
                            id="yesRadio"
                            {...register("trustLogo")}
                            value="yes"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="yesRadio"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="trustLogo"
                            id="noRadio"
                            defaultChecked
                            {...register("trustLogo")}
                            value="no"
                          />
                          <label className="form-check-label">No</label>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <label className="col-sm-3 col-form-label d-none d-md-block"></label>
                    <div className="col-sm-9">
                      <Button
                        className="btn btn-success btn-lg ms-1"
                        type="submit"
                        name="continue"
                        id="btnSubmit"
                      >
                        Continue
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />

      {/* Modal  */}
      <CsrSampleModal />
    </div>
  );
};

export default IndexCsr;
