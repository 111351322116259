import Footer from "Shared/Footer";
import Header from "Shared/Header";
import Navbar from "Shared/Navbar";
import secticologo from "assets/images/sectigo-logo.svg";
import supporticon from "assets/images/support-icon.svg";
import {
  CALL_US,
  CONTACT_US,
  EMAIL_US,
  ENROLLMENT_PROCESS_COMPLETED,
  SENDBOX_URL,
  SUPPORT_EMAIL,
  THANK_YOU,
} from "Utils/Constants";
import { useContext, useEffect, useState } from "react";
import { ProductDetailsContext } from "Context/ProductDetailContext";
import downloadIcon from "assets/images/download-icon.svg";
import processIcon from "assets/images/process-icon.svg";
import { Button } from "react-bootstrap";

const ThankYou = () => {
  const {
    step,
    isOv,
    setStepThankyou,
    orderDetails,
    httpAuthDetails,
    csrInfo,
    values,
    cnameAuthDetails,
    productName,
  } = useContext(ProductDetailsContext);
  const [splitURL, setSplitURL] = useState([]);
  const handleClick = () => {
    window.open(
      `https://playground.centralapi.com/en/downloadfile/${httpAuthDetails.authFileName}/${combinedURL}`
    );
  };
  const copy = async (e) => {
    await navigator.clipboard.writeText(e.target.value);
    alert("The link is copied to your clipboard.");
  };

  useEffect(() => {
    setStepThankyou();
    if (values.dcvMethod === "https" && httpAuthDetails !== {}) {
      setSplitURL(httpAuthDetails.authFileContent.split("\r\n"));
    }
  }, []);
  const combinedURL = splitURL.join("__");

  return (
    <>
      <Header />
      <main>
        <section className="py-3 border-top">
          <div className="container">
            <div className="d-flex flex-sm-row flex-column justify-content-between align-items-center">
              <h1 className="mb-md-0 mb-2 text-md-left text-center fs-3">
                {productName}
              </h1>
              <span className="brand-logo">
                <img alt="Certificate Generation" src={secticologo} />
              </span>
            </div>
          </div>
        </section>
        <section className="content bg-light py-5">
          <div className="container">
            <Navbar step={step} isOv={isOv} />
            <div className="bg-white p-4 p-md-5">
              <h2 className="mb-2 fs-4">{THANK_YOU}</h2>
              <h3 className="text-secondary fs-5 mb-3">
                {ENROLLMENT_PROCESS_COMPLETED}
              </h3>
              <p className="fw-bold">Order ID: {orderDetails.orderNumber}</p>
              <p>
                Your certificate details for {orderDetails.orderNumber} have
                been successfully submitted for processing with Sectigo. You
                will receive direct communication from Sectigo via Email or
                Telephone.
              </p>

              {values.dcvMethod === "https" && (
                <div className="gray-area p-3 p-md-4">
                  <p className="mb-0">
                    <Button
                      onClick={copy}
                      variant="link"
                      className="border-0"
                      value={`https://playground.centralapi.com/en/downloadfile/${httpAuthDetails.authFileName}/${combinedURL}`}
                    >
                      Save
                    </Button>
                    it so you can easily follow up on validation steps or manage
                    your certificate in the future.
                  </p>
                  <div className="row">
                    <div class="col-lg-3 order-lg-1 order-0 text-lg-right text-center pb-lg-0 pb-3">
                      <img alt="Download Auth File" src={downloadIcon} />
                    </div>

                    <div className="col-lg-9">
                      <p>
                        Awesome! You've chosen the File-Based Authentication
                        method, it's super-fast &amp; easy! All you need to do
                        is download your unique Authentication file by clicking
                        the 'Download Auth File' button below. The verification
                        file must be available under this directory{" "}
                        <strong>/.well-known/pki-validation/</strong> of your
                        HTTP server so it can be found like so:
                      </p>
                      <p className="thank-txt">
                        <strong>
                          http://{csrInfo.commonName}
                          /.well-known/pki-validation/
                          {httpAuthDetails.authFileName}
                        </strong>
                      </p>
                      <p className="thank-txt">
                        Your file name should be:{" "}
                        <strong>{httpAuthDetails.authFileName}</strong>
                      </p>
                      <p className="thank-txt">
                        The content contained in the file should be:{" "}
                        {splitURL.map((sp) => {
                          return (
                            <div>
                              <strong>{sp}</strong>
                            </div>
                          );
                        })}
                      </p>
                      <Button
                        className="button button-green"
                        onClick={handleClick}
                      >
                        Download Auth File
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              {values.dcvMethod === "cname" && (
                <div className="gray-area p-3 p-md-4">
                  <div className="row">
                    <div className="col-lg-4 order-lg-1 order-0 text-lg-right text-center pb-lg-0 pb-3">
                      <img alt="Download Auth File" src={processIcon} />
                    </div>
                    <div className="col-lg-8 thanku-dtl">
                      <p>
                        Since you selected CNAME Based Authentication, the
                        issuing vendor will require you to create the following
                        DNS CNAME record for your domain. Below are the specific
                        instructions on how to complete the verification.
                      </p>
                      <ol className="list-group-flush">
                        <li className="list-group-item bg-transparent">
                          Login to your domain‘s <strong>DNS provider</strong>{" "}
                          (typically the registrar of your domain)
                        </li>
                        <li className="list-group-item bg-transparent">
                          Go to the <strong>DNS Zone Manager</strong> to amend
                          the records.
                        </li>
                        <li className="list-group-item bg-transparent thank-txt">
                          Create the following <strong>CNAME</strong> record
                          using hash values below:
                          <ul>
                            <li>
                              - Enter the Host/Alias Name as{" "}
                              <strong>{cnameAuthDetails.cnameAuthName}</strong>
                            </li>
                            <li>
                              - Point to:{" "}
                              <strong>{cnameAuthDetails.cnameAuthValue}</strong>
                            </li>
                          </ul>
                        </li>
                        <li className="list-group-item bg-transparent">
                          Set the <strong>TTL</strong> to <strong>3600</strong>{" "}
                          or the lowest possible option.
                        </li>
                        <li className="list-group-item bg-transparent">
                          Click <strong>Save</strong> and wait for the record to
                          propagate (i.e. 15 minutes).
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              )}
              <div className="contact-section pt-md-5 pt-3 d-flex">
                <span className="support-icon me-4">
                  <img alt="Contanct Us" src={supporticon} />
                </span>
                <div className="contact-info">
                  <h5 className="mb-2">{CONTACT_US}</h5>
                  <p className="mb-2 fw-bold">{CALL_US}</p>
                  <p className="mb-2 fw-bold">
                    {EMAIL_US}
                    <a href="support@sbsslstore.com">{SUPPORT_EMAIL}</a>
                  </p>
                  <p className="mb-0 fw-bold">{SENDBOX_URL}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default ThankYou;
